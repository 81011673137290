import { FC, useState } from "react";
import { NavLink, useLocation} from "react-router-dom";
import overViewIcon from "../../assets/overViewIcon.svg";
import applicationIcon from "../../assets/applicationIcon.svg";
import paymentIcon from "../../assets/paymentIcon.svg";
import productIcon from "../../assets/productIcon.svg";
import settingIcon from "../../assets/settingIcon.svg";
import deviceIcon from "../../assets/deviceIcom.svg";
import orderIcon from "../../assets/orderIcon.svg";
import userIcon from "../../assets/usersicon.svg";
import disburseIcon from "../../assets/disburseIcon.svg";
import vendorIcon from "../../assets/vendorIcon.svg";
import bvnIcon from "../../assets/detailsIcon.svg";
import walleticon from "../../assets/walletIcon.svg";
import businessIcon from "../../assets/businessIcon.svg";
import adminIcon from "../../assets/adminIcon.svg";
import logo from "../../assets/logo.svg";
import signout from "../../assets/signout.svg";
import { Col } from "antd";
import { useContext, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserPofileState, UserTokenState, UserPathState } from "../../resources/user.resource";
import { MenuContext } from "../../providers/AppProvider";

const NavItem: FC<{
  name: string;
  IconPath?: string;
  path?: string;
  onClick?: () => void;
}> = ({ name, IconPath, path, onClick }) => {
  return (
    <li className="active" onClick={() => onClick && onClick()}>
      <>
        {IconPath && (
          <img
            className="menu-icon"
            src={IconPath}
            alt={"keza-icon"}
            style={{ height: 18 }}
          />
        )}
        {!path || path.trim() === "" ? (
          <span>{name}</span>
        ) : (
          <NavLink
            className={({ isActive }) => (isActive ? "active-link" : undefined)}
            to={path}
            end
          >
            {name}
          </NavLink>
        )}
      </>
    </li>
  );
};

const SideBar = () => {
  const [menuOpen, setMenuOpen] = useContext(MenuContext);
  const location = useLocation()
  const [, setUserTokenValue] = useRecoilState(UserTokenState);
  const [, setUserProfile] = useRecoilState(UserPofileState);
  const [, setUserPath] = useRecoilState(UserPathState);
  const handleLogout = () => {
    setUserPath(location?.pathname)
    setUserProfile({});
    setUserTokenValue("");
  };
 
  useEffect(() => {
    return () => {
      if (window.innerWidth >= 960) {
        setMenuOpen(true);
      }
    };
  }, []);

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
    if (window.innerWidth >= 960) {
      setMenuOpen(true);
    }
  };
  return (
    <div
      className="sb-main"
      // style={menuOpen ? { left: "0vw" } : { left: "-60vw" }}
    >
      <Col>
        <div className="logo-wrapper">
          <img
            className="keza-logo"
            src={logo}
            alt="logo"
            style={{ height: 45 }}
          />
          <h3>Keza</h3>
        </div>
      </Col>

      <nav className="sb-nav-wrapper">
        <ul>
          <NavItem
            name="Overview"
            path="/dashboard"
            IconPath={overViewIcon}
            onClick={handleMenuOpen}
          />
          <NavItem
            name="Applications"
            path="applications"
            IconPath={applicationIcon}
            onClick={handleMenuOpen}
          />
          <NavItem
            name="Starlink"
            path="starlink/applications"
            IconPath={applicationIcon}
          />
          <NavItem
            name="Users"
            path="users"
            IconPath={userIcon}
            onClick={handleMenuOpen}
          />
          <NavItem
            name="Orders"
            path="orders"
            IconPath={orderIcon}
            onClick={handleMenuOpen}
          />
          <NavItem
            name="Payment"
            path="payment"
            IconPath={paymentIcon}
            onClick={handleMenuOpen}
          />
          <NavItem
            name="Products"
            path="products"
            IconPath={productIcon}
            onClick={handleMenuOpen}
          />
          <NavItem
            name="Vendors"
            path="vendors"
            IconPath={vendorIcon}
            onClick={handleMenuOpen}
          />
          <NavItem
            name="Wallets"
            path="wallet"
            IconPath={walleticon}
            onClick={handleMenuOpen}
          />
          <NavItem
            name="Disbursements"
            path="disbursements"
            IconPath={disburseIcon}
            onClick={handleMenuOpen}
          />

          {/*profile.role === 'OPS_MANAGER'
          ?<NavItem name="Manage Admin" path="admin" IconPath={settingIcon} onClick={handleMenuOpen}/>:
          ""
          */}
          <NavItem 
            name="Bvn Details" 
            path="bvn/details" 
            IconPath={deviceIcon}
            onClick={handleMenuOpen}
          />
          <NavItem 
            name="Business" 
            path="business" 
            IconPath={businessIcon}
            onClick={handleMenuOpen}
          />
          <NavItem
            name="Business Applications"
            path="business/applications"
            IconPath={bvnIcon}
            onClick={handleMenuOpen}
        />
          <NavItem
           name="Administrators" 
           path="admin" 
           IconPath={adminIcon} 
           onClick={handleMenuOpen}
           />
          <NavItem
            name="Settings"
            path="settings"
            IconPath={settingIcon}
            onClick={handleMenuOpen}
          />
        </ul>
      </nav>

      <br />

      <nav className="sb-nav-wrapper">
        <ul>
          <NavItem onClick={handleLogout} name="Sign Out" IconPath={signout} />
        </ul>
      </nav>
    </div>
  );
};

export default SideBar;
