import { Button, Col, Row, Table, Empty } from "antd";
import { formatDate } from "../../../utilities/date-formatter";
import { FC, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState, useMemo } from "react";
import {
  getProductRequests,
  acceptOffer
} from "../../../api/application";
import { useParams } from "react-router-dom";
import { showError, showMessage } from "../../../helpers";
import { paginationData } from "@app-interfaces";


export interface ActionProps {
  applicationId: string;
}

const ProductRequest: FC<ActionProps> = ({
  applicationId,
}) => {
  const params = useParams();
  const queryClient = useQueryClient();
  const response = useQuery({
    queryKey: ["product-request", applicationId],
    queryFn: async () => {
      const response = await getProductRequests(applicationId as string || params.id as string);
      setPaginations(response.data.pagination);
      return response.data.content;
    },
  });
  const mutation = useMutation({
    mutationFn: acceptOffer,
    onSuccess: (response: any) => {
      console.log("response ", response);
      showMessage(
        "Operation Successful",
        "Successfully accepted the offer",
        "success"
      );
      queryClient.invalidateQueries({
        queryKey: ["product-request", applicationId],
      });
    },
    onError: (error: any) => {
      showError(error);
    },
  });
  useEffect(() => {
    setRequest(response.data);
  }, [response]);
  const [request, setRequest] = useState<any[]>([]);
  const [pages, setPages] = useState<any | number>(1);
  const [sizes, setSizes] = useState<any | number>(10);
  const [paginations, setPaginations] = useState<paginationData>(
    {} as paginationData
  );

  const columns = useMemo(
    () => [
      {
        title: "Vendor",
        dataIndex: "vendor",
        key: "vendor",
        render: (record: any) => <>{record?.business?.name}</>,
      },
      {
        title: "Price",
        dataIndex: "bid",
        key: "bid",
        render: (record: any) => <>{record}</>,
      },
      {
        title: "date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record: any, data: any) => <>
          {formatDate(record)}
        </>,
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: `${new Date().getTime()}`,
        render: (data: any, record: any) => {
          return (
            <Row gutter={[8, 0]}>
              <>
                <Col>
                  <Button
                    onClick={() => mutation.mutate({ Id: record, bid: data.bid })}
                  >
                    Accept Offer
                  </Button>
                </Col>
                {/*<Col>
                            <Button danger >
                              Cancel
                            </Button>
                          </Col>*/}
              </>
            </Row>
          );
        },
      },
    ],
    []
  );
  return (
    <div>
      <div>
        <Col className="card-title mb-1">
          <p>Product Requests</p>
        </Col>
      </div>

      <Table
        loading={response.isLoading}
        locale={{ emptyText: <Empty /> }}
        pagination={{
          pageSize: sizes,
          total: paginations.totalDocs,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "30", "40"],
          onChange(page) {
            setPages(page);
          },
          onShowSizeChange(current) {
            setSizes(current);
          }
        }}
        columns={columns}
        dataSource={request || []}
        scroll={{ x: 1000 }}
      />

    </div>
  )
}

export default ProductRequest