import { Button, Row, Table, Typography, Card } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { getBusiness } from "../../api/business";
import { Link } from "@chakra-ui/react";

const Bussiness = () => {
  const [bussiness, setBussiness] = useState<any>([]);
  const navigate = useNavigate();

  const updateBusinessByID = (id: string) => {
    navigate(`create/${id}`);
  };
  const addDeviceByID = (id: string) => {
    navigate(`devices/${id}`);
  };
  const [pagination, setPagination] = useState<any>([]);

  const columns = useMemo(
    () => [
      {
        title: "Bussiness Name",
        dataIndex: "name",
        key: "name"
      },
      // {
      //   title: "Address",
      //   dataIndex: "address",
      //   key: "address",
      //   render: (record: any) => (
      //     <p>
      //       {" "}
      //       {record?.address} {record?.city} {record?.state}
      //     </p>
      //   )
      // },
      // {
      //   title: "Phone Number",
      //   dataIndex: "contact",
      //   key: "contact"
      // },
      // {
      //   title: "Registration number",
      //   dataIndex: "number",
      //   key: "number"
      // },
      {
        title: "Url",
        dataIndex: "slug",
        key: "slug",
        render: (record: any, data: any) => (
          <Link
            target={"_blank"}
            href={`https://${data?.slug}.keza.africa`}
          >{`https://${data?.slug}.keza.africa`}</Link>
        )
      },
      {
        title: "Interest",
        dataIndex: "interest",
        key: "interest"
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: `${new Date().getTime()}`,
        render: (record: any, data: any) => {
          return (
            <Row gutter={[16, 0]}>
              <Button onClick={() => updateBusinessByID(data._id)}>
                Update
              </Button>

              <Button onClick={() => addDeviceByID(data._id)}>
                Add Device
              </Button>
            </Row>
          );
        }
      }
    ],
    []
  );

  const bussinesResponse = useMutation({
    mutationFn: async () => {
      const response = await getBusiness();
      setBussiness(response.data.data.list);
      setPagination(response.data.data);
      return response.data.data;
    }
  });

  useEffect(() => {
    bussinesResponse.mutate();
  }, []);

  return (
    <div className="container">
      {/* <iframe
        src="https://bolt.keza.africa/"
        width="900"
        height={"400"}
        title='bolt'
      ></iframe> */}
      <Row className="mb-10" justify={"space-between"}>
        <Typography.Title level={4} className="page-title">
          Business
        </Typography.Title>
      </Row>
      <Card
        title={
          <>
            <button className="p-btn2" onClick={() => navigate("create")}>
              <span> Add a business</span>
            </button>
          </>
        }
      >
        <Table
          className="table"
          loading={bussinesResponse.isLoading}
          dataSource={bussiness || []}
          pagination={{
            pageSize: pagination.totalCount,
            total: pagination.numberOfPages,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "30", "40"]
          }}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};

export default Bussiness;
