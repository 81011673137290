import { Button, Col, Row, Table, Typography, Card } from "antd";
import { formatDate } from "../../utilities/date-formatter";
import { useEffect, useMemo, useState } from "react";
import { getTag } from "../../utilities/sanitizer";
import { PhoneOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { activateOrUnactivateVendor, getAllVendors } from "../../api/vendors";
import { showMessage } from "../../helpers";
import { paginationData } from "../../types/pagination.inteface";


const Vendors = () => {
  const [vendors, setVendors] = useState([]);
  const [paginations, setPaginations] = useState<paginationData>({
    numberPages: 1,
    totalDocs: 10,
    query: {
      keyword: "",
      page: 1,
      size: 10
    }
  });

  const vendorResponse = useMutation({
    mutationFn: async (input: any) => {
      const response = await getAllVendors(input as any);
      setPaginations({ ...paginations, ...response.data.pagination });
      setVendors(response.data.content);
      return response.data.content;
    }
  });

  const vendorActivation = useMutation({
    mutationFn: activateOrUnactivateVendor,
    onSuccess: data => {
      showMessage(
        data?.data?.message,
        `Vendor ${
          data?.data?.data?.isActive ? "activated" : "deactivated"
        } successfully!`,
        "success"
      );

      vendorResponse.mutate(paginations);
    }
  });

  const columns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "vendor",
        key: "vendor",
        render: (record: any, data: any) => (
          <p className="table-name">
            {data?.firstName} {data?.lastName}
          </p>
        )
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
        key: "phoneNumber"
      },
      {
        title: "Status",
        dataIndex: "isActive",
        key: "isActive",
        render: (record, data) =>
          getTag(data?.isActive ? "approved" : "pending")
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record: any, data: any) => <>{formatDate(record)}</>
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: `${new Date().getTime()}`,
        render: (record: any, data: any) => {
          return (
            <Row gutter={[16, 0]}>
              <Row style={{ marginRight: "10px" }}>
                <Link to={`${record}`}>
                  <Button>View</Button>
                </Link>
              </Row>
              <Row gutter={[16, 0]}>
                <Col>
                  <Button
                    onClick={() =>
                      vendorActivation.mutate({
                        vendor: data?._id,
                        isActive: !data?.isActive
                      })
                    }
                  >
                    {data?.isActive ? "Disable" : "Approve"}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      data?.phoneNumber &&
                      window.open(`tel:${data?.phoneNumber}`, "blank")
                    }
                  >
                    <PhoneOutlined /> Contact
                  </Button>
                </Col>
              </Row>
            </Row>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    vendorResponse.mutate(
      {
        ...paginations,
      page: paginations.query.page,
      size: paginations.query.size,
      }
    );
  }, [paginations.query]);

  return (
    <div className="container">
      <Row className="mb-10" justify={"space-between"}>
        <Typography.Title level={4} className="page-title">
          Vendors
        </Typography.Title>
      </Row>
      <Card
        title={
          <Row className="mb-3">
            <span className="table-title">
              Showing {vendors.length || 0} of {paginations.totalDocs || 0}{" "}
              results for{" vendors"}
            </span>
          </Row>
        }
      >
        <Table
          className="table"
          loading={vendorResponse.isLoading}
          dataSource={vendors || []}
          pagination={{
            pageSize: paginations.query.size,
            total: paginations.totalDocs,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "30", "40"],
            onChange(page, size) {
              setPaginations({
                ...paginations,
                query: { ...paginations.query, page, size }
              });
            },
            onShowSizeChange(page, size) {
              setPaginations({
                ...paginations,
                query: { ...paginations.query, page, size }
              });
            }
          }}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};

export default Vendors;
