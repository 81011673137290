import { Button, Card, Row, Col, Table } from "antd";
import CounterBox from "../Dashboard/CounterBox/FilterCounterBox";
import smallApplicationIcon from "../../assets/smallApplicationIcon.svg";
import folderIcon from "../../assets/folderIcon.svg";
import folderIcon2 from "../../assets/folderIcon2.svg";
import folderIcon3 from "../../assets/folderIcon3.svg";
import { formatAsCurrency } from "../../utilities/number-formatter";
import { formatDate } from "../../utilities/date-formatter";
import { SearchOutlined } from "@ant-design/icons";
import { Select, Input , InputRef} from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getWallets } from "../../api/wallet";
import { getTag } from "../../utilities/sanitizer";

const Payments = () => {
  
  const { isLoading, data } = useQuery({
    queryKey: ["wallets"],
    queryFn: async () => {
      const response = await getWallets();
      
      return response.data.data;
      
    },
  });
  const [wallets, setWallets] = useState([]);
  const length = data?.length || 0
  const inputRef: React.Ref<InputRef> = useRef() as React.Ref<InputRef>;
  const columns = useMemo(
    () => [
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        render: (record: any, data: any) => (
          <div className='table-info'>
            <span className="table-name">{data?.firstName} {data?.lastName}</span>
            <span className="table-mail">{data?.email}</span>
          </div>
        ),
      },
      {
        title: "Account Number",
        dataIndex: "accountNumber",
        key: "accountNumber",
      },
      {
        title: "Account Provider",
        dataIndex: "accountProvider",
        key: "accountProvider",
        render: (record: any) => <>{getTag(record)}</>,
      },
      {
        title: "Phone Number",
        dataIndex: "phone_number",
        key: "phone_number",
      },
      {
        title: "Approval Status",
        dataIndex: "isApproved",
        key: "isApproved",
        render: (record: any) => <>{getTag(record ? "success" : "pending")}</>,
      },
      {
        title: "Date Onboarded",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record: any) => <>{formatDate(record)}</>,
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: `${new Date().getTime()}`,
        render: (record) => (
          <Link to={`${record}`}>
            <Button>View History</Button>
          </Link>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    setWallets(data || []);
  }, [data]);

  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.trim().toLowerCase();

    setSearchValue(searchValue);
  };
  const handleFilter = useCallback(() => {
    if (searchValue.trim() === "") return setWallets(data || []);

    const filtered = (data || []).filter((item: any) => {
      return (
        item.firstName.trim().toLowerCase().includes(searchValue) ||
        item.lastName.trim().toLowerCase().includes(searchValue) ||
        item.accountNumber?.trim()?.toLowerCase()?.includes(searchValue) ||
        item.phone_number.trim().toLowerCase().includes(searchValue) ||
        item.accountProvider.trim().toLowerCase().includes(searchValue)
      );
    });

    setWallets(filtered);
  }, [searchValue, data]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  const [sortValue, setSortValue] = useState("");

  const handleSelect = (value: string) => {
    const sortValue = value;

    setSortValue(sortValue);
  };
  const handleSort = useCallback(() => {
    if (sortValue === ''){
    return setWallets(data);}

    const sorted = data.filter((item: any) => {
      return (
        item.isApproved === sortValue
      )
    });
    

    setWallets(sorted);
  }, [sortValue, data])
  useEffect(() => {
    handleSort();
  }, [handleSort]);
  
  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"],
    }),
    []
  );

  
  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-md-12">
          <Row>
            <Col span={24}>
              <Col>
                <Row gutter={8} justify={"space-between"} align={"middle"}>
                  <div>
                    <h2 className="page-title">Payments</h2>
                  </div>
                  

                  <div>
                    <Select
                      
                      style={{ width: 100 }}
                      onChange={handleChange}
                      options={[
                        { value: 'thisMonth', label: 'This month' },
                        { value: '', label: '' },
                        { value: '', label: '' },
                        
                      ]}
                    />
                  </div>
                </Row>
              </Col>
            
              <Row gutter={[16, 16]}>
                  <CounterBox
                    loading={isLoading}
                    IconPath={folderIcon}
                    title="Received Payments"
                    value={'0'}
                    Data={["All", "Pending","Approved", "Declined"]}
                  />
                  <CounterBox
                    loading={isLoading}
                    IconPath={smallApplicationIcon}
                    title="Received Payments"
                    value={formatAsCurrency(
                      0
                    )}
                    Data={["All", "Pending","Approved", "Declined"]}
                 
                  />
                  <CounterBox
                    loading={isLoading}
                    IconPath={folderIcon2}
                    title="Received Payments"
                    value={formatAsCurrency(
                      0
                    )}
                    Data={["All", "Pending","Approved", "Declined"]}
                 
                  />
                  <CounterBox
                    loading={isLoading}
                    IconPath={folderIcon3}
                    title="Total Revene"
                    value={formatAsCurrency(
                      0
                    )}
                    Data={["All", "Pending","Approved", "Declined"]}
                  />
              </Row>
              
            </Col>
          </Row>
        </div>
      </div>
      <br />
      <br />
      
      <div className="row mb-3">
        <div className="col-md-12">
          <Card
             title={
              <Row className="mb-3">
                <span className="table-title">Showing {(length/ (length/10))|| 0} of {length} results for </span>
              </Row>
              }
            extra={
              <div className='flex'>
              <Select
                defaultValue="Sort"
                style={{ width: 120 }}
                onChange={handleSelect}
                options={[
                  { value:'', label: 'ALL' },
                  { value: false, label: 'PENDING' },
                  { value: true, label: 'APPROVED' },
                ]}
              />
              <Input
                prefix={<SearchOutlined />}
                placeholder="search"
                onChange={handleChange}
                ref={inputRef}
              />
              </div>
            }
          >
            <Table
              loading={isLoading}
              dataSource={wallets || []}
              columns={columns}
              pagination={pagination}
              scroll={{ x: 1000 }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Payments;
