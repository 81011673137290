import { useMutation } from "react-query";
import { getAllProduct } from "../business";

export const useGetAllProducts = () => {
  const { mutateAsync, ...result } = useMutation(getAllProduct);
  return {
    getAllProduct: mutateAsync,
    result
  };
};
