import { Card, Col, Row, Typography } from "antd";
import { FC } from "react";
import { formatAsCurrency } from "../../../utilities/number-formatter";
import SectionHeader from "../../UserDetail/components/SectionTitle";

const OrderDetail: FC<{ product: any }> = ({ product }) => {
  const getProductPrice = () => {
    const productPrice = product.pricing.find(
      (item: any) => item.storageROM === product.phoneStorage
    );

    const amount =
      product.phoneCondition === "NEW"
        ? productPrice?.priceNew
        : productPrice?.priceOld;

    return amount;
  };

  const getInsurance = () => {
    return `${Math.ceil(0.05 * Number(getProductPrice()))}`;
  };

  const getTotalPrice = () => {
    return `${Math.ceil(1.05 * Number(getProductPrice()))}`;
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <br />
          <Row>
            <Col span={12}>
              <Row>
                <Col>
                  <SectionHeader title="Product details" />
                </Col>
              </Row>
              <div className="mb-3"></div>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <strong>Smartphone model:</strong>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>{product?.name}</Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <strong>Smartphone model</strong>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>{product.condition}</Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <strong>Capacity</strong>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>{product?.phoneStorage}</Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <strong>Color</strong>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>{product?.phoneColor}</Typography.Text>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col span={24}>
                  <Card>
                    <table className="pricing-table">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Amount</strong>
                          </td>
                          <td>{formatAsCurrency(getProductPrice())}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Insurance</strong>
                          </td>
                          <td>{formatAsCurrency(getInsurance())}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Total Amount</strong>
                          </td>
                          <td>{formatAsCurrency(getTotalPrice())}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row className="mb-3">
                <Col>
                  <SectionHeader title="Total estimated payment" />
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <strong>Tenure</strong>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>{product?.duration} Months</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
