
import { Col, Row, Tabs } from "antd";
import AllUsers from "./AllUsers";
import KezaUsers from "./KezaUsers";
import VendorUsers from "./VendorUsers";
import BusinessUsers from "./BusinessUser";

const User = () => {
  return(
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Row>
            <Col span={24}>
              <Tabs>
                <Tabs.TabPane tab="All users" key="item-1">
                  <AllUsers />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Keza users" key="item-2">
                  <KezaUsers />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Vendor users" key="item-3">
                  <VendorUsers />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Business users" key="item-4">
                  <BusinessUsers />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
};

export default User;
