import axios from "axios";
import { showError } from "../helpers";
import { getUserToken } from "../utilities/storage";
import LogOut from '../utilities/LogOut'
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3050/api/v1",
});

api.interceptors.response.use(
  (value) => Promise.resolve(value),
  (error) => {
    showError(error);
    if(error.repons.status === 403) {
      LogOut()
    }
    if(error.repons.status === 401) {
      LogOut()
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((req: any) => {
  const token = getUserToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

export const getAllApplications = () => {
  return api.get("/admin/device/applications");
};

export const getSingleApplication = (id: string) => {
  return api.get(`/admin/device/application/${id}`);
};

export const rejectApplication = (
  applicationId: string,
  rejectionTitle: string
) => {
  return api.post(`/admin/application/${applicationId}/reject`, {
    rejectionTitle,
  });
};

export const approveApplication = (applicationId: string) => {
  return api.put(`/admin/user/application/${applicationId}/approve`);
};

export const approveDeviceApplication = (applicationId: string) => {
  return api.put(`/admin/device/application/${applicationId}/approve`);
};


export const deleteApplication = (applicationId: string) => {
  return api.delete(`/admin/application/${applicationId}/delete`);
};