import { Card, Col, Empty, Row, Spin } from "antd";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getUserFileUploads } from "../../../api/user";
import pdfIcon from "../../../assets/pdf-icon.jpeg";

const UploadedFiles = ({ user }: { user?: any }) => {
  const params = useParams() as any;

  const { isLoading, data, error } = useQuery({
    queryKey: ["user-uploads", params.id],
    queryFn: async () => {
      const response = await getUserFileUploads(params.id);
      return response.data.data;
    }
  });

  const handleDownloadFile = useCallback(async (item: any) => {
    const response = await fetch(item.secure_url);
    const blob = await response.blob();
    const file = new File(
      [blob],
      `${
        user ? `${user?.firstName} ${user?.lastName}` : `${item?.asset_id}`
      } Upload`,
      { type: item?.type }
    );

    const url = window.URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
  }, []);

  if (isLoading)
    return (
      <div className="row my-3">
        <div className="col-md-12">
          <div className="text-center">
            <Spin />
          </div>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="row my-3">
        <div className="col-md-12">
          <div className="text-center">
            <Empty />
          </div>
        </div>
      </div>
    );

  return (
    <div className="row my-3">
      <div className="col-md-12">
        <Row gutter={[20, 20]}>
          {data.map((item: any, index: number) => (
            <Col className="cursor-pointer" span={8} key={index}>
              <a onClick={() => handleDownloadFile(item)}>
                <Card>
                  <img
                    className="upload-file-doc-img"
                    src={pdfIcon}
                    alt="pdf"
                  />
                  <div className="pt-3">
                    <span>Upload</span>
                  </div>
                </Card>
              </a>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default UploadedFiles;
