import axios from "axios";
import { showError } from "../helpers";
import { getUserToken } from "../utilities/storage";
import LogOut from "../utilities/LogOut";
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3050/api/v1"
});

api.interceptors.response.use(
  value => Promise.resolve(value),
  error => {
    showError(error);
    if (error.response.status === 403) {
      LogOut();
    }
    if (error.response.status === 401) {
      LogOut();
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((req: any) => {
  const token = getUserToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

export const getAllVendors = async ({
  page = 1,
  size = 10,
  keyword = "",
  status = undefined
}) => {
  return api.get(
    `/admin/vendors?page=${page}&size=${size}&keyword=${keyword}${
      status !== undefined ? `&status=${status}` : ""
    }`
  );
};

export const activateOrUnactivateVendor = async ({
  vendor = "",
  isActive = false
}) => {
  return api.post("/admin/vendors/activate-or-unactivate-vendor", {
    vendor,
    isActive
  });
};

export const getSingleVendor = async (id = "") => {
  if (id) {
    return api.get(`/admin/vendors/${id}`);
  }
};


export const getDashboardAnalyticsVendor = (dateRange?: {
  startDate: string;
  endDate: string;
}
) => {
  return api.get(
    `/admin/dashboard/analytics?startDate=${
      dateRange?.startDate || ""
    }&endDate=${dateRange?.endDate || ""}&userType=${"vendor"}`
  );
};

export const getDashboardAnalyticsBusiness = (dateRange?: {
  startDate: string;
  endDate: string;
}
) => {
  return api.get(
    `/admin/dashboard/analytics?startDate=${
      dateRange?.startDate || ""
    }&endDate=${dateRange?.endDate || ""}&userType=${"business"}`
  );
};