import { FC } from "react";
import { Card, Col, Empty, Row, Button, Table } from "antd";
import { useMemo } from "react";
import { getTag } from "../../../utilities/sanitizer";

const VendorProfile: FC<{

  
  }> = () => {


    const pagination = useMemo(
      () => ({
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20", "30", "40"],
      }),
      []
    );
    const columns = useMemo(
      () => [
        {
          title: "Vendors",
          dataIndex: "vendors",
          key: "vendors",
        },
        {
          title: "Amount offer",
          dataIndex: "amount",
          key: "amount",
        },
        {
          title: "Proposed date",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "Status",
          dataIndex: "isCompleted",
          key: "isCompleted",
          render: (record: any) => (
            <>{getTag(record ? "available" : "unavailable")}</>
          ),
        },
        {
          title: "Actions",
          dataIndex: "_id",
          key: `${new Date().getTime()}`,
          render: (record: any, data: any) => {
            return (
              <Button className="kyc-btn">
                Accept offer
              </Button>
            );
          },
        },
      ],
      []
    );
    //if (error) return <Empty />;
  return (
    <div>
        
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Card
                    title={
                    <Col className="card-title">
                        <p>Order Requests</p>
                    </Col>
                    }
                >
                  <Table
                    className="table"
                    columns={columns}
                    
                    locale={{ emptyText: <Empty /> }}
                    dataSource={[]}
                    pagination={pagination}
                  />
                </Card>
              </div>
            </div>
          </div>
        
    </div>
  )
}

export default VendorProfile