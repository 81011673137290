import {
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  Stack,
  Text
} from "@chakra-ui/react";
import { ChangeEvent, useRef, useState, useCallback } from "react";
import { SectionLabel } from "./LeftDetails";
import SelectedItem from "./SelectedItem";
import { TextInput } from "../../../components/Input/Input";
import { Product } from "../../../types/product";
import { BsPlusCircle } from "react-icons/bs";
import { handleFileChange } from "../utils/handle-file-upload";
import { AiOutlineDelete } from "react-icons/ai";
import { getPricingName } from "../utils/get-pricing-name";
import { numberWithCommas } from "../../../utilities/number-formatter";

const initialPrice = {
  storageRAM: "",
  storageROM: "",
  priceNew: "",
  priceOld: ""
};
export default function RighDetails({
  product,
  setProduct
}: {
  product: Product;
  setProduct: (data: any) => void;
}) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [price, setPrice] = useState(initialPrice);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const handleUploadFile = useCallback(
    (e: any) =>
      handleFileChange(
        e.target.files[0],
        product,
        setProduct,
        setLoading,
        setUploadProgress
      ),
    [product, setProduct]
  );
  return (
    <Box>
      <Box>
        <SectionLabel name="Gallery" />
        <Box>
          <Center
            h="40"
            w="44"
            borderRadius={"10px"}
            border={"1px solid black"}
            onClick={() => fileInputRef?.current?.click()}
          >
            <input
              onChange={handleUploadFile}
              ref={fileInputRef}
              hidden
              type="file"
              accept=".jpg, .jpeg, .png"
            />
            {loading ? (
              <Box mt="4" textAlign="center">
                <CircularProgress
                  value={uploadProgress}
                  color="var(--keza-brown)"
                  trackColor="gray.200"
                  thickness="8px"
                  size="80px"
                />
                <Box mt="2" fontSize="lg" fontWeight="bold">
                  {uploadProgress}%
                </Box>
              </Box>
            ) : (
              <Button
                variant={"outline"}
                color={"Var(--keza-brown)"}
                borderColor="Var(--keza-brown)"
                fontSize={"14px"}
              >
                Upload image
              </Button>
            )}
          </Center>

          {product?.image?.length > 0 ? (
            <Flex mt={"4"} flexWrap={"wrap"} gap={"4"}>
              {product.image.map((item, index) => (
                <Box key={index}>
                  <SelectedItem
                    imagePath={item}
                    onClick={() =>
                      setProduct({
                        ...product,
                        image: product?.image?.filter(image => image !== item)
                      })
                    }
                  />
                </Box>
              ))}
            </Flex>
          ) : undefined}
        </Box>
      </Box>

      <Box mt="8">
        <SectionLabel name="Pricing" />
        <Flex gap={"8"} flexWrap={"wrap"}>
          <Flex gap="4" >
            <Box>
              <TextInput
                placeholder="128"
                label="ROM"
                onChange={(e: any) =>
                  setPrice({ ...price, storageROM: e.target.value })
                }
                value={price.storageROM}
                style={{maxWidth:'8rem'}}
              />
            </Box>
            <Box>
              <TextInput
                onChange={(e: any) =>
                  setPrice({ ...price, storageRAM: e.target.value })
                }
                value={price.storageRAM}
                label="RAM"
                placeholder="4"
                style={{maxWidth:'8rem'}}

              />
            </Box>
          </Flex>
          <Flex gap={"4"} >
            <Box>
              <TextInput
                type="number"
                placeholder="123909"
                label="Brand new ₦"
                onChange={(e: any) =>
                  setPrice({ ...price, priceNew: e.target.value })
                }
                value={price.priceNew}
                style={{maxWidth:'8rem'}}

              />
            </Box>
            <Box>
              <TextInput
                type="number"
                placeholder="123909"
                label="Pre-owned ₦"
                onChange={(e: any) =>
                  setPrice({ ...price, priceOld: e.target.value })
                }
                value={price.priceOld}
                style={{maxWidth:'8rem'}}

              />
            </Box>
          </Flex>
        </Flex>
        <Flex my={"4"} justifyContent={"flex-end"}>
          <Button
            variant={"ghost"}
            color={"var(--keza-brown)"}
            leftIcon={<BsPlusCircle />}
            onClick={() => {
              if (!price.storageROM || !price.storageRAM) return;
              setProduct({
                ...product,
                pricing: [...product.pricing, price]
              });
              setPrice(initialPrice);
            }}
          >
            Add
          </Button>
        </Flex>
        {product.pricing.length > 0 ? (
          <Stack spacing={"4"} alignItems={"flex-start"}>
            {product.pricing.map((singularPrice, index) => {
              return (
                <Flex
                  alignItems={"flex-end"}
                  justifyContent={"flex-start"}
                  gap={"4"}
                  key={index}
                >
                  {Object.keys(singularPrice).map((itemKey, index) => (
                    <Stack key={index} alignItems={"flex-start"}>
                      <Text textTransform={"capitalize"}>
                        {getPricingName(itemKey)}
                      </Text>

                      <Center
                        border={"1px solid black"}
                        borderRadius={"8px"}
                        w={"6rem"}
                        py={"0.3rem"}
                      >
                        {numberWithCommas(singularPrice[itemKey])}
                      </Center>
                    </Stack>
                  ))}

                  <Box>
                    <AiOutlineDelete
                      cursor={"pointer"}
                      size={"1.4rem"}
                      onClick={() => {
                        setProduct({
                          ...product,
                          pricing: product.pricing.filter(item => {
                            return product.pricing.indexOf(item) !== index;
                          })
                        });
                      }}
                    />
                  </Box>
                </Flex>
              );
            })}
          </Stack>
        ) : undefined}
      </Box>
    </Box>
  );
}
