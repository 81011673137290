import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useRecoilState } from "recoil";
import { UserPofileState, UserTokenState } from "../resources/user.resource";

export default function IdleTimer({ children }: any) {
  const [, setUserTokenValue] = useRecoilState(UserTokenState);
  const [, setUserProfile] = useRecoilState(UserPofileState);
  const onIdle = () => {
    setUserProfile({});
    setUserTokenValue("");
  };
  const { isIdle } = useIdleTimer({
    onIdle,
    timeout: 600_000,
    throttle: 500
  });

  useEffect(() => {
    const interval = setInterval(() => {
      isIdle();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return <>{children}</>;
}
