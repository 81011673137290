import { FC, useEffect, useState } from "react";
import { Card, Empty, Form, Row, Table, Typography, DatePicker, Button, Col } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { formatDateTime } from "../../../utilities/date-formatter";
import { useMemo } from "react";
import { getTag, sanitizeValues } from "../../../utilities/sanitizer";
import { paginationData } from "../../../types/pagination.inteface";
import { useMutation } from "react-query";
import { getAllApplications , exportVendorApplicationDateRange} from "../../../api/application";
import { useNavigate, useParams } from "react-router-dom";
import { RANGE_PRESETS } from "../../../utilities/date-formatter";
import { IApplicationSearch } from "@app-interfaces";
import moment from 'moment';

const VendorApplications: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [form] = Form.useForm();
  const [paginations, setPaginations] = useState<paginationData>({
    numberPages: 1,
    totalDocs: 10,
    query: {
      keyword: "",
      page: 1,
      size: 10
    }
  });

  const ApplicationResponse:any = useMutation({
    mutationFn: async (input: paginationData) => {
      const response = await getAllApplications({
        ...input,
        vendor: params.id
      } as any);
      setPaginations({ ...paginations, ...response.data.data.pagination });
      setApplications(response.data.data.content);

      return response.data.content;
    }
  });

  const [searchValue, setSearchValue] = useState<IApplicationSearch | null>(
    null
  );

  const handleOnRangeChange = (dates: any, dateStrings: [string, string]) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    setSearchValue(oldState => ({
      ...oldState,
      startDate,
      endDate
    }));
  };

  const handleExport = async () => {
    try {
      const response = await exportVendorApplicationDateRange(
        params.id || '',
        searchValue?.startDate || "",
        searchValue?.endDate || ""
      );
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `applications-${searchValue?.startDate || ""} - ${
          searchValue?.endDate
        }.csv`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.log("ERROR DOWNLOADING USERS> ", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "User",
        dataIndex: "owner",
        key: "owner",
        render: (record: any, data: any) => {
          return (
            <div
              onClick={() => navigate(`/dashboard/applications/${data._id}`)}
            >
              <Typography.Title level={5}>
                {data.owner?.firstName} {data.owner?.lastName}
              </Typography.Title>
              <span>{data.owner?.email}</span>
            </div>
          );
        }
      },
      {
        title: "Device",
        dataIndex: "product",
        key: "device",
        render: (record: any) => {
          return <>{record?.name}</>;
        }
      },
      {
        title: "Details",
        dataIndex: "phoneStorage",
        key: "phoneStorage",
        render: (record: any, data: any) => {
          return (
            <>
              {record},{data.phoneColor}
            </>
          );
        }
      },

      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: record => formatDateTime(record)
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: record => getTag(record)
      }
    ],
    []
  );


  useEffect(() => {
    const formValues = sanitizeValues(form.getFieldsValue());

    ApplicationResponse.mutate({
      ...formValues,
      page: paginations.query.page,
      size: paginations.query.size
    });
  }, [paginations.query]);

  return (
    <div className="container profile-cont">
      <Card
        title={
          <Row className="mb-3">
            <span className="table-title">
              Showing {applications.length || 0} of {paginations.totalDocs || 0}{" "}
              results for{" applications"}
            </span>

            <Col sm={20} xs={24} md={20} lg={20} xl={18}>
              <DatePicker.RangePicker
                onChange={handleOnRangeChange}
                ranges={RANGE_PRESETS}
                value={[
                  moment(searchValue?.startDate || new Date()),
                  moment(searchValue?.endDate || new Date())
                ]}
              />
              <Button onClick={handleExport}>
                <CloudDownloadOutlined />
                Export
              </Button>
            </Col>

          </Row>

          
        }
        // extra={
        //   <div
        //     style={{
        //       position: "relative",
        //       display: "flex",
        //       alignItems: "center",
        //       gap: "10px"
        //     }}
        //   >
        //     <Popover
        //       placement="bottomRight"
        //       title={"Filter "}
        //       content={UserFilterComponent}
        //       trigger="click"
        //     >
        //       <Button className="flex filter-btn">
        //         Filter
        //         <img
        //           src={filter}
        //           style={{ width: "16px", marginRight: "5px" }}
        //           alt="filter"
        //           className="pl-3"
        //         />
        //       </Button>
        //     </Popover>
        //     <Popover
        //       placement="bottomRight"
        //       title={"Filter "}
        //       content={UsersExportComponent}
        //       trigger="click"
        //     >
        //       <Button className="flex filter-btn">Export as CSV</Button>
        //     </Popover>
        //   </div>
        // }
      >
        <Table
          locale={{ emptyText: <Empty /> }}
          pagination={{
            showSizeChanger: true,
            total: paginations.totalDocs,
            pageSize: paginations.query.size,
            onChange(page, size) {
              console.log({page, size});
              setPaginations({
                ...paginations,
                query: { ...paginations.query, page, size }
              });
            },
            onShowSizeChange(page, size) {
              setPaginations({
                ...paginations,
                query: { ...paginations.query, page, size }
              });
            },
            pageSizeOptions: ["5", "10", "20", "30", "40"]
          }}
          columns={columns}
          dataSource={applications || []}
          scroll={{ x: 1000 }}
          loading={ApplicationResponse.isLoading}
        />
      </Card>
    </div>
  );
};

export default VendorApplications;
