import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { LiaTimesSolid } from "react-icons/lia";
type SelectedItemProps = {
  name?: string;
  imagePath?: string;
  onClick?: (data?: any) => void;
};
export default function SelectedItem({
  name,
  imagePath,
  onClick
}: SelectedItemProps) {
  return (
    <Box>
      {name && (
        <Center
          gap={"0.5rem"}
          border={"1px solid black"}
          borderRadius={"20px"}
          padding={"0.2rem 0.5rem"}
          fontSize={"14px"}
        >
          <Text>{name}</Text>
          <LiaTimesSolid cursor={"pointer"} onClick={onClick} />
        </Center>
      )}
      {imagePath && (
        <Flex
          flexDirection={"column"}
          gap={"0.5rem"}
          border={"1px solid black"}
          borderRadius={"4px"}
          padding={"0.5rem 0.2rem 0.8rem 1rem"}
        >
          <Box alignSelf={"flex-end"}>
            <LiaTimesSolid onClick={onClick} cursor={"pointer"} />
          </Box>
          <Box mr={"0.6rem"} maxW={"16"}>
            <img width={"100%"} src={imagePath} alt="phone" />
          </Box>
        </Flex>
      )}
    </Box>
  );
}
