import {
    Form,
    Input,
    FormInstance,
    Row,
    Typography,
    Col,
    Space,
    Button,
  } from "antd";
  import { FC, useEffect, useRef, useState } from "react";
  import { updatePhoneToBusiness} from "../../../api/business";
  import { showMessage } from "../../../helpers";
  import { MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

const UpdateDeviceModal: FC<{
  device: any;
  vendor: any;
  reload: any;
}> = ({ device, vendor, reload }) => {
  const onLoad = () => {
    form.setFieldsValue({
      prices: device.pricing
    });
  };
  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  const [isLoading, setLoading] = useState(false);
  const formRef = useRef<FormInstance>(null);
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      if (isLoading) return;
      const payload = form.getFieldsValue();
      const prices = payload.prices.map((item: any) => {
        return {
          price: Number(item.price),
          storageRom: item.storageRom,
          storageRam: item.storageRam
        };
      });

      setLoading(true);
      const request = {
        productId: device._id,
        businessId: vendor._id,
        prices: prices
      };
      await updatePhoneToBusiness(request);
      reload.mutate();
      setLoading(false);

      showMessage(
        "Product Updated successfully",
        "Operation successful",
        "success"
      );

      form.resetFields();
    } catch (error) {
      console.log("error> ", error);
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="profile-cont grid" style={{ gap: "1rem" }}>
      <Col span={24}>
        <p style={{ fontSize: "16px", fontStyle: "bold", fontWeight: "700" }}>
          {" "}
          Business name:
          <span
            style={{ fontSize: "16px", fontWeight: "400", marginLeft: "1rem" }}
          >
            {" "}
            {vendor.name}{" "}
          </span>
        </p>
      </Col>
      <Col span={24}>
        <p style={{ fontSize: "16px", fontStyle: "bold", fontWeight: "700" }}>
          {" "}
          Device name:
          <span style={{ fontSize: "16px", fontWeight: "400" }}>
            {" "}
            {device.name}{" "}
          </span>
        </p>
      </Col>

      <Col span={24}>
        <p style={{ fontSize: "16px", fontStyle: "bold", fontWeight: "700" }}>
          {" "}
          Condition:
          <span style={{ fontSize: "14px", fontWeight: "400" }}>
            {" "}
            {device.condition}{" "}
          </span>
        </p>
      </Col>

      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        ref={formRef}
        form={form}
        onFinishFailed={onFinishFailed}
        style={{ maxWidth: 960 }}
      >
        <Row gutter={8}>
          <Col span={24}>
            <Typography.Title level={5} className="form-title">
              Prices
            </Typography.Title>

            <Form.List name="prices">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Row gutter={16}>
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            label="RAM"
                            name={[name, "storageRam"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input your RAM!"
                              }
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            label="ROM"
                            name={[name, "storageRom"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input your ROM!"
                              }
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            label="Price"
                            name={[name, "price"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input the price!"
                              }
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col
                          span={4}
                          className="flex"
                          style={{
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      className="pcard-btn"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Row className="modl-btn flex">
          <Button
            className="mod-btn"
            onClick={handleSubmit}
            loading={isLoading}
          >
            Update
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default UpdateDeviceModal;
