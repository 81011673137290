import { IApplicationFilterProps, IApplicationSearch } from "@app-interfaces";
import { Button, Form, Input, Radio, Row, Space } from "antd";
import { FC } from "react";

const ApplicationFilter: FC<IApplicationFilterProps> = ({
  loading,
  onFilter,
  onReset
}) => {
  const [form] = Form.useForm();
  const onFinish = (values: IApplicationSearch) => {
    onFilter && onFilter(values);
  };
  const handleFormReset = () => {
    form.resetFields();
    onReset && onReset();
  };
  return (
    <div>
      <Form
        onFinish={onFinish}
        layout="vertical"
        style={{ width: "400px" }}
        form={form}
      >
        <Form.Item
          rules={[{ required: false }]}
          label="Application ID/User/Product"
          name={"keyword"}
        >
          <Input placeholder="search by product, applicant's name" />
        </Form.Item>
        <Form.Item label="Status" initialValue={"ALL"} name="status">
          <Radio.Group>
            <Space direction="vertical" size={"middle"}>
              <Radio value={"ALL"}>All</Radio>
              <Radio value={"APPROVED"}>Approved</Radio>
              <Radio value={"PENDING"}>Pending</Radio>
              <Radio value={"DECLINED"}>Declined</Radio>
              <Radio value={"DELETED"}>Deleted</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Row justify={"center"} gutter={[16, 0]}>
          <Button
            htmlType="submit"
            className="keza-btn-primary"
            type="primary"
            loading={loading}
          >
            Filter
          </Button>
          <Button onClick={handleFormReset}>Reset</Button>
        </Row>
      </Form>
    </div>
  );
};

export default ApplicationFilter;
