import { Card, Col, Row, Table } from "antd";
import CounterBox from "../Dashboard/CounterBox/CounterBox";
import folderIcon from "../../assets/folderIcon.svg";
import { useMemo } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { formatAsCurrency } from "../../utilities/number-formatter";
import { getTag } from "../../utilities/sanitizer";
import { useQuery } from "react-query";
import { getMandatePaymentHistory } from "../../api/application";

const DebitHistory = () => {
  const navigate = useNavigate();
  const params = useParams();

  const mandatePaymentResponse = useQuery({
    queryKey: ["mandate-payment-history", params.id],
    queryFn: async () => {
      const response = await getMandatePaymentHistory(params.id as string);
      return response.data.data;
    },
  });

  const columns = useMemo(
    () => [
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (record: any) => {
          return <>{formatAsCurrency(record)}</>;
        },
      },
      {
        title: "Last Update Time",
        dataIndex: "lastStatusUpdateTime",
        key: "lastStatusUpdateTime",
      },
      {
        title: "status",
        dataIndex: "status",
        key: "status",
        render: (record: any) => {
          return <>{getTag(record)}</>;
        },
      },
      {
        title: "Status Code",
        dataIndex: "statuscode",
        key: "statuscode",
      },
      {
        title: "RRR",
        dataIndex: "RRR",
        key: "RRR",
        render: (record: any) => {
          return <>{record}</>;
        },
      },
      {
        title: "Transaction Reference",
        dataIndex: "transactionRef",
        key: "transactionRef",
        render: (record: any) => {
          return <>{record}</>;
        },
      },
    ],
    []
  );
  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"],
    }),
    []
  );
  return (
    <div className="container">
      <Row className="mb-3">
        <Col span={24}>
          <span className="cursor-pointer" onClick={() => navigate(-1)}>
            <ArrowLeftOutlined /> Back
          </span>
        </Col>
      </Row>
      <div className="row mb-5">
        <div className="col-md-12">
          <Row>
            <Col span={24}>
              <Card
                title="Debit Summary"
                extra={<>Application ID: {params.id}</>}
              >
                <Row gutter={[16, 16]}>
                  <CounterBox
                    loading={mandatePaymentResponse.isLoading}
                    IconPath={folderIcon}
                    title="Total Transaction Count"
                    value={mandatePaymentResponse.data?.totalTransactionCount}
                    span={12}
                  />
                  <CounterBox
                    loading={mandatePaymentResponse.isLoading}
                    IconPath={folderIcon}
                    title="Total Amount"
                    value={formatAsCurrency(
                      mandatePaymentResponse.data?.totalAmount
                    )}
                    span={12}
                  />
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <Table
            loading={mandatePaymentResponse.isLoading}
            dataSource={mandatePaymentResponse.data?.paymentDetails || []}
            columns={columns}
            pagination={pagination}
            scroll={{ x: 1000 }}
          />
        </div>
      </div>
    </div>
  );
};

export default DebitHistory;
