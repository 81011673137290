import { Card, Col, Row, Spin, Select } from "antd";
import { FC } from "react";
import { numberWithCommas } from "../../../utilities/number-formatter";
import "./counterbox.css";

interface IProps {
  IconPath?: string;
  title: string;
  value?: string;
  loading?: boolean;
  span?: number;
  Data?: String[];
}

const CounterBox: FC<IProps> = ({
  IconPath,
  title,
  value,
  loading,
  span,
  Data = []
}) => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  return (
    <Col span={span || 8}>
      <Card className="counter-box">
        <Col>
          <Row>
            {IconPath && (
              <img
                src={IconPath}
                alt="application"
                className="pr-8"
                style={{ width: 22 }}
              />
            )}
            <span className="text-top">{title}</span>
          </Row>
          <br />
          {loading ? (
            <Spin />
          ) : (
            <h4 className="text-center overview-count">
              {numberWithCommas(value) || 0}
            </h4>
          )}
        </Col>
        {Data.length > 0 && (
          <Col>
            <Select
              defaultValue=""
              className="select-filter"
              style={{ width: 100 }}
              onChange={handleChange}
              options={[
                Data?.map((data: any) => {
                  return { value: data, label: data };
                })
              ]}
              size="small"
            />
          </Col>
        )}
      </Card>
    </Col>
  );
};

export default CounterBox;
