import axios from "axios";
import { showError } from "../helpers";

const Axios = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

Axios.interceptors.response.use(
  (value) => Promise.resolve(value),
  (error) => {
    showError(error);
    return Promise.reject(error);
  }
);
export const loginApi = (email: any, password: any) => {
  return Axios.post("/auth/admin/login", { email, password });
};
