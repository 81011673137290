import { Button, Col, Empty, Row, Typography, Modal, Card } from "antd";
import SectionHeader from "../components/SectionTitle";
import { FC, useCallback, useState } from "react";
import { formatDate } from "../../../utilities/date-formatter";
import { formatAsCurrency } from "../../../utilities/number-formatter";
import { useMutation } from "react-query";
import { getUserKyc } from "../../../api/application";
import { showError, showMessage } from "../../../helpers";
import { useParams } from "react-router-dom";
import { getTag } from "../../../utilities/sanitizer";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { UserPofileState } from "../../../resources/user.resource";
import { useRecoilValue } from "recoil";
import { useGetMandateStatusVfd } from "../../../api/mutations/appications";
import pdfIcon from "../../../assets/pdf-icon.jpeg";
const UserProfile: FC<{
  user: any;
  applicationId?: any;
  application?: any;
}> = ({ user, applicationId = "", application }) => {
  //const address = user?.addressDetail;

  const params = useParams();
  const { getMandateStatus, getMandateStatusResult } = useGetMandateStatusVfd();
  const {
    data: userKycs,
    mutate,
    isLoading
  } = useMutation({
    mutationFn: async () => {
      const response = await getUserKyc(user._id || (params.id as string));
      if (response.data) {
        setOpen(true);
        return response.data;
      }
    },
    onError: error => {
      showError(error);
    }
  });

  const [open, setOpen] = useState(false);
  const profile = useRecoilValue(UserPofileState);

  const isUriImage = (url: string) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };
  const checkmandateStatus = async () => {
    const res = await getMandateStatus(applicationId!);
    const isActive = res?.data?.data?.mandateState === "ACTIVE";
    showMessage(
      isActive ? "ACTIVE" : "NOT ACTIVE",
      isActive ? "Mandate is active" : "Mandate is not active",
      isActive ? "success" : "error"
    );
  };
  const handleDownloadFile = useCallback(async (item: any) => {
    const response = await fetch(item.secure_url);
    const blob = await response.blob();
    const file = new File(
      [blob],
      `${
        user ? `${user?.firstName} ${user?.lastName}` : `${item?.asset_id}`
      } Identification`,
      { type: item?.type }
    );

    const url = window.URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
  }, []);


  return (
    <div className="container profile-cont">
      <div className="row">
        <div className="col-md-12">
          <Row gutter={[32, 0]} justify={"space-between"}>
            <Col>
              <Col className="header-span">
                <span>User ID:</span> {user._id}
              </Col>
              <Col className="header-span">
                <span>Status:</span>{" "}
                {getTag(user?.isApproved ? "Approved" : "Pending")}
              </Col>
              <Col className="header-span">
                <span>Card Added:</span>{" "}
                {getTag(user?.customerHasCard ? "success" : "pending")}
              </Col>
              <Col className="header-span">
                <span>VFD Mandate:</span>{" "}
                <Button
                  onClick={checkmandateStatus}
                  disabled={!user?.vfdMandateDetail}
                  loading={getMandateStatusResult.isLoading}
                >
                  {user?.vfdMandateDetail ? "View Status" : "Not active"}
                </Button>
              </Col>
            </Col>
            <Col>
              <Row gutter={[16, 0]}>
                <Col>
                  <Button>
                    <MailOutlined /> Send mail
                  </Button>
                </Col>
                <Col>
                  <Button>
                    <PhoneOutlined /> Contact
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
          {profile.role === "AGENT" ? (
            <>
              <Row>
                <Col span={12} className="flex info-items">
                  <Row>
                    <Col>
                      <SectionHeader title="Personal details" />
                    </Col>
                  </Row>

                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">First name</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.firstName}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Last name</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.lastName}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <strong></strong>
                        <span className="info-span">Email address</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.email}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Phone number</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.phone_number}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col span={12} className="flex info-items">
                  <Row>
                    <Col>
                      <SectionHeader title="Personal details" />
                    </Col>
                  </Row>

                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">First name</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.firstName}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Last name</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.lastName}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <strong></strong>
                        <span className="info-span">Email address</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.email}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Phone number</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.phone_number}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={12} className="flex info-items">
                  <Row gutter={[16, 0]}>
                    <Col>
                      <SectionHeader title="Identity verification" />
                    </Col>
                    <Col>
                      <>
                        <Button
                          loading={isLoading}
                          className="md-btn"
                          onClick={() => {
                            if (!userKycs) {
                              mutate();
                            } else {
                              setOpen(true);
                            }
                          }}
                        >
                          Look Up
                        </Button>
                        <Modal
                          open={open}
                          onOk={() => setOpen(false)}
                          onCancel={() => setOpen(false)}
                          footer={null}
                          width={450}
                          className="lookUp"
                        >
                          {userKycs && (
                            <Col>
                              <div className="modal-cont flex">
                                <Row gutter={[16, 0]} justify="space-around">
                                  <Col span={12}>
                                    {isUriImage(userKycs?.suppliedImage) ? (
                                      <img
                                        src={userKycs?.suppliedImage}
                                        alt="user_img"
                                        className="modal-img"
                                      />
                                    ) : (
                                      <img
                                        src={`data:image/png;base64,${userKycs?.suppliedImage}`}
                                        alt="user_img"
                                        className="modal-img"
                                      />
                                    )}
                                    <h2 className=" modal-name">Bvn Image</h2>
                                  </Col>
                                  <Col span={12}>
                                    <img
                                      src={
                                        user?.identity?.supplied ||
                                        user?.photoUrl
                                      }
                                      alt="user_img"
                                      className="modal-img"
                                    />
                                    <h2 className=" modal-name">
                                      Supplied Image
                                    </h2>
                                  </Col>
                                </Row>
                                <h2 className=" modal-name">{`${userKycs?.firstName} ${userKycs?.lastName}`}</h2>
                              </div>
                              <div className="modal-cont2 grid">
                                <div className="flex modal-if">
                                  <p className="modal-if-title">First Name</p>
                                  <p className="modal-if-description">
                                    {userKycs?.firstName}
                                  </p>
                                </div>

                                <div className="flex modal-if">
                                  <p className="modal-if-title">Middle Name</p>
                                  <p className="modal-if-description">
                                    {userKycs?.middleName}
                                  </p>
                                </div>
                                <div className="flex modal-if">
                                  <p className="modal-if-title">Surname</p>
                                  <p className="modal-if-description">
                                    {userKycs?.lastName}
                                  </p>
                                </div>
                                <div className="flex modal-if">
                                  <p className="modal-if-title">Gender</p>
                                  <p className="modal-if-description">
                                    {userKycs?.gender}
                                  </p>
                                </div>
                                <div className="flex modal-if">
                                  <p className="modal-if-title">
                                    Date Of Birth
                                  </p>
                                  <p className="modal-if-description">
                                    {userKycs?.dateOfBirth}
                                  </p>
                                </div>
                                <div className="flex modal-if">
                                  <p className="modal-if-title">
                                    Residential Address
                                  </p>
                                  <p className="modal-if-description">
                                    {userKycs?.residentialAddress}
                                  </p>
                                </div>
                                <div className="flex modal-if">
                                  <p className="modal-if-title">Phone Number</p>
                                  <p className="modal-if-description">
                                    {userKycs?.phoneNumber}
                                  </p>
                                </div>
                                <div className="flex modal-if">
                                  <p className="modal-if-title">Bvn</p>
                                  <p className="modal-if-description">
                                    {userKycs?.bvn}
                                  </p>
                                </div>
                                <div className="flex modal-if">
                                  <p className="modal-if-title">Nin</p>
                                  <p className="modal-if-description">
                                    {userKycs?.nin}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          )}
                        </Modal>
                      </>
                    </Col>
                  </Row>

                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">BVN</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.bvn}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Date of birth</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {formatDate(user?.dateOfBirth)}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Home address</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.address}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    {user?.identity || user.photoUrl ? (
                      <>
                        <Col>
                          {user?.identity?.stored ? (
                            isUriImage(user?.identity?.stored) ? (
                              <img
                                className="img-thumb"
                                src={user?.identity?.stored}
                                alt="kea"
                              />
                            ) : (
                              <img
                                className="img-thumb"
                                src={`data:image/png;base64,${user?.identity?.stored}`}
                                alt="kea"
                              />
                            )
                          ) : (
                            <Empty />
                          )}
                          <Typography.Text>
                            <strong>BVN Image</strong>
                          </Typography.Text>
                        </Col>
                        <Col>
                          {user?.photoUrl ? (
                            <img
                              className="img-thumb"
                              src={user?.photoUrl}
                              alt="kea"
                            />
                          ) : user?.identity?.supplied ? (
                            <img
                              className="img-thumb"
                              src={user?.identity?.supplied}
                              alt="kea"
                            />
                          ) : (
                            <Empty />
                          )}

                          <Typography.Text>
                            <strong>Image Uploaded</strong>
                          </Typography.Text>
                        </Col>
                      </>
                    ) : (
                      <Empty />
                    )}
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Identification</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                          <Col>
                            {user?.idUrl ? (
                              isUriImage(user?.idUrl) ? (
                                <img
                                  className="img-thumb"
                                  src={user?.idUrl}
                                  alt="img"
                                />
                              ) : (
                                <Col className="cursor-pointer" span={8}>
                                  <button
                                    onClick={() =>
                                      handleDownloadFile(user?.idUrl)
                                    }
                                  >
                                    <Card>
                                      <img
                                        className="upload-file-doc-img"
                                        src={pdfIcon}
                                        alt="pdf"
                                      />
                                      <div className="pt-3">
                                        <span>Identification</span>
                                      </div>
                                    </Card>
                                  </button>
                                </Col>
                              )
                            ) : (
                              <Empty />
                            )}
                          </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={12} className="flex info-items">
                  <Row>
                    <Col>
                      <SectionHeader title="Employment and Finances" />
                    </Col>
                  </Row>

                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Employment status</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.employmentDetail?.employmentStatus}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">
                          Employer/Company/Busniess
                        </span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.employmentDetail?.employerName}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Work address</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.employmentDetail?.officeAddress}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Official Email</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.employmentDetail?.email}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Monthly Income</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {formatAsCurrency(
                          user?.employmentDetail?.monthlyIncome
                        )}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Bank Provider</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des"></Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Account Number</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.accountNumber}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Bank statement</span>
                      </Typography.Text>
                    </Col>
                    <Col></Col>
                  </Row>
                </Col>
                <Col span={12} className="flex info-items">
                  <Row>
                    <Col>
                      <SectionHeader title="Social" />
                    </Col>
                  </Row>
                  <div className="mb-3"></div>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Facebook</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.social?.facebook}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">LinkedIn</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.social?.linkedin}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Instagram</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.social?.instagram}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Twitter</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.social?.twitter}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>

                <Col span={12} className="flex info-items">
                  <Row>
                    <Col>
                      <SectionHeader title="Address" />
                    </Col>
                  </Row>
                  <div className="mb-3"></div>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Street Address</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.addressDetail?.address}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">City</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.addressDetail?.city}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">State</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.addressDetail?.state}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Addition Information</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.addressDetail?.additionalInformation}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>

                <Col span={12} className="flex info-items">
                  <Row>
                    <Col>
                      <SectionHeader title="Endoser" />
                    </Col>
                  </Row>
                  <div className="mb-3"></div>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Name</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.endoser?.name}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Email</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.endoser?.email}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Phone Number</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.endoser?.phoneNumber}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Residential Address</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.endoser?.residential_address}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Work Email</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.endoser?.workEmail}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Company</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.endoser?.companyName}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Employment Status</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {user?.endoser?.employmentStatus}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
