import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Box,
  Button,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LeftDetails from "./components/LeftDetails";
import RighDetails from "./components/RighDetails";
import { Product } from "../../types/product";
import { useCreateProduct } from "../../api/mutations/product";
import { validateProduct } from "./utils/validate-product";
import { showMessage } from "../../helpers";
import ImportModal from "./components/ImportModal";
import { useRecoilState } from "recoil";
import { ImportedPhoneState } from "../../resources/product.resource";
import { initialProduct } from "./utils/initial-product";


export default function AddProduct() {
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product>(initialProduct as Product);
  const { createProduct, result } = useCreateProduct();
  const { onClose, onOpen, isOpen } = useDisclosure();
  const [importedPhone, setImportedPhone] =
    useRecoilState<any>(ImportedPhoneState);

  const uploadProduct = async () => {
    const { valid, message } = validateProduct(product);
    if (!valid) {
      showMessage("Invalid operation", message, "error");
      return;
    }
    try {
       await createProduct(product);
      showMessage("Operation successfull", "Product uploaded", "success");
      setProduct(initialProduct as Product);
    } catch (error) {
      return;
    }
  };
  useEffect(()=>{
    if(importedPhone.name){      
      setProduct(importedPhone)
    }
  },[importedPhone])
  return (
    <Box>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Flex
          alignItems={"center"}
          gap="4"
          onClick={() => navigate(-1)}
          cursor={"pointer"}
        >
          <ArrowLeftOutlined />
          <Text> Back to Products</Text>
        </Flex>

        <Box>
          <Button
            variant={"outline"}
            borderColor={"var(--keza-brown)"}
            color={"var(--keza-brown)"}
            onClick={onOpen}
          >
            Import device
          </Button>
        </Box>
      </Flex>
      <SimpleGrid columns={2} mt={"8"} columnGap={"24"}>
        <GridItem>
          <LeftDetails product={product} setProduct={setProduct} />
        </GridItem>
        <GridItem>
          <RighDetails product={product} setProduct={setProduct} />
        </GridItem>
      </SimpleGrid>

      <Box mt={"16"} maxW={"10rem"} ml={"auto"}>
        <Button
          onClick={uploadProduct}
          color={"white"}
          bg={"var(--keza-brown)"}
          isLoading={result.isLoading}
        >
          Save Changes
        </Button>
      </Box>
      <ImportModal onClose={onClose} isOpen={isOpen} />
    </Box>
  );
}
