const moment = require("moment");

export const getTodayDate = () => {
  return moment().format("Do MMMM YYYY");
};

export const formatDate = (date: string) => {
  return moment(date).format("DD/MM/YYYY");
};

// h:mm:ss a

export const formatDateTime = (date: string) => {
  return moment(date).format("DD/MM/YYYY, h:mm:ss a");
};
export const getDay = () => {
  return moment().format("dddd");
};

export const RANGE_PRESETS: {} = {
  Today: [moment(), moment()],
  "Last 7 Days": [moment().startOf("week"), moment().endOf("week")],
  "This Month": [moment().startOf("month"), moment().endOf("month")]
};
