import { Box, Button, Flex} from "@chakra-ui/react";
import { TextInput } from "../../../components/Input/Input";
import SelectedItem from "../../AddProduct/components/SelectedItem";
import { useCallback, useState } from "react";
import { Imeis } from "../../../types/imeis";
import { useUpdateDeviceImeis } from "../../../api/mutations/appications";
import { showError, showMessage } from "../../../helpers";
import { InitialImeis } from "./utils/initial-imei";

export default function UpdateDeviceImeiModal({ applicationId, close }: any) {
  const { updateDeviceImeis, updateDeviceImeisResult } = useUpdateDeviceImeis();
  const [imeis, setImeis] = useState<Imeis>(InitialImeis as any);
  const [imei, setImei] = useState<string>("");
  //const params = useParams();

  const handleKeyPress = () => {
        setImeis({
          ...imeis,
          imeis: [...imeis.imeis, imei]
        });
        setImei("");
  };

  const handleUpdate = useCallback(async () => {
    try {
       await updateDeviceImeis({
        applicationId: applicationId,
        imeis:imeis.imeis
      })
     showMessage("Operation successfull", "Imei added", "success")
    } catch (error) {
      showError(error);
    }
    setImeis(InitialImeis);
    close();
  }, [imeis]);

  return (
    <div>
      <Box maxW={"100%"}>
        <TextInput
          label="Device Imeis"
          name="device"
          onChange={(e: any) => setImei(e.target.value)}
          value={imei}
          //onKeyDown={(e: any) => handleKeyPress(e, "imeis")}
          placeholder="Input device imei"
        />
        <Button
        onClick={handleKeyPress}
        _active={{
          bg: "#AA4428",
          border: "1px solid #AA4428"
        }}
        _hover={{
          bg: "#AA4428",
          border: "1px solid #AA4428"
        }}
        bg={"#AA4428"}
        border={"1px solid #AA4428"}
        borderRadius={'4px'}
        margin={'1rem'}
        padding={"1rem"}
        color={"#fff"}
        fontSize={"16px"}
        fontWeight={"700"}
        >Add imei</Button>
        {imeis?.imeis?.length > 0 ? (
          <Flex mt={"4"} flexWrap={"wrap"} gap={"4"}>
            {imeis?.imeis?.map((item, index) => (
              <Box key={index}>
                <SelectedItem
                  name={item}
                  onClick={() =>
                    setImeis({
                      ...imeis,
                      imeis: imeis?.imeis?.filter(imei => imei !== item)
                    })
                  }
                />
              </Box>
            ))}
          </Flex>
        ) : undefined}
      </Box>
      <Flex
        maxWidth={"100%"}
        marginY="1rem"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Button
          onClick={handleUpdate}
          _active={{
            bg: "#AA4428",
            border: "1px solid #AA4428"
          }}
          _hover={{
            bg: "#AA4428",
            border: "1px solid #AA4428"
          }}
          bg={"#AA4428"}
          border={"1px solid #AA4428"}
          borderRadius={'4px'}
          padding={"1rem"}
          color={"#fff"}
          fontSize={"18px"}
          fontWeight={"700"}
          isLoading={updateDeviceImeisResult.isLoading}
        >
          Update
        </Button>
      </Flex>
    </div>
  );
}
