import {
  Form,
  Input,
  FormInstance,
  Row,
  Typography,
  Col,
  Space,
  Button,
} from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { addPhoneToBusiness } from "../../../api/business";
import { showMessage } from "../../../helpers";
import { MinusCircleOutlined } from "@ant-design/icons";

const DeviceModal: FC<{
  device: any;
  vendor: any;
  reload:any;
  setOpen:any
}> = ({ device, vendor, reload,setOpen }) => {

  
  const onLoad = () => {
    form.setFieldsValue({
      prices: device.pricing
    });
  };
  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  const [isLoading, setLoading] = useState(false);
  const formRef = useRef<FormInstance>(null);
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      if (isLoading) return;
      const payload = form.getFieldsValue();
      const prices = payload.prices.map((item: any) => {
        return {
          price: Number(item.priceNew),
          storageRom: item.storageROM,
          storageRam: item.storageRAM
        };
      });

      setLoading(true);
      const request = {
        businessId: vendor._id,
        productId: device._id,
        prices: prices
      };

      await addPhoneToBusiness(request);
      setOpen(false)
      setLoading(false);

      showMessage(
        "Product created successfully",
        "Operation successful",
        "success"
      );

      form.resetFields();
    } catch (error) {
      console.log("error> ", error);
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="profile-cont grid" style={{ gap: "1rem" }}>
      <Col span={24}>
        <p style={{ fontSize: "16px", fontStyle: "bold", fontWeight: "700" }}>
          {" "}
          Business name:
          <span
            style={{ fontSize: "16px", fontWeight: "400", marginLeft: "1rem" }}
          >
            {" "}
            {vendor.name}{" "}
          </span>
        </p>
      </Col>
      <Col span={24}>
        <p style={{ fontSize: "16px", fontStyle: "bold", fontWeight: "700" }}>
          {" "}
          Device name:
          <span style={{ fontSize: "16px", fontWeight: "400" }}>
            {" "}
            {device.name}{" "}
          </span>
        </p>
      </Col>

      <Col span={24}>
        <p style={{ fontSize: "16px", fontStyle: "bold", fontWeight: "700" }}>
          {" "}
          Condition:
          <span style={{ fontSize: "14px", fontWeight: "400" }}>
            {" "}
            {device.condition}{" "}
          </span>
        </p>
      </Col>

      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        ref={formRef}
        form={form}
        onFinishFailed={onFinishFailed}
        style={{ maxWidth: 960 }}
      >
        <Row gutter={8}>
          <Col span={24}>
            <Typography.Title level={5} className="form-title">
              Prices
            </Typography.Title>

            <Form.List name="prices">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Row gutter={16}>
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            label="RAM"
                            name={[name, "storageRAM"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input your RAM!"
                              }
                            ]}
                          >
                            <Input readOnly />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            label="ROM"
                            name={[name, "storageROM"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input your ROM!"
                              }
                            ]}
                          >
                            <Input readOnly />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            label="Price"
                            name={[name, "priceNew"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input the price!"
                              }
                            ]}
                          >
                            <Input/>
                          </Form.Item>
                        </Col>

                        <Col
                          span={4}
                          className="flex"
                          style={{
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <MinusCircleOutlined />
                        </Col>
                      </Row>
                    </Space>
                  ))}
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Row className="modl-btn flex">
          <Button className="mod-btn" onClick={handleSubmit} loading={isLoading}>
            Add to shelf
          </Button>
        </Row>
      </Form>
    </div>
  );
};
export default DeviceModal;
