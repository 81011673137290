import {
  Box,
  Flex,
  Center,
  GridItem,
  SimpleGrid,
  Text,
  Stack
} from "@chakra-ui/layout";
import { Button, Col, Empty, Row, Typography, Modal } from "antd";
import { getTag } from "../../../utilities/sanitizer";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useDisclosure } from "@chakra-ui/react";
import ResetModal from "./ResetModal";
import { useGetMandateStatusVfd } from "../../../api/mutations/appications";
import { showMessage } from "../../../helpers";

const userKeys = ["firstName", "lastName", "phone_number", "email"];
function Item({ name, value }: { name?: string; value?: string }) {
  return (
    <Flex alignItems={"center"} justifyContent={"flex-start"}>
      <Text fontSize={"14px"} opacity={"0.6"} mr={"8"}>
        {name}:
      </Text>
      <Box>
        {value?.startsWith("https://res.cloudinary.com") ? (
          <img src={value} />
        ) : (
          <Text fontWeight={"bold"}>{value}</Text>
        )}
      </Box>
    </Flex>
  );
}

function Section({
  label,
  data,
  name,
  applicationId
}: {
  label: string;
  data: any;
  name?: string;
  applicationId?: string;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Box>
      <Flex gap={"4"} alignItems={"center"}>
        <Center mb={"4"} w={"14rem"} p={"2"} bg={"#fff2df"}>
          <Text fontWeight={"bold"}>{label}</Text>
        </Center>
        {label !== "Personal details" && (
          <Button onClick={onOpen}>Reset Section</Button>
        )}
      </Flex>
      <Flex justifyContent={"flex-start"} direction={"column"}>
        {Object.entries(data)?.map(([key, value]: any, index: number) => {
          return (
            <Box key={index}>
              <Item name={key} value={value} />
            </Box>
          );
        })}
      </Flex>
      <ResetModal
        category={name}
        isOpen={isOpen}
        onClose={onClose}
        data={data}
        applicationId={applicationId}
      />
    </Box>
  );
}
export default function BusinessApplication({ data }: { data: any }) {
  const businessData = data?.data?.businessCustomer;
  const applicationId = data?.data?._id;
  const { getMandateStatus, getMandateStatusResult } = useGetMandateStatusVfd();

  let personalDetails: any = {};
  Object.entries(data?.data?.owner)?.forEach(([key, value]) => {
    if (userKeys.includes(key)) {
      personalDetails[key] = value;
    }
  });
  const checkmandateStatus = async () => {
    const res = await getMandateStatus(applicationId!);
    const isActive = res?.data?.data?.mandateState === "ACTIVE";
    showMessage(
      isActive ? "ACTIVE" : "NOT ACTIVE",
      isActive ? "Mandate is active" : "Mandate is not active",
      isActive ? "success" : "error"
    );
  };

  return (
    <Box p={"8"}>
      <Row gutter={[32, 0]} justify={"space-between"}>
        <Col>
          <Col className="header-span">
            <span>User ID:</span> {data?.data?._id}
          </Col>
          <Col className="header-span">
            <span>Status:</span>{" "}
            {getTag(data?.data?.isApproved ? "Approved" : "Pending")}
          </Col>
          <Col className="header-span">
            <span>Card Added:</span>{" "}
            {getTag(data?.data?.owner.customerHasCard ? "success" : "pending")}
          </Col>
          <Col className="header-span">
            <span>VFD Mandate:</span>{" "}
            <Button
              loading={getMandateStatusResult.isLoading}
              disabled={!data?.data?.owner?.vfdMandateDetail}
              onClick={checkmandateStatus}
            >
              {data?.data?.owner?.vfdMandateDetail
                ? "View Status"
                : "Not active"}
            </Button>
          </Col>
        </Col>
        <Col>
          <Row gutter={[16, 0]}>
            <Col>
              <Button>
                <MailOutlined /> Send mail
              </Button>
            </Col>
            <Col>
              <Button>
                <PhoneOutlined /> Contact
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <SimpleGrid my="8" gap={"8"} columns={2}>
        <GridItem>
          <Section label="Personal details" data={personalDetails} />
        </GridItem>

        {businessData?.identity && (
          <GridItem>
            <Section
              label="Identity verification"
              data={businessData?.identity}
              name="identity"
              applicationId={applicationId}
            />
          </GridItem>
        )}
        {businessData?.employment && (
          <GridItem>
            <Section
              label="Employment and Finances"
              data={businessData?.employment}
              name="employment"
              applicationId={applicationId}
            />
          </GridItem>
        )}
        {businessData?.endorser && (
          <GridItem>
            <Section
              label="Endoser"
              data={businessData?.endorser}
              name="endorser"
              applicationId={applicationId}
            />
          </GridItem>
        )}
      </SimpleGrid>
    </Box>
  );
}
