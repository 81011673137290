import { FC } from "react";
import { Button, Col, Row, Typography, Empty } from "antd";
import SectionHeader from "../components/SectionTitle";
import {  getSingleUser } from "../../../api/user";
//import { showMessage } from "../../../helpers";
import { getTag } from "../../../utilities/sanitizer";
import { useQuery } from "react-query";
import {  useParams } from "react-router-dom";
//import { formatDate } from "../../../utilities/date-formatter";
//import { formatAsCurrency } from "../../../utilities/number-formatter";

const OrderProfile : FC<{
    
  }> = () => {
    const params = useParams() as any;
    //const queryClient = useQueryClient();
    const orderDetailResponse= useQuery({
        queryKey: ["user", params.id],
        queryFn: async () => {
        const response = await getSingleUser(params.id);
        return response.data.data;
        },
    });
  return (
    
  <div className="container profile-cont">
    <div className="row">
      <div className="col-md-12">
        <Row gutter={[32, 0]} justify={"space-between"}>
            <Col>
              <Col className="header-span"><span>Order ID:</span> {orderDetailResponse?.data?._id}</Col>
              <Col className="header-span"><span>
               Status:</span>{" "}
               {getTag(
                  orderDetailResponse?.data?.isApproved
                    ? "Approved"
                     : "Pending"
               )}
              </Col>  
            </Col>
            <Col>
                        <Button
                        className="extra-btn"
                        >
                        Request Order
                        </Button>
                    </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col span={12} className="flex info-items">
            <Row>

              <Col>
                <SectionHeader title="Device details" />
              </Col>
            </Row>

            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Device name</span> 
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Device condition</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <strong></strong>
                  <span className="info-span">Capacity</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{""}</Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Color</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{""}</Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Device amount</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{""}</Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Loan amout</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{""}</Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Insurance</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{""}</Typography.Text>
              </Col>
            </Row>
            

          </Col>
          <Col span={12} className="flex info-items">
            <Row gutter={[16, 0]}>
              <Col>
                <SectionHeader title="Gallery" />
              </Col>
            </Row>
            
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                   
                    {<img
                        className="img-thumb"
                        src={''}
                        alt="kea"
                    />
                   || <Empty />}
              </Col>
            </Row>
            
          </Col>
        </Row>
        <Row>
          <Col span={12} className="flex info-items">
            <Row>
              <Col>
                <SectionHeader title="Paymnet plan" />
              </Col>
            </Row>
            
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                <span className="info-span">Payment terms</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                <span className="info-span">Down payment</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                <span className="info-span">Monthly repayment</span>
                </Typography.Text>
              </Col>
              <Col> 
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">total expected amount</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            
          </Col>
          <Col span={12} className="flex info-items">
            <Row>
              <Col>
                <SectionHeader title="Delivery and shipping" />
              </Col>
            </Row>
            
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                <span className="info-span">Delivery status</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                <span className="info-span">Residetial address</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                <span className="info-span">Shipping fee</span>
                </Typography.Text>
              </Col>
              <Col> 
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Expected delivery date</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Date delivered</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {""}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                  <Button className="extra-btn">
                    Track
                  </Button>
              </Col>
            </Row>
            
          </Col>
        </Row>
      </div>
    </div>
  </div>
  )
}

export default OrderProfile