import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Center
} from "@chakra-ui/react";
import { useUploadNovo } from "../../../api/mutations/product";
import { showError, showMessage } from "../../../helpers";

interface NameModalProps {
  isOpen: boolean;
  onClose: () => void;
  applicationId: string;
}

const NovoModal: React.FC<NameModalProps> = ({
  onClose,
  isOpen,
  applicationId
}) => {
  const [deviceId, setDeviceId] = useState("");
  const { uploadNovo, result } = useUploadNovo();
  const [getUpload, setGetUpload] = useState("");

  const handleSave = async () => {
    try {
      await uploadNovo({
        applicationId: applicationId,
        deviceId: deviceId
      });
      showMessage("Operation successful", "", "success");
      onClose();
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter Device ID</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Enter Device ID"
            value={deviceId}
            onChange={e => setDeviceId(e.target.value)}
          />
          <Center>
            <Button
              isLoading={result.isLoading}
              mt={4}
              colorScheme="blue"
              onClick={handleSave}
            >
              Save
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NovoModal;
