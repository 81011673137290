import { useState } from "react";
import ApplicationDetails from "./components/ApplicationDetails"
import Applications from "./components/Applications"
import { BusinessSection} from "../../enums/users";


const BusinessApplication = () => {

  const [section, setSection] = useState(BusinessSection.APPLICATIONS);
  const [details, setDetails] = useState<any>([]);
  return (
    <div className="container">
        <div className="row">
        {section === BusinessSection.APPLICATIONS && (
            <Applications section={setSection} details={setDetails}/>
        )}
        {section === BusinessSection.APPLICATIO_DETAILS && (
          <ApplicationDetails section={setSection} details={details}/>
        )}
            

            
        </div>
    </div>
  )
}

export default BusinessApplication