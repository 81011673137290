import { Button, Col, Empty, Row, Table } from "antd";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getBankStatment } from "../../../api/user";
import { showError } from "../../../helpers";
import { formatDate } from "../../../utilities/date-formatter";
import {
  formatAsCurrency,
  numberWithCommas
} from "../../../utilities/number-formatter";
import { getTag } from "../../../utilities/sanitizer";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

const PaymentHistory = () => {
  const params = useParams();
  const queryResponse = useQuery({
    queryFn: async () => {
      const response = await getBankStatment(params.id as string);
      return response.data.data;
    },
    queryKey: ["bank-statement", params.id]
  });

  const columns = useMemo(
    () => [
      {
        title: "Description",
        dataIndex: "narration",
        key: "narration"
      },

      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (record: any) => <>{formatAsCurrency(record)}</>
      },
      {
        title: "Due date",
        dataIndex: "date",
        key: "date",
        render: (record: any) => <>{formatDate(record)}</>
      },
      {
        title: "Channel",
        dataIndex: "type",
        key: "type",
        render: (record: any) => <>{getTag(record)}</>
      },
      {
        title: "Status",
        dataIndex: "type",
        key: "type",
        render: (record: any) => <>{getTag(record)}</>
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: `${new Date().getTime()}`,
        render: (record: any, data: any) => {
          return <Button className="kyc-btn">Send reminder</Button>;
        }
      }
    ],
    []
  );

  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"]
    }),
    []
  );

  if (queryResponse.error) {
    showError(queryResponse.error);
  }

  const downloadAsPDF = () => {
    const doc = new jsPDF();
    const body: any[] = [];

    (queryResponse.data?.statement || []).forEach((element: any) => {
      body.push([
        numberWithCommas(element.amount || "0"),
        numberWithCommas(element.balance || "0"),
        element.narration,
        element.type,
        formatDate(element.date)
      ]);
    });

    autoTable(doc, {
      head: [["Amount", "Balance", "Naration", "Type", "Date"]],
      body
    });

    doc.save("statment.pdf");
  };
  return (
    <div className="mt-3">
      {queryResponse.data?.periculum && (
        <Row className="mb-3" justify="end">
          <Col>
            <a
              href={`https://kezaa.insights-periculum.com/statement/${queryResponse.data.periculum?.key}`}
              target="__blank"
            >
              <Button>View on Periculum</Button>
            </a>
          </Col>
        </Row>
      )}
      <div className="row  mb-1">
        <Row>
          <Button onClick={downloadAsPDF}>Download as PDF</Button>
        </Row>
      </div>

      <Col className="card-title mb-1">
        <p>Payment history</p>
      </Col>

      <Table
        loading={queryResponse.isLoading}
        locale={{ emptyText: <Empty /> }}
        pagination={pagination}
        columns={columns}
        dataSource={queryResponse.data?.statement || []}
        scroll={{ x: 750 }}
      />
    </div>
  );
};

export default PaymentHistory;
