import axios from "axios";
import { showError } from "../helpers";
import { getUserToken } from "../utilities/storage";
import LogOut from "../utilities/LogOut";
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3050/api/v1"
});

api.interceptors.response.use(
  value => Promise.resolve(value),
  error => {
    showError(error);
    if (error.repons.status === 403) {
      LogOut();
    }
    if (error.repons.status === 401) {
      LogOut();
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((req: any) => {
  const token = getUserToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

export const getBvnDetails = ({ page = 1, size = 10 }) => {
  return api.get(`admin/identities/bvns?page=${page}&size=${size}`);
};

export const deleteBvnDetails = (bvn_id: string) => {
  return api.delete(`/admin/identities/${bvn_id}/delete`);
};

export const searchBvn = async (bvn: string) => {
  return api.post(`/admin/identities/bvns`, { bvn });
};

export const fetchAccountFromBvn = async (bvn: string) => {
  return api.post(`/admin/identity-verification/bvn-radar`, {bvn});
};

