import { Col, Row, Spin, Typography } from "antd";
import { FC, useState } from "react";
import {Button,Card, } 
from "antd";
import filter from "../../../assets/filter.svg"
const Wallet : FC<{
    user: any;
  }> = ({ user }) => {
  return (
<div className="container">
        <div className="row">
            <div className="col-md-12">
                <Card
                className="card-bord"
                title={
                    <Row className="" justify={"space-between"}>
                        <Typography.Title level={5}>Wallet</Typography.Title>
                    </Row>
                }
                >
                    <hr/>
                    <div className=" myprofile grid">
                        <div className="profile-left flex">
                            <div className="left-card grid">
                                <span> Total available balance</span>

                                <div className="wallet-value flex">
                                    <h2>{0}</h2>
                                </div>

                                <Button className="wallet-btn">
                                    Withdraw
                                </Button>
                            </div>

                            <div className="left-des">
                                <div className="des-cont">
                                    <p>Number of approved customers:</p>
                                    <span></span>
                                </div>
                                <div className="des-cont">
                                    <p>Total customer earnings:</p>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className="profile-right">
                        <Row className="" justify={"space-between"}>
                            <Typography.Title level={5}>
                                Recent transactions
                            </Typography.Title>

                            <Button className="flex filter-btn">
                                Filter by
                                <img
                                src={filter}
                                style={{ width: "16px", marginRight: "5px" }}
                                alt="filter"
                                className="pl-3"
                                />
                            </Button>
                        </Row>
                        <br/>
                        <br/>


                        <div className="wallet-info">
                            <Row className="trans-item" justify={"space-between"}>
                                <Col>
                                    <p className="trans-title"></p>
                                    <span className="trans-date">   <span></span></span>
                                </Col>

                                <Col>

                                </Col>
                            </Row>
                        </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </div>
  )
}

export default Wallet