export const channels = [
  "Twitter",
  "Facebook",
  "Instagram",
  "TikTok",
  "LinkedIn",
  "Phone Vendor",
  "Employer",
  "Friend or Family",
  "Google search",
  "Other"
];
