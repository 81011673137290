import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row } from "antd";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleProduct, updateProduct } from "../../api/product";
import { showError, showMessage } from "../../helpers";
import { formatAsCurrency } from "../../utilities/number-formatter";

const ProductDetailOld = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data } = useQuery({
    queryKey: ["product", params.id],
    queryFn: async () => {
      const response = await getSingleProduct(params.id as string);
      return response.data.data;
    }
  });
  const [updates, setUpdates] = useState({}) as any;

  const resetState = (key: any) => {
    setUpdates((old: any) => ({ ...old, [key]: null }));
  };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateProduct,
    mutationKey: ["product-update", params.id],
    onError: error => {
      showError(error);
    },
    onSuccess: () => {
      setUpdates({});
      showMessage(
        "operation successful",
        "Prices updated successfully",
        "success"
      );

      queryClient.invalidateQueries({ queryKey: ["product", params.id] });
    }
  });
  const handleUpdate = () => {
    const pricesToUpdate: any = {};
    Object.keys(updates).forEach(key => {
      if (updates[key]) pricesToUpdate[key] = updates[key];
    });

    pricesToUpdate.id = params.id;

    mutation.mutate(pricesToUpdate);
  };
  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-md-12">
          <Row>
            <Col span={24}>
              <span className="cursor-pointer" onClick={() => navigate(-1)}>
                <ArrowLeftOutlined /> Back to Products
              </span>
            </Col>
          </Row>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-12">
          <Card title="Product Info">
            <table className="w-100 table table-striped">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{data?.name}</td>
                </tr>
                <tr>
                  <td>Colors</td>
                  <td>{data?.colors?.join(",")}</td>
                </tr>
              </tbody>
            </table>
          </Card>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-12">
          <Card title="Pricing and Storage">
            <table className="w-100 table table-striped">
              <thead>
                <th>RAM</th>
                <th>ROM</th>
                <th>Price New</th>
                <th>Price Old</th>
              </thead>
              <tbody>
                {data?.pricing.map((item: any, i: number) => (
                  <tr key={i}>
                    <td>{item?.storageRAM}</td>
                    <td>{item?.storageROM}</td>
                    <td>
                      {!updates[`${item?.storageRAM}_${item?.storageROM}`] ? (
                        formatAsCurrency(item?.priceNew)
                      ) : (
                        <Input
                          value={
                            updates[`${item?.storageRAM}_${item?.storageROM}`]
                              .priceNew
                          }
                          placeholder="Enter Amount"
                          onChange={e =>
                            setUpdates((old: any) => ({
                              ...old,
                              [`${item.storageRAM}_${item.storageROM}`]: {
                                ...old[`${item.storageRAM}_${item.storageROM}`],
                                priceNew: e.target.value
                              }
                            }))
                          }
                        />
                      )}
                    </td>
                    <td>
                      {!updates[`${item?.storageRAM}_${item?.storageROM}`] ? (
                        formatAsCurrency(item?.priceOld)
                      ) : (
                        <Input
                          value={
                            updates[`${item?.storageRAM}_${item?.storageROM}`]
                              .priceOld
                          }
                          placeholder="Enter Amount"
                          onChange={e =>
                            setUpdates((old: any) => ({
                              ...old,
                              [`${item.storageRAM}_${item.storageROM}`]: {
                                ...old[`${item.storageRAM}_${item.storageROM}`],
                                priceOld: e.target.value
                              }
                            }))
                          }
                        />
                      )}
                    </td>
                    <td>
                      {!updates[`${item?.storageRAM}_${item?.storageROM}`] ? (
                        <Button
                          onClick={() =>
                            setUpdates((old: any) => ({
                              ...old,
                              [`${item.storageRAM}_${item.storageROM}`]: {
                                priceNew: item?.priceNew,
                                priceOld: item?.priceOld
                              }
                            }))
                          }
                        >
                          Edit
                        </Button>
                      ) : null}
                      {updates[`${item?.storageRAM}_${item?.storageROM}`] && (
                        <Button onClick={handleUpdate}>Update</Button>
                      )}
                      {updates[`${item?.storageRAM}_${item?.storageROM}`] && (
                        <Button
                          onClick={() =>
                            resetState(`${item.storageRAM}_${item.storageROM}`)
                          }
                        >
                          Cancel
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailOld;
