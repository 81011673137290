import axios from "axios";
import { showError } from "../helpers";
import { getUserToken } from "../utilities/storage";
import LogOut from "../utilities/LogOut";
import { IUserSearch } from "@app-interfaces";
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3050/api/v1"
});

api.interceptors.request.use((req: any) => {
  const token = getUserToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

api.interceptors.response.use(
  value => Promise.resolve(value),
  error => {
    showError(error);
    if (error.response.status === 403) {
      LogOut();
    }
    if (error.response.status === 401) {
      LogOut();
    }
    return Promise.reject(error);
  }
);

export const getAllUsers = ({
  page = 1,
  size = 10,
  keyword = "",
  channel = "",
  vendor = "",
  status = undefined
}) => {
  return api.get(
    `/admin/users?page=${page}&size=${size}&keyword=${keyword}${
      status !== undefined ? `&status=${status}` : ""
    }&channel=${channel}${vendor ? `&vendor=${vendor}` : ""}`
  );
};

export const getSingleUser = (userId: string) => {
  return api.get(`/admin/user/${userId}`);
};

export const getUserApplications = (userId: string) => {
  return api.get(`/admin/user/${userId}/applications`);
};

export const getUserActivities = (userId: string) => {
  return api.get(`/admin/user/${userId}/activities`);
};

export const approveUser = (userId: string) => {
  return api.put(`/admin/user/${userId}/approve`);
};

export const performKarmaCheck = (applicationId: string) => {
  return api.get(`/admin/user/${applicationId}/check-karma`);
};

export const getBankStatment = (userId: string) => {
  return api.get(`/admin/user/${userId}/bank-statement`);
};

export const getUserFileUploads = (userId: string) => {
  return api.get(`/admin/user/${userId}/uploads`);
};

export const sendReminderApi = (userId: string, title: string) => {
  return api.post(`/admin/user/${userId}/send-reminder`, { title });
};

export const redoKycApi = (userId: string, title: string) => {
  return api.post(`/admin/user/${userId}/kyc-redo`, { title });
};

export const searchUserApi = (payload: IUserSearch) => {
  Object.keys(payload).forEach(item => {
    if (typeof payload[item as keyof IUserSearch] === "undefined") {
      delete payload[item as keyof IUserSearch];
    }
  });
  return api.post(`/admin/user/search`, payload );
};

export const searchUserApiVen = (payload: IUserSearch) => {
  Object.keys(payload).forEach(item => {
    if (typeof payload[item as keyof IUserSearch] === "undefined") {
      delete payload[item as keyof IUserSearch];
    }
  });
  return api.post(`/admin/user/search`, payload );
};
export const searchUserApiKez = (payload: IUserSearch) => {
  Object.keys(payload).forEach(item => {
    if (typeof payload[item as keyof IUserSearch] === "undefined") {
      delete payload[item as keyof IUserSearch];
    }
  });
  return api.post(`/admin/user/search`, payload );
};
export const exportUserDateRange = (startDate: string, endDate: string, userType: string) => {
  return api.get(
    `admin/users/download?startDate=${startDate}&endDate=${endDate}&userType=${userType}`,
    { responseType: "blob" }
  );
};

export const exportVendorUserDateRange = (
  vendorId: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `admin/vendors/${vendorId}/download-users?startDate=${startDate}&endDate=${endDate}`,
    { responseType: "blob" }
  );
};
