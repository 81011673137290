import { ThemeConfig, extendTheme, ChakraProvider } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export default function ThemeProvider({ children }: { children: ReactNode }) {
  const config: ThemeConfig = {
    useSystemColorMode: false,
    cssVarPrefix: "keza"
  };
  const theme = extendTheme({
    config
  });
  return <ChakraProvider resetCSS theme={theme}>{children}</ChakraProvider>;
}
