import { Button, Empty, Table,Row,Col , Card} from "antd";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getUserActivities, sendReminderApi,redoKycApi } from "../../../api/user";
import { showError, showMessage } from "../../../helpers";
import { getTag } from "../../../utilities/sanitizer";
import mark from "../../../assets/mark.svg"
import fail from "../../../assets/fail.svg"
const Kyc = () => {
  const params = useParams();
  const { isLoading, error, data } = useQuery({
    queryKey: ["activitiy", params.id],
    queryFn: async () => {
      const response = await getUserActivities(params.id as string);
      return response.data.data;
    },
  });
  const [loading, setLoading] = useState(false);
  const sendReminder = async (title: string) => {
    try {
      if (loading) return;
      setLoading(true);
      await sendReminderApi(params.id as string, title);

      setLoading(false);

      showMessage(
        "Operation successful",
        "Reminder successfully sent",
        "success"
      );
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };
  const redoKyc = async (title: string) => {
    try {
      if (loading) return;
      setLoading(true);
      await redoKycApi(params.id as string, title);

      setLoading(false);

      showMessage(
        "Operation successful",
        "Status updated",
        "success"
      );
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };
  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"],
    }),
    []
  );
  const columns = useMemo(
    () => [
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Status",
        dataIndex: "isCompleted",
        key: "isCompleted",
        render: (record: any) => (
          <>{getTag(record ? "completed" : "pending")}</>
        ),
      },
      {
        title: "Actions",
        dataIndex: "_id",
        width:"45%",
        key: `${new Date().getTime()}`,
        render: (record: any, data: any) => {
          return (
            <Col  className="flex">
              <Row gutter={[8, 0]}>

                <Col>
                    <Button
                    className="kyc-btn"
                    >
                      <img src={mark} alt=""/>
                      Completed
                    </Button>
                </Col>
                <Col>
                <Button
                className="kyc-btn"
                 onClick={() => redoKyc(data.title)}
                    >
                      Fail
                      <img src={fail} alt=""/>
                    </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => sendReminder(data.title)}
                    disabled={data.isCompleted}
                  >
                    Reminder
                  </Button>
                </Col>
              </Row>
              <Row className="reset-btn-cont">
                <Col >     
                    <span
                    onClick={() => redoKyc(data.title)}
                    className="reset-btn"
                    >
                      Reset
                    </span>
                </Col>
              </Row>
            </Col>
          );
        },
      },
    ],
    []
  );
  if (error) return <Empty />;

  return (
    <div className="container">
    <div className="row">
      <div className="col-md-12">
          <Card
              title={
                <Col className="card-title">
                  <p>Verification checks</p>
                </Col>
                }
          >
            <Table
              className="table"
              columns={columns}
              loading={isLoading}
              locale={{ emptyText: <Empty /> }}
              dataSource={data || []}
              pagination={pagination}
            />
          </Card>
      </div>
    </div>
  </div>
  )
}

export default Kyc