import { Row, Card, Spin } from "antd";
import {
  paginationsData,
  paginationssData
} from "../../types/pagination.inteface";
import { FC, useEffect, useState } from "react";
import { getProduct } from "../../api/business";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import type { PaginationProps } from "antd";
import { Pagination } from "antd";
import ShelfPhoneCard from "./Devicecomponents/ShelfPhoneCard";

const Shelf: FC<{ business: any }> = ({ business }) => {
  const [product, setProduct] = useState<any>([]);

  const params = useParams();

  const [paginations, setPaginations] = useState<paginationsData>({
    page: 1,
    size: 10,
    id: params.id
  });

  const [pagination, setPagination] = useState<paginationssData>({
    totalCount: 10,
    numberOfPages: 1
  });

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPaginations({
      page: current,
      size: pageSize,
      id: params.id
    });
  };
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setPaginations({
      page: page,
      size: pageSize,
      id: params.id
    });
  };

  const productResponse = useMutation({
    mutationFn: async () => {
      const response = await getProduct(paginations as paginationsData);
      setProduct(response.data.data.list);
      setPagination(response.data.data);
      return response.data.data;
    }
  });

  useEffect(() => {    
    productResponse.mutate();
    // eslint-disable-next-line
  }, [paginations]);

  return (
    <div className="container ">
      <Card
        className="table-cont"
        title={
          <Row className="mb-3">
            <span className="table-title">
              Showing {paginations.size || 0} of {pagination.totalCount || 0}{" "}
              results for {business.name}{" "}
            </span>
          </Row>
        }
      >
        {productResponse.isLoading ? (
          <div
            className="flex"
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: "2rem 0"
            }}
          >
            <Spin />
          </div>
        ) : (
          <div className="phone-card">
            <div className="phone-card-cont grid">
              {product.map((device: any) => {
                return (
                  <ShelfPhoneCard
                    key={device._id}
                    phone={device}
                    bussiness={business}
                    reload={productResponse}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div
          className="flex"
          style={{
            justifyContent: "center",
            alignItems: "center",
            margin: "2rem 0"
          }}
        >
          <Pagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={1}
            total={pagination.totalCount}
            onChange={onChange}
          />
        </div>
      </Card>
    </div>
  );
};

export default Shelf;
