import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Row, Spin, Tabs } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  approveDeviceApplication,
  getSingleApplication,
  rejectApplication,
} from "../../../api/deviceapplication";
import { showError, showMessage } from "../../../helpers";
import { getTag } from "../../../utilities/sanitizer";
import UserProfile from "../../UserDetail/UserProfile/UserProfile";
import PaymentTrack from "../../ApplicationDetail/PaymentTrack/PaymentTrack";
import Underwriting from "../../ApplicationDetail/UnderWriting/Underwriting";
import type { MenuProps } from "antd";
import { Dropdown, Tooltip } from "antd";
import React, { useState } from "react";
import Actions from "../../ApplicationDetail/Actions/Actions";
import OrderDetail from "./OrderDetail";

const DeviceApplicationDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();

  const applicationDetailResponse = useQuery({
    queryKey: ["device_application", params.id],
    queryFn: async () => {
      const response = await getSingleApplication(params.id as string);

      return response.data;
    },
  });
  const mutation = useMutation({
    mutationFn: approveDeviceApplication,
    onSuccess: () => {
      // Invalidate and refetch
      showMessage(
        "Operation successful",
        "Application approved successfully",
        "success"
      );
      queryClient.invalidateQueries({ queryKey: ["application", params.id] });
      queryClient.invalidateQueries({ queryKey: ["applications"] });
    },
    onError: (error, variables, context) => {
      showError(error);
    },
  });

  const items: MenuProps["items"] = [
    {
      label: "Poor Credit History",
      key: "REJECTION_POOR_CREDIT_HISTORY",
    },
    {
      label: "Identification Failed",
      key: "REJECTION_IDENTIFICATION_FAILED",
    },
    {
      label: "Suspected Irregularities",
      key: "REJECTION_SUSPECTED_IRREGULARITIES",
    },
    {
      label: "Failed Employment Verification",
      key: "REJECTION_EMPLOYMENT_VERIFICATION_FAILED",
    },
    {
      label: "Failed Endoser Verification",
      key: "REJECTION_ENDOSER_REJECTED",
    },
    {
      label: "Failed Social Media Verification",
      key: "REJECTION_SOCIAL_MEDIA_VERIFCATION_FAILED",
    },
    {
      label: "Application location outside coverage",
      key: "REJECTION_LOCATION_OUTSIDE",
    },
  ];

  const [loading, setLoading] = useState(false);

  const handleMenuClick = async (payload: any) => {
    try {
      if (loading) return;
      setLoading(true);
      await rejectApplication(params.id as string, payload.key);

      queryClient.invalidateQueries({ queryKey: ["application", params.id] });
      setLoading(false);

      showMessage(
        "Operation successful",
        "Application Rejected successfully",
        "success"
      );
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Row>
            <Col span={24}>
              <span className="cursor-pointer" onClick={() => navigate(-1)}>
                <ArrowLeftOutlined /> Back to Applications
              </span>
            </Col>
          </Row>

          <br />
          <Row>
            <Col span={24}>
              <Card
                title={
                  <>
                    <>
                      <Row gutter={[32, 0]}>
                        <Col>
                          Application ID: {applicationDetailResponse?.data?._id}
                        </Col>
                        <Col>
                          Status:{" "}
                          {getTag(
                            applicationDetailResponse?.data?.status ===
                              "DECLINED"
                              ? "rejected"
                              : applicationDetailResponse?.data?.isApproved
                              ? "Approved"
                              : "Pending"
                          )}
                        </Col>
                      </Row>
                    </>
                  </>
                }
                extra={
                  <Row gutter={[20, 0]}>
                    <Col>
                      <Button
                        onClick={() => mutation.mutate(params.id as string)}
                        className="keza-btn-primary"
                        disabled={
                          applicationDetailResponse?.data?.status === "DECLINED"
                        }
                      >
                        Approve
                      </Button>
                    </Col>
                    <Col>
                      <Dropdown.Button
                        trigger={["click"]}
                        menu={menuProps}
                        buttonsRender={([leftButton, rightButton]) => [
                          <Tooltip title="Reject Application" key="leftButton">
                            {leftButton}
                          </Tooltip>,
                          React.cloneElement(
                            rightButton as React.ReactElement<any, string>,
                            { loading }
                          ),
                        ]}
                      >
                        Reject
                      </Dropdown.Button>
                    </Col>
                  </Row>
                }
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between">
                      <Tabs>
                        <Tabs.TabPane tab="Applicant" key="item-1">
                          {applicationDetailResponse.isLoading ? (
                            <Spin />
                          ) : applicationDetailResponse.error ? (
                            <Empty />
                          ) : applicationDetailResponse.data?.owner ? (
                            <UserProfile
                              user={applicationDetailResponse.data?.owner || {}}
                              applicationId={
                                applicationDetailResponse.data?.id ||
                                applicationDetailResponse.data?._id
                              }
                              application={
                                applicationDetailResponse?.data?.referredBy ||
                                ""
                              }
                            />
                          ) : (
                            <Empty />
                          )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Order Details" key="item-2">
                          {applicationDetailResponse.isLoading ? (
                            <Spin />
                          ) : applicationDetailResponse.error ? (
                            <Empty />
                          ) : applicationDetailResponse.data?.device ? (
                            <OrderDetail
                              product={{
                                ...applicationDetailResponse.data?.device,
                                percentageMultiplier:
                                  applicationDetailResponse.data
                                    ?.percentageMultiplier,
                                duration:
                                  applicationDetailResponse.data.meta.duration,
                              }}
                            />
                          ) : (
                            <Empty />
                          )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Payment History" key="item-3">
                          <PaymentTrack />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Underwriting" key="item-4">
                          <>
                            <br />
                            <Underwriting />
                          </>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Actions" key="item-5">
                          <Actions
                            applicationId={applicationDetailResponse.data?._id}
                            isDeviceApplication
                          />
                        </Tabs.TabPane>
                      </Tabs>
                      {applicationDetailResponse.data?.owner && (
                        <Link
                          to={`/dashboard/users/${applicationDetailResponse.data?.owner._id}`}
                        >
                          <Button danger>View User</Button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DeviceApplicationDetails;
