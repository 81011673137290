import { Col, Pagination, PaginationProps, Row, Tabs, Typography } from "antd";
import { DeviceSection } from "../../enums/users";
import { useEffect, useState } from "react";
import AddDevice from "./AddDevice";
import Shelf from "./Shelf";
import { useQuery } from "react-query";
import { getBusinessById } from "../../api/business";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Input } from "@chakra-ui/react";
//import { getAllProduct } from "../../api/business";

import debounce from "lodash.debounce";
import {
  paginationsData,
  paginationssData
} from "../../types/pagination.inteface";
import { useGetAllProducts } from "../../api/mutations/business";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Devices = () => {
  const params = useParams();
  const [filteredDevices, setFilteredDevices] = useState<any[]>([]);
  const [section, setSection] = useState(DeviceSection.ALL_DEVICES);
  const [business, setBusiness] = useState<any>([]);
  const [search, setSearch] = useState();
  const { getAllProduct, result } = useGetAllProducts();

  const [paginations, setPaginations] = useState<paginationsData>({
    page: 1,
    size: 12,
    id: params.id
  });
  const [pagination, setPagination] = useState<paginationssData>({
    totalCount: 12,
    numberOfPages: 1
  });

  const navigate = useNavigate();

  const sectionChange = (key: any) => {
    if (key === "item-2") {
      setSection(DeviceSection.BUSINESS_SHELF);
    } else {
      setSection(DeviceSection.ALL_DEVICES);
    }
  };

  // eslint-disable-next-line
  const bussiness = useQuery({
    queryKey: ["business"],
    queryFn: async () => {
      const response = await getBusinessById(params.id);
      setBusiness(response.data.data);
    }
  });

  useEffect(() => {
    const debouncedFilterPhone = debounce(async () => {
      try {
        const response = await getAllProduct({ ...paginations, search });
        setFilteredDevices(response.data.data.list);
        setPagination(response.data.data);
      } catch (error) {}
    }, 500);

    debouncedFilterPhone();
    return () => {
      debouncedFilterPhone.cancel();
    };
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    const fetchAllPhones = async () => {
      const response = await getAllProduct({ ...paginations, search });
      setFilteredDevices(response.data.data.list);
      setPagination(response.data.data);
    };

    fetchAllPhones();
    // eslint-disable-next-line
  }, [paginations.page]);


  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setPaginations({
      page: page,
      size: pageSize,
      id: params.id
    });
  };
  return (
    <div className="container">
      <div className="row">

        <Row className="mb-10" justify={"space-between"}>
            <Typography.Title level={4} className="page-title">
                Add device to business
            </Typography.Title>
        </Row> 
        <Row gutter={8} justify={"space-between"} align={"middle"}>
                <Col>
                  <span className="cursor-pointer mb-10" onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined /> Back to Business
                  </span>
                </Col>
        </Row>
        <br />
        <br />

        <div className="col-md-12">
          <Box maxW={"50%"} my="4">
            <Input
              onChange={(e: any) => setSearch(e?.target?.value)}
              placeholder="Search for phones"
            />
          </Box>
          <Row>
            <Col span={24}>
              <Tabs onChange={key => sectionChange(key)}>
                <Tabs.TabPane tab="Add Device" key="item-1">
                  {section === DeviceSection.ALL_DEVICES && (
                    <AddDevice
                      loading={result.isLoading}
                      filteredDevice={filteredDevices}
                      business={business}
                    />
                  )}
                  {section === DeviceSection.BUSINESS_SHELF && (
                    <Shelf business={business} />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Shelf" key="item-2">
                  {section === DeviceSection.ALL_DEVICES && (
                    <AddDevice
                      loading={result.isLoading}
                      filteredDevice={filteredDevices}
                      business={business}
                    />
                  )}
                  {section === DeviceSection.BUSINESS_SHELF && (
                    <Shelf business={business} />
                  )}
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
        <Pagination
          showSizeChanger
          defaultPageSize={12}
          onChange={onChange}
          defaultCurrent={1}
          total={pagination.totalCount}
        />
      </div>
    </div>
  );
};

export default Devices;
