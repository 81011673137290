// fileUtils.ts

import { api } from "../../../api/productAdd";
import { showMessage } from "../../../helpers";
import { Product } from "../../../types/product";

// import { api } from "../../../api/productAdd";
// import { showMessage } from "../../../helpers";

export async function handleFileChange(
  file: File,
  product: Product,
  setProduct: (data: any) => void,
  setLoading: (value: boolean) => void,
  setUploadProgress: (value: number) => void
) {
  setLoading(true);
  const formData = new FormData();  
  formData.append("image", file);
  try {
    const res = await api.post("/upload/devices/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: (progressEvent: any) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setUploadProgress(progress);
      }
    });
    setProduct({
      ...product,
      image: [...product.image, res.data.data.url]
    });
    showMessage("Operation successful");
    setLoading(false);
    setUploadProgress(0);
  } catch (error) {
    setLoading(false);
  }
}
