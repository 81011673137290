export const formatAsCurrency = (number: any) => {
  return Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(number);
};

export function numberWithCommas(value: any) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
