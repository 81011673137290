import { useMutation } from "react-query";
import { showError, showMessage } from "../../helpers";
import {
  getAccountBalance,
  getMandateStatusVfd,
  resetOnboardKey,
  reviewReciept,
  sendDirectDebitVfd,
  sendReciept,
  updateDeviceImeis
} from "../application";

export const useResetOnboardKey = () => {
  const { mutateAsync, ...result } = useMutation(resetOnboardKey);
  return {
    resetOnboardKey: mutateAsync,
    result
  };
};

export const useSendDirectDebit = () => {
  const { mutateAsync, ...sendDirectDebitVfdResult } =
    useMutation(sendDirectDebitVfd);
  return {
    sendDirectDebitVfd: mutateAsync,
    sendDirectDebitVfdResult
  };
};

export const useGetMandateStatusVfd = () => {
  const { mutateAsync, ...getMandateStatusResult } =
    useMutation(getMandateStatusVfd);
  return {
    getMandateStatus: mutateAsync,
    getMandateStatusResult
  };
};

export const useGetAccountbalance = () => {
  const { mutateAsync, ...getAccountBalanceResult } =
    useMutation(getAccountBalance);
  return {
    getAccountBalance: mutateAsync,
    getAccountBalanceResult
  };
};

export const useUpdateDeviceImeis = () => {
  const { mutateAsync, ...updateDeviceImeisResult } =
    useMutation(updateDeviceImeis);
  return {
    updateDeviceImeis: mutateAsync,
    updateDeviceImeisResult
  };
};

export const useReviewReciept: any = (options?: any) => {
  return useMutation({
    mutationFn: reviewReciept,
    onError: err => {
      showError(err);
    },
    onSuccess: data => {
      showMessage("Operation successful", "Reciept sent to admin", "success");
    },
    ...options
  });
};
export const useSendReciept: any = (options?: any) => {
  return useMutation({
    mutationFn: sendReciept,
    onError: err => {
      showError(err);
    },
    onSuccess: data => {
      showMessage("Operation successful", "Reciept sent to USER", "success");
    },
    ...options
  });
};
