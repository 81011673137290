import { Button, Card, Col, Input, Row, Table, Tag } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getAllProducts, updateProductStatusById } from "../../api/product";
import CounterBox from "../Dashboard/CounterBox/CounterBox";
import smallApplicationIcon from "../../assets/smallApplicationIcon.svg";
import folderIcon from "../../assets/folderIcon.svg";
import folderIcon2 from "../../assets/folderIcon2.svg";
import folderIcon3 from "../../assets/folderIcon3.svg";
import { getTag } from "../../utilities/sanitizer";
import Add from "../../assets/Add.svg";
import { showMessage } from "../../helpers";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Select } from "antd";
import { getDisbursments } from "../../api/application";
import { message, Popconfirm } from "antd";
import { formatDate } from "../../utilities/date-formatter";

const Products = () => {
  const cancelTrue = () => {
    message.error("No");
  };

  const cancelFalse = () => {
    message.error("No");
  };

  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const response = await getDisbursments();
      return response.data.data.data;
    },
    queryKey: ["disbursements"]
  });
  const productsResponse = useQuery({
    queryKey: ["products"],
    queryFn: async () => {
      const response = await getAllProducts();
      return response.data.data;
    }
  });
  const columns = useMemo(
    () => [
      {
        title: "Product name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "RAM/ROM",
        dataIndex: "capacity",
        key: "capacity"
      },
      {
        title: "Type",
        dataIndex: "condition",
        key: "condition",
        render: (record: any) => <>{getTag(record)}</>
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (record: string[]) => <>{record}</>
      },
      {
        title: "Date added",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record: any) => <>{formatDate(record)}</>
      },
      {
        title: "Status",
        dataIndex: "isActive",
        key: "isActive",
        render: (record: any[]) => (
          <Tag color={record ? "success" : "error"}>
            {record ? "ONLINE" : "OFFLINE"}
          </Tag>
        )
      },
      {
        title: "Actions",
        dataIndex: "_id",
        width: "25%",
        render: (record: any, data: any) => {
          return (
            <Row gutter={[8, 0]}>
              <Col>
                <Link to={`/dashboard/product/${record}/edit`}>
                  <Button>Edit</Button>
                </Link>
              </Col>
              <Col>
                {data.isActive ? (
                  <Popconfirm
                    title="Take product offline"
                    onConfirm={() => {
                      updateProductStatus(data._id, false);
                      message.success("yes");
                    }}
                    onCancel={cancelFalse}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button>
                      <CaretDownOutlined color="red" />
                    </Button>
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Take product online"
                    onConfirm={() => {
                      updateProductStatus(data._id, true);
                      message.success("yes");
                    }}
                    onCancel={cancelTrue}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button>
                      <CaretUpOutlined color="green" />
                    </Button>
                  </Popconfirm>
                )}
              </Col>
            </Row>
          );
        }
      }
    ],
    []
  );

  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts(productsResponse.data);
  }, [productsResponse.data]);

  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"]
    }),
    []
  );

  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const updateProductStatus = async (id: string, status: boolean) => {
    try {
      if (loading) return;
      setLoading(true);

      await updateProductStatusById(id, { status });

      queryClient.invalidateQueries({ queryKey: ["products"] });

      showMessage(
        "Operation successful",
        "Product status updated successfully",
        "success"
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.trim().toLowerCase();

    setSearchValue(searchValue);
  };

  const handleFilter = useCallback(() => {
    if (searchValue === "") return setProducts(productsResponse.data);

    const filtered = productsResponse.data.filter((item: any) => {
      return (
        item.name?.trim().toLowerCase().includes(searchValue) ||
        item._id.trim().toLowerCase().includes(searchValue)
      );
    });

    setProducts(filtered);
  }, [searchValue, productsResponse.data]);

  useEffect(() => {
    handleFilter();
  }, [searchValue]);

  const [sortValue, setSortValue] = useState("");

  const handleSelect = (value: string) => {
    const sortValue = value;

    setSortValue(sortValue);
  };
  const handleSort = useCallback(() => {
    if (sortValue === "") {
      return setProducts(productsResponse.data);
    }

    const sorted = productsResponse.data.filter((item: any) => {
      return item.isActive === sortValue;
    });

    setProducts(sorted);
  }, [sortValue, productsResponse.data]);

  useEffect(() => {
    handleSort();
  }, [handleSort]);

  return (
    <div className="container">
      <Row>
        <Col span={24}>
          <Col>
            <Row gutter={8} justify={"space-between"} align={"middle"}>
              <div>
                <h2 className="page-title">Products</h2>
              </div>

              <div>
                <Select
                  style={{ width: 100 }}
                  onChange={handleChange}
                  options={[
                    { value: "thisMonth", label: "This month" },
                    { value: "", label: "" },
                    { value: "", label: "" }
                  ]}
                />
              </div>
            </Row>
          </Col>

          <Row gutter={[16, 16]}>
            <CounterBox
              loading={isLoading}
              IconPath={folderIcon}
              title="Total Products"
              value={data?.applicationCount?.total}
            />
            <CounterBox
              loading={isLoading}
              IconPath={smallApplicationIcon}
              title="In stock Products"
              value={data?.applicationCount?.approved}
            />
            <CounterBox
              loading={isLoading}
              IconPath={folderIcon2}
              title="Out of stock Products"
              value={data?.applicationCount?.pending}
            />
            <CounterBox
              loading={isLoading}
              IconPath={folderIcon3}
              title="Removed Products"
              value={data?.applicationCount?.declined}
            />
          </Row>
        </Col>
      </Row>
      <br />
      <br />
      <Link to="/dashboard/products/addproduct" className="p-btn flex">
        <p> Add a product</p>
      </Link>
      <div className="row">
        <div className="col-md-12">
          <Card
            title={
              <Row className="mb-3">
                <span className="table-title">Showing 0 of 0 results for </span>
              </Row>
            }
            extra={
              <div className="flex">
                <Select
                  defaultValue="Sort"
                  style={{ width: 120 }}
                  onChange={handleSelect}
                  options={[
                    { value: "", label: "ALL" },
                    { value: true, label: "ONLINE" },
                    { value: false, label: "OFFLINE" }
                  ]}
                />
                <Input
                  prefix={<SearchOutlined />}
                  placeholder="search by ID, product's name"
                  onChange={handleChange}
                />
              </div>
            }
          >
            <Table
              loading={productsResponse.isLoading}
              dataSource={products || []}
              columns={columns}
              pagination={pagination}
              scroll={{ x: 1000 }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Products;
