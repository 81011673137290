import axios from "axios";
import { showError } from "../helpers";
import { getUserToken } from "../utilities/storage";
import LogOut from '../utilities/LogOut'
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3050/api/v1",
});

api.interceptors.response.use(
  (value) => Promise.resolve(value),
  (error) => {
    showError(error);
    if(error.repons.status === 403) {
      LogOut()
    }
    if(error.repons.status === 401) {
      LogOut()
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((req: any) => {
  const token = getUserToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

export const getAllProducts = () => {
  return api.get(`/admin/products`);
};

export const getSingleProduct = (id: string) => {
  return api.get(`/product/${id}`);
};

export const updateProduct = (payload: any) => {
  const { id, ...rest } = payload;
  return api.put(`/admin/product/${id}/update`, rest);
};

export const updateProductByID = (id: string, payload: any) => {
  return api.post(`/admin/product/update/${id}`, payload);
};

export const updateProductStatusById = (
  id: string,
  payload: { status: boolean }
) => {
  return api.put(`/admin/product/status/update/${id}`, payload);
};

export const uploadNovo = (payload: {applicationId:string, deviceId:string}) => {
  return api.put(`admin/applications/update-nuovopay`, payload)
};
