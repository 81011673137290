import { atom } from "recoil";
import implementPersist from "../utilities/implement-persist";

export const UserTokenState = atom({
  key: "UserTokenState",
  default: "",
  effects_UNSTABLE: implementPersist("UserTokenState"),
});

export const UserPofileState = atom({
  key: "UserPofileState",
  default: "",
  effects_UNSTABLE: implementPersist("UserPofileState"),
});


export const UserPathState = atom({
  key: "UserPathState",
  default: "",
  effects_UNSTABLE: implementPersist("UserPathState"),
});