import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Center,
  Box
} from "@chakra-ui/react";
import Select from "react-select";
import { useResetOnboardKey } from "../../../api/mutations/appications";
import { useState } from "react";
import { showError, showMessage } from "../../../helpers";

export default function ResetModal({
  isOpen,
  onClose,
  data,
  applicationId,
  category
}: {
  isOpen: any;
  onClose: any;
  data: any;
  applicationId?: string;
  category?: string;
}) {
  // console.log(data);
  const options = Object.keys(data).map(item => ({ label: item, value: item }));
  const { resetOnboardKey, result } = useResetOnboardKey();
  const [resetKey, setResetKey] = useState("");
  const upload = async () => {
    if (!resetKey) return;
    try {
      console.log({ applicationId, resetKey, category });
      const res = await resetOnboardKey({ applicationId, resetKey, category });
      showMessage("Operation successflu");
      onClose()
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset Key</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Select
              onChange={(e: any) => setResetKey(e?.value)}
              options={options}
              placeholder="Select key..."
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Center>
            <Button
              isLoading={result.isLoading}
              colorScheme="blue"
              mr={3}
              onClick={upload}
            >
              Reset Key
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
