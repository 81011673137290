import { FC, useState } from "react";
import DeviceModal from "./DeviceModal";
import { Col, Modal, Row } from "antd";
const AddPhoneCard: FC<{ device: any; bussiness: any , reload:any; }> = ({
  device,
  bussiness,
  reload,
}) => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="pcard flex">
        <Col>
          <img src={device.image[0]} alt="phone" className="pcard-img" />
        </Col>
        <Col className="pcard-info flex">
          <h6>{device.name}</h6>
          <span>{device.condition}</span>
        </Col>
        <Col>
          <button className="pcard-btn" onClick={showModal}>
            Add to shelf
          </button>
          <>
            <Modal
              open={open}
              title={
                <Row gutter={16}>
                  <Col span={24}>
                    <p>
                      {" "}
                      Add{" "}
                      <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                       <b>{device.name}</b> 
                      </span>{" "}
                      to {bussiness.name}{" "}
                    </p>
                  </Col>
                  <Col span={24}>
                    <h6>Select the device details below</h6>
                  </Col>
                </Row>
              }
              onOk={handleOk}
              onCancel={handleCancel}
              footer={""}
              style={{ maxWidth: 1024 }}
            >
              <DeviceModal setOpen={setOpen} device={device} vendor={bussiness} reload={reload} />
            </Modal>
          </>
        </Col>
      </div>
    </div>
  );
};

export default AddPhoneCard;
