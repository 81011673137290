export const initialProduct = {
    colors: [],
    name: "",
    pricing: [],
    condition: "NEW",
    image: [],
    component: {
      camera: [],
      display: "",
      chip: "",
      batteryName: "",
      isBatteryRemovable: false
    },
    isActive: true,
    type: "PHONE"
  };
 