import { Card, Row, Spin } from "antd";
import { FC } from "react";
import AddPhoneCard from "./Devicecomponents/AddPhoneCard";
import { Center } from "@chakra-ui/react";

const AddDevice: FC<{
  business: any;
  filteredDevice?: any;
  loading?: boolean;
}> = ({ business, filteredDevice, loading }) => {

  if (loading) {
    return (
      <Center>
        <Spin />
      </Center>
    );
  } else {
    return (
      <div className="container ">
        <Card
          className="table-cont"
          title={
            <Row className="mb-3">
              <span className="table-title">
                {/* Showing {paginations.size || 0} of {pagination.totalCount || 0}{" "} */}
                results for devices
              </span>
            </Row>
          }
        >
          <div className="phone-card">
            <div className="phone-card-cont grid">
              {filteredDevice.map((device: any) => {
                return (
                  <AddPhoneCard
                    key={device._id}
                    device={device}
                    bussiness={business}
                    reload={filteredDevice}
                  />
                );
              })}
            </div>
          </div>

          <div
            className="flex"
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: "2rem 0"
            }}
          ></div>
        </Card>
      </div>
    );
  }
};
export default AddDevice;
