import {
  Box,
  BoxProps,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Text
} from "@chakra-ui/react";
import { HTMLProps, useState } from "react";
//   import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
//   import { CgAsterisk } from 'react-icons/cg';

interface CustomInputProps extends HTMLProps<HTMLInputElement> {
  label?: string;
}
export function TextInput({ label, ...inputProps }: CustomInputProps) {
  const { as, ...rest } = inputProps; // Extract the 'as' prop
  const inputComponentProps = rest as InputProps;
  return (
    <Box>
      {label && (
        <FormLabel fontWeight="700" fontSize={{ base: "14px", md: "16px" }}>
          <Text>{label}</Text>
        </FormLabel>
      )}

      <Input borderRadius={'0px'} {...inputComponentProps} border={'1px solid rgba(0,0,0,0.7)'}/>
      {/* {props.errorMessage && props.touched && (
          <Text fontSize={'12px'} color={'red'}>
            {props.errorMessage}
          </Text>
        )} */}
    </Box>
  );
}

// export function PasswordInput(props: TextInputProps) {
//   const [showPassword, setShowPassword] = useState(false);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <Box>
//       <FormLabel
//         display={'flex'}
//         fontWeight="700"
//         fontSize={{ base: '14px', md: '16px' }}
//       >
//         <Text>{props.label}</Text>
//         {/* <CgAsterisk color="#E50917" /> */}
//       </FormLabel>
//       <InputGroup>
//         <InputRightElement
//           onClick={togglePasswordVisibility}
//           cursor={'pointer'}
//           children={
//             showPassword ? (
//               <AiOutlineEyeInvisible color="gray.300" />
//             ) : (
//               <AiOutlineEye color="gray.300" />
//             )
//           }
//         />
//         <Input
//           type={showPassword ? 'text' : 'password'}
//           onChange={props.onChange}
//           placeholder={props.placeHolder}
//           name={props.name}
//           border="1px solid rgba(29, 29, 29, 0.5);"
//           isInvalid={Boolean(props.error && props.touched)}
//           value={props.value}
//           id={props.name}
//         />
//       </InputGroup>
//       {props.errorMessage && props.touched && (
//         <Text fontSize={'12px'} color={'red'}>
//           {props.errorMessage}
//         </Text>
//       )}
//     </Box>
//   );
// }
