import {
  Button,
  Card,
  Col,
  DatePicker,
  Popconfirm,
  Popover,
  Row,
  Table,
  Typography,
  message,
  Tabs,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import folderIcon from "../../assets/folderIcon.svg";
import folderIcon2 from "../../assets/folderIcon2.svg";
import filter from "../../assets/filter.svg";
import CounterBox from "../Dashboard/CounterBox/FilterCounterBox";
import smallApplicationIcon from "../../assets/smallApplicationIcon.svg";
import { useMutation, useQuery} from "react-query";
import { getDashboardAnalytics } from "../../api/dashboard";
import {
  getAllApplications,
  deleteApplications,
  exportApplicationDateRange
} from "../../api/application";
import { formatDateTime, RANGE_PRESETS } from "../../utilities/date-formatter";
import { getTag } from "../../utilities/sanitizer";
import { Link } from "react-router-dom";
import { showError, showMessage } from "../../helpers";
import { IApplicationSearch, paginationData } from "@app-interfaces";
import ApplicationFilter from "./ApplicationFilter/ApplicationFilter";
import moment from "moment";
import { CloudDownloadOutlined } from "@ant-design/icons";
//import { exportUserDateRange } from "../../api/user";
import {Text } from "@chakra-ui/layout";


const Application = () => {
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);
  const { isLoading, data } = useQuery({
    queryKey: ["application_dashboard_analytics"],
    queryFn: async () => {
      const response = await getDashboardAnalytics();

      return response.data.data;
    }
  });

  const [pages, setPages] = useState<any | number>(1);
  const [sizes, setSizes] = useState<any | number>(10);
  const applicationResponse = useQuery({
    queryKey: ["applications"],
    queryFn: async () => {
      const response = await getAllApplications(searchValue);
      setPaginations(response.data.data.pagination);
      isMounted.current = true;
      return response.data.data.content;
    },
    retry: false
  });

  useEffect(() => {
    setSearchValue(state => ({ ...state, page: pages, size: sizes }));
  }, [pages, sizes]);

  const [paginations, setPaginations] = useState<paginationData>(
    {} as paginationData
  );
  const deleteApplication = async (application_id: string) => {
    try {
      if (loading) return;
      setLoading(true);
      await deleteApplications(application_id);

      setLoading(false);

      showMessage("Operation successful", "Application Deleted", "success");
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };
  const confirm = (application_id: any) => {
    deleteApplication(application_id);
  };
  const cancel = () => {
    message.error("Application not deleted");
  };
  const columns = useMemo(
    () => [
      {
        title: "User",
        dataIndex: "owner",
        key: "owner",
        render: (record: any, data: any) => {
          return (
            <div>
              <Typography.Title level={5}>
                {data.owner?.firstName} {data.owner?.lastName}
              </Typography.Title>
              <span>{data.owner?.email}</span>
            </div>
          );
        }
      },
      {
        title: "Device",
        dataIndex: "product",
        key: "device",
        render: (record: any) => {
          return <>{record?.name}</>;
        }
      },

      {
        title: "Details",
        dataIndex: "phoneStorage",
        key: "phoneStorage",
        render: (record: any, data: any) => {
          return (
            <>
              {record},{data.phoneColor}
            </>
          );
        }
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: record => formatDateTime(record)
      },
      {
        title: "Type",
        dataIndex: "Type",
        key: "business",
        render: (record, data) => {
          return (
            <Text fontWeight={"bold"}>
              {data?.business?.length > 0
                ? data?.business[0]?.name
                : data?.vendor?.length > 0
                ? data?.vendor[0]?.username
                : "Keza"}
            </Text>
          );
        }
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: record => getTag(record)
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: "Action",
        render: (record, data) => (
          <Row gutter={[8, 0]}>
            <Col>
              <Link to={`${record}`}>
                <Button>View</Button>
              </Link>
            </Col>
            {data.status !== "DELETED" && (
              <Col>
                  <Popconfirm
                  title={`Delete ${data.owner?.firstName || ""} ${data.owner?.lastName|| ""}'s application`}
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button>
                    Delete
                  </Button>
                </Popconfirm>
              </Col>
            )}
          </Row>
        )
      }
    ],
    []
  );

  const [application, setApplication] = useState([]);
  useEffect(() => {
    setApplication(applicationResponse.data);
  }, [applicationResponse.data]);

  const Data =
    application?.map((data: any) => {
      return {
        firstName: data.owner?.firstName,
        lastName: data.owner?.lastName,
        date: formatDateTime(data?.createdAt),
        brand: data.product?.name,
        condition: data?.phonCondition,
        tenure: data.meta?.duration
      };
    }) || [];

  const dataToConvert = {
    data: Data,
    filename: "Keza_applications.csv",
    delimiter: ",",
    headers: ["First Name", "Last Name", "Date", "Brand", "Condiiton", "Tenure"]
  };

  const handleOnRangeChange = (dates: any, dateStrings: [string, string]) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    setSearchValue(oldState => ({
      ...oldState,
      startDate,
      endDate
    }));
  };

  const [searchValue, setSearchValue] = useState<IApplicationSearch | null>(
    null
  );

  const handleOnFilter = (data: IApplicationSearch) => {
    setSearchValue(oldState => ({
      ...oldState,
      keyword: data.keyword,
      status: data.status
    }));
  };

  const searchApplication = useMutation({
    mutationFn: async (input: IApplicationSearch | null) => {
      const response = await getAllApplications(input);
      setPaginations({ ...paginations, ...response.data.data.pagination });
      setApplication(response.data.data.content);

      return response.data.data.content;
    }
  });

  useEffect(() => {
    if (isMounted.current) {
      searchApplication.mutate(searchValue);
    }
  }, [searchValue]);

  const handleOnReset = () => {
    setSearchValue(oldState => ({
      ...oldState,
      keyword: "",
      status: "",
      startDate: "",
      endDate: ""
    }));
  };
  const handleExport = async () => {
    try {
      const response = await exportApplicationDateRange(
        searchValue?.startDate || "",
        searchValue?.endDate || ""
      );
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `applications-${searchValue?.startDate || ""} - ${
          searchValue?.endDate
        }.csv`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.log("ERROR DOWNLOADING USERS> ", error);
    }
  };

  function handleTabChange(e: string) {
    console.log(e);
    setSearchValue(state => ({
      ...state,
      page: pages,
      size: sizes,
      modelType: e
    }));
  }
  return (
    <div className="container">
      <Row className="mb-10" justify={"space-between"}>
        <Typography.Title level={4}>Applications</Typography.Title>

        <Row gutter={[8, 8]}>
          <DatePicker.RangePicker
            onChange={handleOnRangeChange}
            ranges={RANGE_PRESETS}
            value={[
              moment(searchValue?.startDate || new Date()),
              moment(searchValue?.endDate || new Date())
            ]}
          />
          <Button onClick={handleExport}>
            <CloudDownloadOutlined />
            Export
          </Button>
        </Row>
      </Row>
      <Row>
        <Col span={24}>
          <Row justify={"space-between"} gutter={[16, 16]}>
            <CounterBox
              loading={isLoading}
              IconPath={folderIcon}
              title="Total Applications"
              value={`${paginations.totalDocs || 0}`}
            />
            <CounterBox
              loading={isLoading}
              IconPath={smallApplicationIcon}
              title="Approved Applications"
              value={data?.applicationCount?.approved}
            />
            <CounterBox
              loading={isLoading}
              IconPath={folderIcon2}
              title="Pending Applications"
              value={data?.applicationCount?.pending}
            />
          </Row>
        </Col>
      </Row>
      <br />
      <br />
      <Card
        title={
          <Row className="mb-3">
            <span className="table-title">
              Showing {paginations.size || 0} of {paginations.totalDocs} results
              for{" "}
            </span>
          </Row>
        }
        extra={
          <div style={{ position: "relative" }}>
            <Popover
              placement="bottomRight"
              title={"Filter "}
              content={() => (
                <ApplicationFilter
                  onFilter={handleOnFilter}
                  onReset={handleOnReset}
                  loading={
                    applicationResponse.isLoading || searchApplication.isLoading
                  }
                />
              )}
              trigger="click"
            >
              <Button className="flex filter-btn">
                Filter
                <img
                  src={filter}
                  style={{ width: "16px", marginRight: "5px" }}
                  alt="filter"
                  className="pl-3"
                />
              </Button>
            </Popover>
          </div>
        }
      >
        <Row>
          <Col span={24}>
            {/*<Row gutter={8} justify={"space-between"} align={"middle"}>
                <Col>
                  
                </Col>
              </Row>
              <br />
              <br />*/}

            <Tabs onChange={handleTabChange}>
              <Tabs.TabPane tab="All " key="all">
                {/* <AllUsers /> */}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Keza" key="keza">
                {/* <KezaUsers /> */}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Vendors" key="vendor">
                {/* <VendorUsers /> */}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Business" key="business">
                {/* <VendorUsers /> */}
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
        <Table
          className="table"
          loading={applicationResponse.isLoading || searchApplication.isLoading}
          dataSource={application || []}
          columns={columns}
          pagination={{
            pageSize: sizes,
            total: paginations.totalDocs,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "30", "40"],
            onChange(page) {
              setPages(page);
            },
            onShowSizeChange(current) {
              setSizes(current);
            }
          }}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};

export default Application;
