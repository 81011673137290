import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Center,
  Spinner,
  Stack,
  Flex,
  Text
} from "@chakra-ui/react";
import { useFecthAccountFromBvn } from "../../../api/mutations/bvn";
import { FaRegCopy } from "react-icons/fa";
import { copyToClipboard } from "../../../utilities/copy-to-clipboard";
import { showError } from "../../../helpers";

function AccountItem({ name, value }: { name: string; value: string }) {
  return (
    <Flex alignItems={"center"} gap={"8"}>
      <Text fontWeight={600}>{name}</Text>
      <Text>{value}</Text>
    </Flex>
  );
}
interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  bvn: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  bvn
}: CustomModalProps) => {
  const [accountDetails, setAccountDetails] = useState<any>([]);

  const { fetchAccountFromBvn, fetchAccountFromBvnResult } =
    useFecthAccountFromBvn();
  const copyOneItem = useCallback((item: any) => {
    let longText = "";
    Object.entries(item).map(([key, val]) => {
      longText += `${key}: ${val} \n`;
    });
    copyToClipboard(longText);
  }, []);

  const copyAllAccount = useCallback((item: any) => {
    let longText = "";
    item.forEach((item: any) => {
      Object.entries(item).map(([key, val]) => {
        longText += `${key}: ${val} \n`;
      });
      longText += `\n\n\n`;
    });
    copyToClipboard(longText);
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchAccountFromBvn(bvn)
        .then(res =>
          setAccountDetails(res.data?.data?.identity?.data?.full_details)
        )
        .catch(err => showError(err));
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Account Details</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Center mb={"4"}>
            <Button onClick={() => copyAllAccount(accountDetails)}>
              Copy All
            </Button>
          </Center>
          {fetchAccountFromBvnResult.isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Accordion allowMultiple>
              {accountDetails.map((item:any, index:any) => (
                <AccordionItem key={item.bankName}>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        Account {index + 1}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"flex-start"}
                    >
                      <Stack>
                        <AccountItem name="Bank Name" value={item.bankName} />
                        <AccountItem
                          name="Account Number"
                          value={item.accountDescription}
                        />
                        <AccountItem
                          name="Phone Number"
                          value={item.mobile_number}
                        />
                      </Stack>
                      <Box
                        onClick={() => copyOneItem(item)}
                        color={"#aa4428"}
                        cursor={"pointer"}
                      >
                        <FaRegCopy size={"30px"} />
                      </Box>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brown"
            color="white"
            bg="#AA4428"
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
