import { Box, Input, useDisclosure } from "@chakra-ui/react";
import { Button } from "antd";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { useReviewReciept } from "../../../api/mutations/appications";
import { useState } from "react";
import { showError } from "../../../helpers";

export default function ReviewReceit({applicationId }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState('')
  const reviewMutation = useReviewReciept({
    onError: (err:any) => {      
      onClose();
      showError(err);
    }
  });

  const handleInputChange = (e:any) => setInput(e.target.value)
  return (
    <Box>
      <Button onClick={onOpen}>Review reciept</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Review receipt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Interest</FormLabel>
              <Input type="number" onChange={handleInputChange}/>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              loading={reviewMutation.isLoading}
              onClick={() => reviewMutation.mutate(
                {
                  applicationId: applicationId,
                  interest: Number(input),
                }
                )}
            >
              Review
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
