import {Row,Typography,Card, Col } from "antd";
//import { FC, useState } from "react";
import { DatePicker,} from 'antd';
import one from "../../../assets/one.svg"
import two from "../../../assets/two.svg"
import three from "../../../assets/three.svg"
const { RangePicker } = DatePicker;
const LeaderBoard = () => {
  return (
<div className="container">
        <div className="row">
            <div className="col-md-12">
              <Card
                className="card-bord"
                title={
                    <Row className="" justify={"start"} align={"middle"}>
                        <Typography.Title level={5} style={{marginRight:"1.5rem"}}>Leaderboard</Typography.Title>

                        <RangePicker />
                    </Row>
                }
                >
                    <hr/>
                    <div>
                        <div className="">
                            <p className="lead-title">The leaderboard highlights the top performing members in your division</p>
                        </div>
                        <div className=" leaderboard grid">
                      
                          <div className="profile-left lead-left">
                            <p className="lead-subtitle"> {""} results in {""} division</p>

                            <Col className="grid lead-items">
                                <Row className="lead-item one" align={"middle"}>
                                  <div>
                                    <img src={one} alt="position"/>
                                  </div>
                                  <Row justify={"space-between"} align={"middle"}>
                                    <div>
                                      <p className="lead-subtitle"></p>
                                      <span className="lead-title">{""} - {""}</span>
                                    </div>
                                    <div>
                                      <p className="lead-title"></p>
                                    </div>
                                  </Row>
                                </Row>

                                <Row className="lead-item two" align={"middle"}>
                                  <div>
                                    <img src={two} alt="position"/>
                                  </div>
                                  <Row justify={"space-between"} align={"middle"}>
                                    <div>
                                      <p className="lead-subtitle"></p>
                                      <span className="lead-title">{""} - {""}</span>
                                    </div>
                                    <div>
                                      <p className="lead-title"></p>
                                    </div>
                                  </Row>
                                </Row>

                                <Row className="lead-item three"  align={"middle"}>
                                  <div>
                                    <img src={three} alt="position"/>
                                  </div>
                                  <Row  justify={"space-between"} align={"middle"}>
                                    <div>
                                      <p className="lead-subtitle"></p>
                                      <span className="lead-title">{""} - {""}</span>
                                    </div>
                                    <div>
                                      <p className="lead-title"></p>
                                    </div>
                                  </Row>
                                </Row>

                                <Row className="lead-item others" align={"middle"}>
                                  <div>
                                    <img src={""} alt="position"/>
                                  </div>
                                  <Row justify={"space-between"} align={"middle"}>
                                    <div>
                                      <p className="lead-subtitle"></p>
                                      <span className="lead-title">{""} - {""}</span>
                                    </div>
                                    <div>
                                      <p className="lead-title"></p>
                                    </div>
                                  </Row>
                                </Row>
                            </Col>
                              
                          </div>
                          <div className="profile-right lead-right">
                            <p className="lead-subtitle"><strong>Overall Performance</strong></p>

                            <Col className="grid lead-items">

                              <div className=" performance-item flex">
                                <Row className="per-item flex">
                                  <p className="l-date">This week:</p>
                                  <p className="position"> position</p>
                                </Row>
                                <Row className="lead-item others" align={"middle"}>
                                  <div>
                                    <img src={""} alt="position"/>
                                  </div>
                                  <Row justify={"space-between"} align={"middle"}>
                                    <div>
                                      <p className="lead-subtitle"></p>
                                      <span className="lead-title">{""} - {""}</span>
                                    </div>
                                    <div>
                                      <p className="lead-title"></p>
                                    </div>
                                  </Row>
                                </Row>
                              </div>

                              <div className=" performance-item flex">
                                <Row className="per-item flex">
                                  <p className="l-date">Last week:</p>
                                  <p className="position"> position</p>
                                </Row>
                                <Row className="lead-item others" align={"middle"}>
                                  <div>
                                    <img src={""} alt="position"/>
                                  </div>
                                  <Row justify={"space-between"} align={"middle"}>
                                    <div>
                                      <p className="lead-subtitle"></p>
                                      <span className="lead-title">{""} - {""}</span>
                                    </div>
                                    <div>
                                      <p className="lead-title"></p>
                                    </div>
                                  </Row>
                                </Row>
                              </div>

                              <div className=" performance-item flex">
                                <Row  className="per-item flex">
                                  <p className="l-date">This Month:</p>
                                  <p className="position"> position</p>
                                </Row>
                                <Row className="lead-item others" align={"middle"}>
                                  <div>
                                    <img src={""} alt="position"/>
                                  </div>
                                  <Row justify={"space-between"} align={"middle"}>
                                    <div>
                                      <p className="lead-subtitle"></p>
                                      <span className="lead-title">{""} - {""}</span>
                                    </div>
                                    <div>
                                      <p className="lead-title"></p>
                                    </div>
                                  </Row>
                                </Row>
                              </div>
                                
                            </Col>
                          </div>
                        </div>
                    </div>
                    
              </Card>
                
            </div>
        </div>
    </div>
  )
}

export default LeaderBoard