import { Typography } from "antd";
import { FC } from "react";

const SectionHeader: FC<{ title: string }> = ({ title }) => {
  return (
    <div className="section-header">
      <Typography.Text>{title}</Typography.Text>
    </div>
  );
};

export default SectionHeader;
