import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  Tabs,
  Select
} from "antd";

import { useNavigate, useParams } from "react-router-dom";
import "./NewProduct.css";
import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import Add from "../../assets/Add.svg";
import Menu from "../../assets/Menu.svg";
import image from "../../assets/image.svg";
import bin from "../../assets/bin.svg";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import {
  searchProductList,
  getProductDetailFromExternalSearch,
  createProduct
} from "../../api/productAdd";
import { Checkbox } from "antd";
import { showError, showMessage } from "../../helpers";
import { getSingleProduct, updateProductByID } from "../../api/product";
import { getTag } from "../../utilities/sanitizer";

const FormItem = Form.Item;
const { Search } = Input;

const NewProduct = () => {
  const navigate = useNavigate();
  //const params = useParams();
  const [form] = Form.useForm();
  const [modalform] = Form.useForm();

  const [searched, setSearched] = useState(false);
  const [chosen, setChosen] = useState(false);
  const [isSearching, setSearching] = useState(false);

  const [fetchingProduct, setFetchingProduct] = useState(false);

  const [brandNew, setBrandNew] = useState(true);

  const [isEditMode, setEditMode] = useState(false);
  const [status, setStatus] = useState<any>();
  const [product, setProduct] = useState<any>({});

  const params = useParams();

  const getProductById = useCallback(async () => {
    try {
      if (!params.id || params.id.trim() === "") return;

      setEditMode(true);
      setFetchingProduct(true);
      const response = await getSingleProduct(params.id as string);
      const product = response.data.data;
      
      setImgSrc(product.image[0]);
      form.setFieldsValue({
        name: product.name,
        color: product.colors.join(),
        phoneDisplay: product.component.display,
        phoneMicrochip: product.component.cpu,
        battery: product.component.batteryName,
        cellularData: product.component.network,
        camera: product.component.camera.join(),
        extraFeatures: product.extraFeatures
      });
      setFetchingProduct(false);

      setProduct(product);

      setPrices(product.pricing);

      setBrandNew(product.condition === "NEW");
      setStatus(product);
    } catch (error) {
      setFetchingProduct(false);
    }
  }, [params, form]);

  useEffect(() => {
    getProductById();
  }, [getProductById]);

  const [open, setOpen] = useState(false);
  const [phones, setPhones] = useState<any[]>([]);
  const [imgSrc, setImgSrc] = useState<any>();
  const [prices, setPrices] = useState<
    {
      storageRAM: string;
      storageROM: string;
      priceOld: string;
      priceNew: string;
    }[]
  >([{ storageRAM: "", storageROM: "", priceOld: "", priceNew: "" }]);
  const [pricesComponenet, setPhonePriceComponent] = useState<JSX.Element[]>(
    []
  );

  const handleRemovePriceComponent = (index: number) => {
    if (index === 0) return;
    if (prices.length === 0) return;

    const newPricesComponent = [...pricesComponenet];
    const newPrices = [...prices];
    newPrices.splice(index, 1);
    setPrices(newPrices);
    newPricesComponent.splice(index, 1);
    setPhonePriceComponent(newPricesComponent);
  };

  const handleCancel = () => {
    setOpen(false);
    setSearched(false);
    setChosen(false);
  };
  const onSearch = async (value: string) => {
    try {
      if (isSearching) return;
      setSearching(true);
      const productType = modalform.getFieldValue("productType");
      const response = await searchProductList(value, productType);
      setSearching(false);
      console.log("response> ", response);
      setPhones(response.data.data);
      setSearched(true);
    } catch (error) {
      setSearching(false);
      showError(error);
    }
  };

  const phoneDetailResponse = async (
    id: string,
    type: string,
    productImageUrl: string
  ) => {
    const response = await getProductDetailFromExternalSearch(
      id,
      type,
      productImageUrl
    );
    const product = response.data.data;

    return product;
  };
  const showModal = () => {
    setOpen(true);
  };

  const [loading, setLoadingProduct] = useState(false);

  const handleChosen = async (item: any) => {
    try {
      setLoading(true);
      const productType = modalform.getFieldValue("productType");
      const phoneResponse = await phoneDetailResponse(
        item.docId,
        productType,
        item.imageUrl
      );
      setLoading(false);
      setImgSrc(phoneResponse.imageUrl);
      setChosen(true);
      setOpen(false);
      console.log(phoneResponse.name);
      form.setFieldValue("name", phoneResponse.name);
      form.setFieldsValue({
        color: phoneResponse.colors,
        phoneDisplay: phoneResponse.dimensions,
        phoneMicrochip: phoneResponse.cpu,
        battery: phoneResponse.battery,
        cellularData: phoneResponse.network,
        camera: phoneResponse.camera.join()
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      console.log("called o");
      if (isLoading) return;
      const payload = form.getFieldsValue();

      setLoading(true);
      const request = {
        colors: payload.color ? payload.color.split(",") : "",
        name: payload.name,
        pricing: prices,
        condition: brandNew ? "NEW" : "PRE_OWNED",
        image: [imgSrc],
        component: {
          camera: payload.camera ? payload.camera.split(",") : "",
          display: payload.phoneDisplay,
          chip: payload.phoneMicrochip,
          batteryName: payload.battery
            ? payload.battery.split(",")[0].trim()
            : "",
          isBatteryRemovable: false,
          extraFeatures: payload.extraFeatures
        },
        type: isEditMode
          ? product.type
          : modalform.getFieldValue("productType").toUpperCase()
      };

      if (isEditMode) {
        await updateProductByID(params.id as string, request);
      } else {
        // await createProduct(request);
      }

      setLoading(false);

      showMessage(
        "Operation successful",
        isEditMode
          ? "Product update successfull. Redirecting..."
          : "Product created successfully",
        "success"
      );

      form.resetFields();

      setPrices([
        {
          storageRAM: "",
          storageROM: "string",
          priceOld: "string",
          priceNew: ""
        }
      ]);

      modalform.resetFields();

      if (isEditMode) setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.log("error> ", error);
      setLoading(false);
    }
  };

  const handleOnChange = (payload: {
    fieldName: "storageRAM" | "storageROM" | "priceOld" | "priceNew";
    value: string;
    index: number;
  }) => {
    const newPrices = [...prices];
    if (!newPrices[payload.index])
      setPrices([
        ...prices,
        { ...prices[0], [payload.fieldName]: payload.value }
      ]);
    else {
      newPrices[payload.index][payload.fieldName] = payload.value;

      setPrices(newPrices);
    }
  };

  const handleAddPriceComponent = (e: any) => {
    e.preventDefault();

    setPrices(state => [
      ...state,
      { storageRAM: "", storageROM: "", priceOld: "", priceNew: "" }
    ]);
  };

  return (
    <div className="container ">
      <div className="row mb-3">
        <div className="col-md-2">
          <Row >
            <Col span={24}>
              <Row gutter={8} justify={"space-between"} align={"middle"}>
                <Col>
                  <span className="cursor-pointer" onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined /> Back to Products
                  </span>
                </Col>
              </Row>
              <br />
              <br />

              <Card>
                <Tabs>
                  <Tabs.TabPane tab="Product Details" key="item-1">
                    <div className="product-container">
                      <div className="cont-body">
                        <div className="bdy-top">
                          <Row gutter={[32, 0]} justify={"space-between"}>
                            <Col>
                              <Col className="header-span">
                                <span>Product ID:</span>
                                {status?.data?._id || params.id}{" "}
                              </Col>
                              <Col className="header-span">
                                <span>Status:</span>{" "}
                                {getTag(
                                  status?.data?.isActive ? "Online" : "Offline"
                                )}
                              </Col>
                            </Col>
                            <Col>
                              <button
                                className="btn-container flex"
                                onClick={showModal}
                              >
                                <p> Import a device</p>
                                <img src={Add} alt="img" />
                              </button>
                            </Col>
                          </Row>

                          <span className="sub-txt">
                            or enter details manually
                          </span>
                        </div>

                        {fetchingProduct && (
                          <Row className="mb-2" justify={"center"}>
                            <Spin />
                          </Row>
                        )}

                        <Form
                          onFinishFailed={onFinishFailed}
                          form={form}
                          layout="vertical"
                        >
                          <div className="bdy-center grid">
                            <div className="bdy-left grid">

                              <div className="left-cont grid">
                                <div className="bdy-header">
                                  <p>Product details</p>
                                </div>
                                <div className="flex">
                                  <div className="input grid">
                                    <FormItem
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please provide product name"
                                        }
                                      ]}
                                      label="Product name"
                                      name="name"
                                    >
                                      <Input />
                                    </FormItem>
                                  </div>
                                </div>

                                <div className="flex ">
                                  <div className="input grid">
                                    <FormItem
                                      rules={[
                                        {
                                          required: false,
                                          message:
                                            "Please provide product colors"
                                        }
                                      ]}
                                      label="Colors"
                                      name="color"
                                    >
                                      <Input />
                                    </FormItem>
                                  </div>
                                </div>
                              </div>

                              <div className="left-cont grid">
                                <div className="bdy-header">
                                  <p>Phone Device components</p>
                                </div>

                                <div className="flex input-cont">
                                  <div className="input">
                                    <FormItem
                                      rules={[
                                        {
                                          required: false,
                                          message:
                                            "Please provide product camera"
                                        }
                                      ]}
                                      label="Camera"
                                      name="camera"
                                    >
                                      <Input />
                                    </FormItem>
                                  </div>

                                  <div className="input">
                                    <FormItem
                                      rules={[
                                        {
                                          required: false,
                                          message:
                                            "Please provide Phone display"
                                        }
                                      ]}
                                      label="Phone display"
                                      name="phoneDisplay"
                                    >
                                      <Input />
                                    </FormItem>
                                  </div>
                                </div>

                                <div className="flex input-cont">
                                  <div className="input">
                                    <FormItem
                                      rules={[
                                        {
                                          required: false,
                                          message:
                                            "Please provide Phone microchip"
                                        }
                                      ]}
                                      label="Phone microchip"
                                      name="phoneMicrochip"
                                    >
                                      <Input />
                                    </FormItem>
                                  </div>

                                  <div className="input">
                                    <FormItem
                                      rules={[
                                        {
                                          required: false,
                                          message:
                                            "Please provide product cellular data"
                                        }
                                      ]}
                                      label="Cellular data"
                                      name="cellularData"
                                    >
                                      <Input />
                                    </FormItem>
                                  </div>
                                </div>

                                <div className="flex input-cont">
                                  <div className="input">
                                    <FormItem
                                      rules={[
                                        {
                                          required: false,
                                          message:
                                            "Please provide Product battery"
                                        }
                                      ]}
                                      label="Battery "
                                      name="battery"
                                    >
                                      <Input />
                                    </FormItem>
                                  </div>
                                  <div className="input">
                                    <FormItem
                                      rules={[
                                        {
                                          required: false,
                                          message: ""
                                        }
                                      ]}
                                      label="Extras "
                                      name="extraFeatures"
                                    >
                                      <Input />
                                    </FormItem>
                                  </div>
                                </div>
                              </div>

                              <div className="left-cont grid">
                                <div className="bdy-header">
                                  <p>Pricing</p>
                                </div>

                                <div className="price-input flex">
                                  {prices.map((item, i) => (
                                    <RenderPriceBlockCompnent
                                      key={i}
                                      handleImageOnClick={() =>
                                        handleRemovePriceComponent(i)
                                      }
                                      index={i}
                                      values={prices[i]}
                                      handleOnChange={handleOnChange}
                                    />
                                  ))}

                                  <button
                                    onClick={handleAddPriceComponent}
                                    className="add-btn flex"
                                  >
                                    <img src={Add} alt="img" />
                                    Add
                                  </button>
                                </div>

                                <div className="input">
                                  <Checkbox
                                    checked={brandNew}
                                    onChange={e =>
                                      setBrandNew(e.target.checked)
                                    }
                                  >
                                    Brand New
                                  </Checkbox>

                                  <p className="subtext">
                                    Please uncheck if phone is Pre-Owned
                                  </p>
                                </div>
                              </div>
                              
                            </div>

                            <div className="bdy-right">
                              <div className="bdy-header">
                                <p>Product gallery</p>
                              </div>

                              <div className="gallery-cont grid">

                                <div className="major-img flex">
                                  <img src={imgSrc || image} alt="img" />
                                </div>

                                <Upload
                                action={`https://staging.keza.africa/api/v1/upload/devices/image`}
                                //customRequest={(info) =>{
                                  //setImgSrc(info.file)
                                //}}
                                onChange={(info)=> {
                                  if (info.file.status !== "uploading") {
                                    console.log(info.file, info.fileList);
                                  }
                                  if (info.file.status === "done") {
                                    message.success(`${info.file.name} file uploaded successfully`);
                                  } else if (info.file.status === "error") {
                                    message.error(`${info.file.name} file upload failed.`);
                                  }
                                }
                              }
                                >
                                  <Button
                                    className="upd-btn"
                                    icon={<UploadOutlined />}
                                  >
                                    Click to Upload
                                  </Button>
                                </Upload>
                                
                              </div>
                            </div>
                          </div>
                        </Form>
                        <>
                          <Modal
                            open={open}
                            title={[
                              <p className="modal-title">Import a device</p>
                            ]}
                            onCancel={handleCancel}
                            footer={[]}
                            width={1000}
                          >
                            <Form
                              onFinishFailed={onFinishFailed}
                              form={modalform}
                              layout="vertical"
                            >
                              <FormItem
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select product type"
                                  }
                                ]}
                                label="Device Category"
                                name="productType"
                              >
                                <Select
                                  placeholder="Product Type"
                                  defaultValue={"Phone"}
                                  options={[
                                    { label: "Phone", value: "Phone" },
                                    { label: "Laptop", value: "Laptop" }
                                  ]}
                                />
                              </FormItem>
                              <FormItem
                                rules={[
                                  {
                                    required: true,
                                    message: "Please provide product name"
                                  }
                                ]}
                                label="Device name"
                                name="search"
                              >
                                <Search
                                  placeholder="Start typing name of device...."
                                  allowClear
                                  size="large"
                                  onSearch={onSearch}
                                  className="modal-search"
                                  loading={isSearching}
                                />
                              </FormItem>
                            </Form>
                            {!searched && (
                              <div className="grid modal-footer">
                                <p className="sub-text">
                                  Search and select a device to import all its
                                  features
                                </p>
                              </div>
                            )}
                            {searched && (
                              <div className="grid modal-content">
                                <div className="grid modal-footer">
                                  <p className="import-des">
                                    You are about to import this device and all
                                    its features
                                  </p>
                                </div>

                                <span className="modal-hd">
                                  Search results for <span></span>
                                </span>
                                <Row gutter={[16, 16]}>
                                  {phones?.map((item: any, index: any) => {
                                    return (
                                      <Col
                                        className="result-card grid"
                                        onClick={() => handleChosen(item)}
                                        md={6}
                                        sm={24}
                                        key={index}
                                      >
                                        <div>
                                          <img src={item.imageUrl} alt="img" />
                                          <Typography.Title level={5}>
                                            {item.name}
                                          </Typography.Title>

                                          <Button
                                            key="submit"
                                            className="modal-btn"
                                            loading={loading}
                                            disabled={loading}
                                            onClick={() => handleChosen(item)}
                                          >
                                            Import
                                          </Button>
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </div>
                            )}
                          </Modal>
                        </>
                      </div>
                    </div>
                    <Col className="cont-footer">
                      <Button
                        htmlType="submit"
                        className="keza-btn-primary"
                        type="primary"
                        onClick={handleSubmit}
                        loading={isLoading}
                      >
                        {isEditMode ? "Update" : "Create"}
                      </Button>
                    </Col>
                  </Tabs.TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const RenderPriceBlockCompnent = ({
  handleImageOnClick,
  index,
  handleOnChange,
  values
}: {
  handleImageOnClick: any;
  index: any;
  handleOnChange: Function;
  values: {
    storageRAM: string;
    storageROM: string;
    priceOld: string;
    priceNew: string;
  };
}) => {
  return (
    <div className="pricing grid">
      <div className="flex input-cont-alt">
        <div className="input-small">
          <FormItem
            rules={[
              {
                required: true,
                message: "Please provide product RAM"
              }
            ]}
            label="RAM"
          >
            <Input
              onChange={e => {
                handleOnChange({
                  fieldName: "storageRAM",
                  value: e.target.value,
                  index
                });
              }}
              value={values.storageRAM}
            />
          </FormItem>
        </div>
        <div className="input-small">
          <FormItem
            rules={[
              {
                required: true,
                message: "Please provide product ROM"
              }
            ]}
            label="ROM"
          >
            <Input
              onChange={e => {
                handleOnChange({
                  fieldName: "storageROM",
                  value: e.target.value,
                  index
                });
              }}
              value={values.storageROM}
            />
          </FormItem>
        </div>
      </div>

      <div className="flex input-cont-alt">
        <span className="amount">Amount</span>

        <div className="input-small">
          <FormItem
            rules={[
              {
                required: true,
                message: "Please provide product price"
              }
            ]}
            label="Brand new"
          >
            <Input
              type="number"
              onChange={e => {
                handleOnChange({
                  fieldName: "priceNew",
                  value: e.target.value,
                  index
                });
              }}
              value={values.priceNew}
            />
          </FormItem>
        </div>
        <div className="input-small">
          <FormItem
            rules={[
              {
                required: true,
                message: "Please provide product price"
              }
            ]}
            label="Pre-owned"
          >
            <Input
              type="number"
              onChange={e => {
                handleOnChange({
                  fieldName: "priceOld",
                  value: e.target.value,
                  index
                });
              }}
              value={values.priceOld}
            />
          </FormItem>
        </div>

        <img
          onClick={handleImageOnClick}
          className="bin"
          src={bin}
          alt="icon"
        />
      </div>
    </div>
  );
};

export default NewProduct;
