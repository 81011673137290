import axios from "axios";
import { showError } from "../helpers";
import { getUserToken } from "../utilities/storage";
import LogOut from "../utilities/LogOut";
import { IApplicationSearch } from "@app-interfaces";
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3050/api/v1"
});

api.interceptors.response.use(
  value => Promise.resolve(value),
  error => {
    showError(error);
    if (error.response.status === 403) {
      LogOut();
    }
    if (error.response.status === 401) {
      LogOut();
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((req: any) => {
  const token = getUserToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

export const getAllApplications = (data: IApplicationSearch | null) => {
  return api.get(
    `/admin/users/applications?page=${data?.page || 0}&size=${
      data?.size || 10
    }&keyword=${data?.keyword || ""}&startDate=${
      data?.startDate || ""
    }&endDate=${data?.endDate || ""}&status=${data?.status || "ALL"}${
      data?.vendor ? `&vendor=${data?.vendor}` : ""
    }&modelType=${data?.modelType || ""}`
  );
};
/*export const getAllApplications = () => {
  return api.get(`/admin/users/applications`);
};*/

export const getSingleApplication = (id: string) => {
  return api.get(`/admin/application/${id}`);
};

export const getApplicationProcessReviews = (id: string) => {
  return api.get(`/admin/application/${id}/review-processes`);
};

export const approveApplication = (applicationId: string) => {
  return api.put(`/admin/user/application/${applicationId}/approve`);
};

export const updateApplicationProcess = (
  applicationId: string,
  processId: number,
  status: string
) => {
  return api.put(`/admin/application/${applicationId}/proccess/update`, {
    processId,
    status
  });
};

export const getApplicationBillingProccess = (applicationId: string) => {
  return api.get(`/admin/billing/${applicationId}/tracking`);
};

export const getMandateStatus = (applicationId: string) => {
  return api.get(`/admin/mandate/${applicationId}/status`);
};

export const sendDebitInstruction = (billingId: string) => {
  return api.get(`/admin/billing/${billingId}/send`);
};

export const rejectApplication = (
  applicationId: string,
  rejectionTitle: string
) => {
  return api.post(`/admin/application/${applicationId}/reject`, {
    rejectionTitle
  });
};

//application/:id/repayment-info

export const applicationFinancialDetail = (applicationId: string) => {
  return api.get(`/admin/application/${applicationId}/repayment-info`);
};

//application/:id/loan-apply

export const requestFunding = (applicationId: string, payload: any) => {
  return api.post(`/admin/application/${applicationId}/loan-apply`, payload);
};

//billing/disbursements

export const getDisbursments = () => {
  return api.get(`/admin/billing/disbursements`);
};

export const updateDownpaymentAmount = (
  applicationId: string,
  amount: string,
  dueDate: string,
  status: string,
  billingId: string
) => {
  return api.put(
    `/admin/billing/application/${applicationId}/update-downpayment`,
    { amount, dueDate, status, billingId }
  );
};

export const updateRepaymentAmount = (
  applicationId: string,
  amount: string,
  dueDate: string,
  status: string,
  billingId: string
) => {
  return api.put(
    `/admin/billing/application/${applicationId}/update-repayment`,
    { amount, dueDate, status, billingId }
  );
};

export const getDebitStatus = (billingId: string) => {
  return api.get(`/admin/billing/${billingId}/debit-status`);
};

export const mandatePaymentStatus = (applicationId: string) => {
  return api.get(`/admin/billing/mandate/${applicationId}/payment-history`);
};

export const getMandatePaymentHistory = (applicationId: string) => {
  return api.get(`/admin/billing/mandate/${applicationId}/payment-history`);
};

export const stopMandate = (applicationId: string) => {
  return api.get(`/admin/billing/application/${applicationId}/stop-mandate`);
};

export const initiateDebit = (billingId: string) => {
  return api.get(`/admin/billing/${billingId}/debit`);
};

export const requestEndorserDetails = (applicationId: string) => {
  return api.get(`/admin/get-endorser-details/${applicationId}`);
};

export const requestLoanAgreement = (applicationId: string) => {
  return api.post(`/admin/request-loan-agreement/`, { applicationId });
};

export const requestDeviceLoanAgreement = (applicationId: string) => {
  return api.post(`/admin/request-device-loan-agreement/`, { applicationId });
};

export const sendPaymentInstruction = (billingId: string) => {
  return api.post(`/admin/device/make-downpayment`, { billingId });
};

export const getPaymentStatus = (billingId: string) => {
  return api.get(`/admin/billing/${billingId}/verify-payment-status`);
};

export const makeCharge = (billingId: string) => {
  return api.get(`/admin/billing/${billingId}/recurring-charge`);
};

export const deleteApplications = (applicationId: string) => {
  return api.delete(`/admin/application/${applicationId}`);
};

export const getUserKyc = (applicationId: string) => {
  return api.get(`/admin/user/${applicationId}/kyc-info`);
};

export const getProductRequests = (id: string) => {
  return api.get(`/admin/product-requests/application/${id}`);
};
export const acceptOffer = async ({ Id = "", bid = 0 }) => {
  if (!!Id && bid > 0) {
    return api.post(`/admin/product-requests/place-offer`, { Id, bid });
  }
};

export const exportApplicationDateRange = (
  startDate: string,
  endDate: string
) => {
  return api.get(
    `admin/applications/download?startDate=${startDate}&endDate=${endDate}`,
    { responseType: "blob" }
  );
};

export const exportVendorApplicationDateRange = (
  vendorId: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `admin/vendors/${vendorId}/download-applications?startDate=${startDate}&endDate=${endDate}`,
    { responseType: "blob" }
  );
};
export const resetOnboardKey = (data: {
  applicationId?: string;
  resetKey?: string;
  category?: string;
}) => {
  return api.post(`businesses/applications/questions/reset-key`, data);
};

export const getMandateStatusVfd = async (applicationId: string) => {
  return api.get(`admin/mandate/${applicationId}/vfd/mandate-query`);
};
export const sendDirectDebitVfd = async (data: {
  applicationId: string;
  billingId: string;
}) => {
  return api.post(
    `admin/mandate/${data.applicationId}/vfd-charge/${data.billingId}`
  );
};
export const getAccountBalance = async (applicationId: string) => {
  return api.post(`admin/mandate/${applicationId}/vfd/account-balance`);
};

export const updateDeviceImeis = async (data: {
  applicationId: string;
  imeis: any;
}) => {
  return api.put(`admin/applications/update-device-imeis`, data);
};

export const reviewReciept = async (data:{
  applicationId: string,
   interest: string,
}) => {
  return api.post(`admin/preview/generate-receipt`, data);
};
export const sendReciept = async (data:{
  applicationId: string,
   interest: string,
}) => {
  return api.post(`admin/generate-receipt`, data);
};
