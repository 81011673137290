import { CheckOutlined, StopOutlined } from "@ant-design/icons";
import { Button, Empty, Table } from "antd";
import { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";
import {
  getApplicationProcessReviews,
  updateApplicationProcess,
} from "../../../api/application";
import { performKarmaCheck } from "../../../api/user";
import { showError, showMessage } from "../../../helpers";
import { getTag } from "../../../utilities/sanitizer";

const DEFAULT_ACTION = [
  { processId: 8, processName: "Fund Application", status: "pending" },
];

const Underwriting = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const { isLoading, error, data } = useQuery({
    queryKey: ["review-process", params.id],
    queryFn: async () => {
      const response = await getApplicationProcessReviews(params.id as string);
      return response.data.data;
    },
  });
  const queryClient = useQueryClient();
  const handleProcessRun = (processId: number | string) => {
    switch (processId) {
      case 1:
        //perform karma
        return checkKarma();
      case 2:
        // credit check
        break;
      default:
        return;
    }
  };
  const updateProcessStatus = async (data: {
    status: "pass" | "fail";
    processId: number;
  }) => {
    try {
      setLoading(true);
      await updateApplicationProcess(
        params.id as string,
        data.processId,
        data.status
      );
      setLoading(false);
      queryClient.invalidateQueries({
        queryKey: ["review-process", params.id],
      });
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };
  const columns = useMemo(
    () => [
      {
        title: "Process ID",
        dataIndex: "processId",
        key: "processId",
      },
      {
        title: "Procces Name",
        dataIndex: "processName",
        key: "processName",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (record: any) => <>{getTag(record)}</>,
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: `${new Date().getTime()}`,
        render: (record: any, data: any) => {
          return (
            <>
              {data.processId === 8 && (
                <Link to="fund">
                  <Button>Run</Button>
                </Link>
              )}

              {[1].includes(data.processId) && (
                <Button
                  loading={loading}
                  onClick={() => handleProcessRun(data.processId)}
                  disabled={!data.isRunnable || isPassedOrFail(data.status)}
                >
                  Run
                </Button>
              )}

              {!data.shouldSkipCheck && (
                <>
                  <Button
                    onClick={() =>
                      updateProcessStatus({
                        status: "pass",
                        processId: data.processId,
                      })
                    }
                    disabled={isPassedOrFail(data.status)}
                  >
                    <div className="d-flex align-items-center">
                      <CheckOutlined /> <span> Pass</span>
                    </div>
                  </Button>
                  <Button
                    onClick={() =>
                      updateProcessStatus({
                        status: "fail",
                        processId: data.processId,
                      })
                    }
                    danger
                    disabled={isPassedOrFail(data.status)}
                  >
                    <div className="d-flex align-items-center">
                      <StopOutlined /> <span> Fail</span>
                    </div>
                  </Button>
                </>
              )}
            </>
          );
        },
      },
    ],
    [loading]
  );
  const isPassedOrFail = (status: any) => ["PASSED", "FAILED"].includes(status);
  const checkKarma = async () => {
    try {
      setLoading(true);
      await performKarmaCheck(params.id as string);
      setLoading(false);
      queryClient.invalidateQueries({ queryKey: ["application", params.id] });
      showMessage("Operation successful", "Karma check done", "success");
    } catch (error: any) {
      console.log(error?.response);
      setLoading(false);
      showError(error);
    }
  };
  if (error) return <Empty />;
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Table
            columns={columns}
            loading={isLoading}
            locale={{ emptyText: <Empty /> }}
            dataSource={data && data.length > 0 ? data : DEFAULT_ACTION}
          />
        </div>
      </div>
    </div>
  );
};

export default Underwriting;
