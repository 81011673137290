import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Box,
  Center,
  Stack,
  ModalProps,
  ModalCloseButton,
  InputGroup,
  InputRightElement,
  Input,
  Flex,
  Text
} from "@chakra-ui/react";
import CustomSelect from "../../../components/Select/CustomSelect";
import { TextInput } from "../../../components/Input/Input";
import { useState } from "react";
import { showError, showMessage } from "../../../helpers";
import { useSearchGsmArena } from "../../../api/mutations/product";
import GsmArenaPhoneCard from "./GsmArenaPhoneCard";
const options = [
  { value: "Phone", label: "Phone" },
  { value: "Laptop", label: "Laptop" },
  { value: "Watch", label: "Watch" }
];
export type GsmArenaPhone = {
  _link: string;
  imageUrl: string;
  name: string;
  docId: string;
};
type AddressProps = Pick<ModalProps, "isOpen" | "onClose">;
export default function ImportModal({ isOpen, onClose }: AddressProps) {
  const [deviceName, setDeviceName] = useState<string>("");
  const [dataEmpty, setDataEmpty] = useState(false);
  const [category, setCategory] = useState("");
  const { searchGsmArena, result } = useSearchGsmArena();
  const [phones, setPhones] = useState<Array<GsmArenaPhone>>([]);
  const searchPhone = async () => {
    setPhones([])
    if (!deviceName || !category) {
      showMessage("Invalid operation", "Please select device", "error");
      return;
    }
    const res = await searchGsmArena({
      searchValue: deviceName,
      type: category
    });
    if (res?.data?.data?.length === 0) {
      setDataEmpty(true);
      return;
    }
    setPhones(res.data.data);
  };
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={"70vw"}>
          <ModalCloseButton />

          <ModalBody p={"2rem"}>
            <Stack spacing={"8"}>
              <Box>
                <CustomSelect
                  options={options}
                  onChange={event => {
                    setDataEmpty(false);
                    setCategory(event.value);
                  }}
                  isSearchable={true}
                  label="State"
                />
              </Box>

              <Box position={"relative"}>
                <InputGroup size="md">
                  <Input
                    value={deviceName}
                    onChange={e => {
                      setDataEmpty(false);
                      setDeviceName(e.target.value);
                    }}
                    borderRadius={"0px"}
                    borderColor={"black"}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      mr={"4"}
                      isLoading={result.isLoading}
                      h="1.75rem"
                      size="sm"
                      onClick={searchPhone}
                    >
                      Search
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {dataEmpty && <Text color={"red"}>Device not found</Text>}
              </Box>

              <Flex flexWrap={"wrap"} gap="8">
                {phones?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <GsmArenaPhoneCard
                        onClose={onClose}
                        {...item}
                        category={category}
                      />
                    </Box>
                  );
                })}
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
