import { Button, Card, Col, Popconfirm, Row, Table, Typography, message } from 'antd'
import { FC, useMemo } from 'react'
import { formatDateTime } from '../../../utilities/date-formatter';
import { getTag } from '../../../utilities/sanitizer';

const Applications:FC <{section:any, details:any}> = ({section, details}) => {
  /*const deleteApplication = async (application_id: string) => {
    try {
      if (loading) return;
      setLoading(true);
      await deleteApplications(application_id);

      setLoading(false);

      showMessage("Operation successful", "Application Deleted", "success");
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };*/
  const confirm = (application_id: any) => {
    //deleteApplication(application_id);
  };
  const cancel = () => {
    message.error("Application not deleted");
  };
  const columns = useMemo(
    () => [
      {
        title: "Business",
        dataIndex: "owner",
        key: "owner",
        render: (record: any, data: any) => {
          return (
            <div>
              <Typography.Title level={5}>
                {data.owner?.firstName} {data.owner?.lastName}
              </Typography.Title>
              <span>{data.owner?.email}</span>
            </div>
          );
        }
      },
      {
        title: "Device",
        dataIndex: "product",
        key: "device",
        render: (record: any) => {
          return <>{record?.name}</>;
        }
      },
      {
        title: "Details",
        dataIndex: "phoneStorage",
        key: "phoneStorage",
        render: (record: any, data: any) => {
          return (
            <>
              {record},{data.phoneColor}
            </>
          );
        }
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: record => formatDateTime(record)
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: record => getTag(record)
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: "Action",
        render: (record, data) => (
          <Row gutter={[8, 0]}>
            <Col>
                <Button>View</Button>
            </Col>
            {data.status !== "DELETED" && (
              <Col>
                  <Popconfirm
                  title={`Delete ${data.owner?.firstName || ""} ${data.owner?.lastName|| ""}'s application`}
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button>
                    Delete
                  </Button>
                </Popconfirm>
              </Col>
            )}
          </Row>
        )
      }
    ],
    []
  );
  return (
    <div>
        <Row className="mb-10" justify={"space-between"}>
        <Typography.Title level={4} className="page-title">
          Business Applications
        </Typography.Title>
      </Row>
      <Card>
        <Table
          className="table"
          //loading={"bussinesResponse.isLoading"}
          //dataSource={"bussiness" || []}
          //pagination={{
            //pageSize: pagination.totalCount,
            //total: pagination.numberOfPages,
            //showSizeChanger: true,
            //pageSizeOptions: ["5", "10", "20", "30", "40"]
          //}}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      </Card>

    </div>
  )
}

export default Applications