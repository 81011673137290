import { Button, Card, Col, Input, Row, Table, Typography } from "antd";
import { Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { getAllAdmins } from "../../api/admins";
import { getTag } from "../../utilities/sanitizer";
import { SearchOutlined } from "@ant-design/icons";
import AdminForm from "./AdminForm";
import { UserPofileState } from "../../resources/user.resource";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { paginationData } from "../../types/pagination.inteface";

const AdminCreate = () => {
  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);
  const profile: any = useRecoilValue(UserPofileState);
  const [pagination, setPagination] = useState<paginationData>({
    numberPages: 1,
    totalDocs: 10,
    query: {
      keyword: "",
      page: 0,
      size: 10,
      status: undefined
    }
  });

  const adminResponse = useMutation({
    mutationFn: async (input: paginationData) => {
      const response = await getAllAdmins(input.query as any);
      setPagination({ ...pagination, ...response.data.pagination });
      setAdmins(response.data.content);
      return response.data.content;
    }
  });

  const columns = useMemo(
    () => [
      {
        title: "User",
        dataIndex: "owner",
        key: "owner",
        render: (record: any, data: any) => {
          return (
            <div>
              <Typography.Title level={5}>
                {data?.firstName} {data?.lastName}
              </Typography.Title>
            </div>
          );
        }
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        render: (record: any) => {
          return <Typography.Paragraph>{record}</Typography.Paragraph>;
        }
      },
      {
        title: "Status",
        dataIndex: "isActive",
        key: "isActive",
        render: (record: any) => getTag(record ? "active" : "pending")
      },
      {
        title: "Actions",
        dataIndex: "_id",
        width: "25%",
        key: `${new Date().getTime()}`,
        render: (record: any, data: any) => (
          <Row gutter={[8, 0]}>
            <Col>
              <Button>Update</Button>
            </Col>
            <Col>
              {data.isActive ? (
                <Button danger>Disable</Button>
              ) : (
                <Button>Enable</Button>
              )}
            </Col>
          </Row>
        )
      }
    ],
    []
  );

  useEffect(() => {
    adminResponse.mutate(pagination);
  }, [pagination.query]);

  useEffect(() => {
    if (!["OPS_MANAGER", "SUPER_ADMIN"].includes(profile.role)) {
      navigate("/dashboard");
    }
  }, [profile.role]);

  return (
    <div className="container">
      <Row className="mb-10" justify={"space-between"}>
        <Typography.Title level={4} className="page-title">
          Administrators
        </Typography.Title>
      </Row>

      <Card
        className="profile-cont"
        title="Please complete this form to add an Administrator"
      >
        <AdminForm />
      </Card>
      <br />
      <br />
      <br />
      <Card
        title=""
        extra={
          <div className="flex">
            <Select
              defaultValue="Sort"
              style={{ width: 120 }}
              onChange={value => {
                setPagination({
                  ...pagination,
                  query: {
                    ...pagination.query,
                    status: (value === "" ? undefined : value) as
                      | boolean
                      | undefined
                  }
                });
              }}
              options={[
                { value: "", label: "ALL" },
                { value: true, label: "Enabled" },
                { value: false, label: "Disabled" }
              ]}
            />
            <Input
              prefix={<SearchOutlined />}
              placeholder="search by ID, admin's name"
              onChange={event =>
                setPagination({
                  ...pagination,
                  query: {
                    ...pagination.query,
                    keyword: event.target.value
                  }
                })
              }
            />
          </div>
        }
      >
        <Table
          loading={adminResponse.isLoading}
          dataSource={admins}
          columns={columns}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "30", "40"]
          }}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};
export default AdminCreate;
