import { useMutation } from "react-query";
import {
  createProduct,
  getDetailsFromExternalSearch,
  searchGsmArena,
  searchProductList,
  uploadImage

} from "../productAdd";
import {  uploadNovo} from "../product"
import { result } from "lodash";

export const useCreateProduct = () => {
  const { mutateAsync, ...result } = useMutation(createProduct);
  return {
    createProduct: mutateAsync,
    result
  };
};

export const useUploadImage = () => {
  const { mutateAsync, ...result } = useMutation(uploadImage);
  return {
    uploadImage: mutateAsync,
    result
  };
};

export const useSearchGsmArena = () => {
  const { mutateAsync, ...result } = useMutation(searchGsmArena);
  return {
    searchGsmArena: mutateAsync,
    result
  };
};

export const useGetDetailsFromeExternal = () => {
  const { mutateAsync, ...result } = useMutation(getDetailsFromExternalSearch);
  return {
    getDetailsFromExternalSearch: mutateAsync,
    result
  };
};

export const useUploadNovo = () => {
  const {mutateAsync, ...result} = useMutation(uploadNovo);
  return{
    uploadNovo: mutateAsync,
    result
  }
}
