import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Row, Spin, Tabs } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { approveUser, getSingleUser } from "../../api/user";
import { showMessage } from "../../helpers";
import Activity from "./Activity/Activities";
import Kyc from "./Kyc/Kyc";
import UserOrders from "./Orders/Orders";
import PaymentHistory from "./PaymentHistory/PaymentHistory";
import "./userdetail.css";
import UserProfile from "./UserProfile/UserProfile";
import UploadedFiles from "./UploadedFiles/UploadedFiles";
import { UserPofileState } from "../../resources/user.resource";
import { useRecoilValue } from "recoil";

const UserDetail = () => {
  const navigate = useNavigate();
  const params = useParams() as any;
  const queryClient = useQueryClient();

  const userDetailResponse = useQuery({
    queryKey: ["user", params.id],
    queryFn: async () => {
      const response = await getSingleUser(params.id);
      return response.data.data;
    }
  });
  const profile = useRecoilValue(UserPofileState);

  const mutation = useMutation({
    mutationFn: approveUser,
    onSuccess: () => {
      // Invalidate and refetch
      showMessage(
        "Operation successful",
        "User profile approved successfully",
        "success"
      );
      queryClient.invalidateQueries({ queryKey: ["user", params.id] });
      queryClient.invalidateQueries({ queryKey: ["users"] });
    }
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Row>
            <Col span={24}>
              <Row gutter={8} justify={"space-between"} align={"middle"}>
                <Col>
                  <span className="cursor-pointer" onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined /> Back to users
                  </span>
                </Col>
                <Col>
                  <Button
                    onClick={() => mutation.mutate(params.id)}
                    className="keza-btn-primary"
                  >
                    Approve
                  </Button>
                </Col>
              </Row>
              <br />
              <br />

              {profile.role !== "AGENT" ? (
                  <Tabs>
                              <Tabs.TabPane tab="Kyc" key="item-1">
                                <Kyc />
                              </Tabs.TabPane>
                              <Tabs.TabPane tab="User Profile" key="item-2">
                                {userDetailResponse.isLoading ? (
                                  <Spin />
                                ) : userDetailResponse.error ? (
                                  <Empty />
                                ) : (
                                  <UserProfile user={userDetailResponse.data} />
                                )}
                              </Tabs.TabPane>
                              <Tabs.TabPane tab="Applications" key="item-3">
                                <UserOrders />
                              </Tabs.TabPane>
                              <Tabs.TabPane tab="Payment History" key="item-4">
                                <PaymentHistory />
                              </Tabs.TabPane>
                              <Tabs.TabPane tab="Uploads" key="item-5">
                                <UploadedFiles user={userDetailResponse.data} />
                              </Tabs.TabPane>
                              <Tabs.TabPane tab="Activity" key="item-6">
                                <Activity />
                              </Tabs.TabPane>
                              <Tabs.TabPane tab="Feedback" key="item-7">
                                <Empty />
                              </Tabs.TabPane>
                  </Tabs>
              ):
                (
                  <Tabs>
                             
                      <Tabs.TabPane tab="User Profile" key="item-2">
                          {userDetailResponse.isLoading ? (
                              <Spin />
                           ) : userDetailResponse.error ? (
                              <Empty />
                           ) : (
                             <UserProfile user={userDetailResponse.data} />
                           )}
                      </Tabs.TabPane>
                            
                  </Tabs>
                )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
