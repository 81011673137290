import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  Row,
  Select,
  Spin,
  Typography
} from "antd";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getVendors, getWalletBalance, walletWithdraw } from "../../api/wallet";
import { showMessage } from "../../helpers";
import { formatAsCurrency } from "../../utilities/number-formatter";

const WalletWithdraw = () => {
  const navigate = useNavigate();
  const params = useParams();
  const walletEnquiry = useQuery({
    queryKey: ["wallet-balance", params.accountNumber],
    queryFn: async () => {
      const response = await getWalletBalance(params.accountNumber as string);
      return response.data.data.data;
    }
  });

  const [amount, setAmount] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const vendorsResponse = useQuery({
    queryKey: ["vendors"],
    queryFn: async () => {
      const response = await getVendors();
      return response.data.data.data;
    }
  });

  const handleWithdraw = async () => {
    try {
      if (amount.trim() === "" || vendorId.trim() === "")
        return showMessage(
          "Validation failed",
          "Amoount and Recipient is required",
          "info"
        );

      if (loading) return;
      setLoading(true);
      await walletWithdraw(params.accountNumber as string, +amount, vendorId);
      queryClient.invalidateQueries({
        queryKey: ["wallet-balance", params.accountNumber]
      });
      showMessage(
        "Operation successful",
        "Transfer made successfully",
        "success"
      );
      setLoading(true);
    } catch (error) {
      setLoading(false);
    }
  };

  const renderWalletDetails = () => {
    return (
      <div className="center-form">
        <Row className="mb-1">
          <Col>
            <Typography.Text>Balance</Typography.Text>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Typography.Title>
              {walletEnquiry.isLoading ? (
                <Spin />
              ) : (
                formatAsCurrency(walletEnquiry.data?.accountBalance)
              )}
            </Typography.Title>
          </Col>
        </Row>
        <Row className="mb-3">
          <label>Wallet Name</label>
          <Input value={walletEnquiry.data?.client} disabled />
        </Row>
        <Row className="mb-3">
          <label>Product Name</label>
          <Input value={walletEnquiry.data?.savingsProductName} disabled />
        </Row>
        <Row className="mb-3">
          <label>Account Number</label>
          <Input value={walletEnquiry.data?.accountNo} disabled />
        </Row>
        <Row className="mb-4">
          <label>Amount to withdraw</label>
          <Input
            value={amount}
            onChange={e => setAmount(e.target.value)}
            type="number"
          />
        </Row>
        <Row className="mb-4">
          <div className="w-100">
            <label>Recipient</label>
          </div>
          <Select
            value={vendorId}
            onChange={value => setVendorId(value)}
            className="w-100"
            loading={vendorsResponse.isLoading}
          >
            {(vendorsResponse.data || []).map((item: any) => (
              <Select.Option value={item._id}>
                <div>
                  <p>{item.bankName}</p>
                  <strong>
                    {item.accountName} - {item.accountNumber}
                  </strong>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Row>
        <br />
        <br />
        <Row className="mb-3 ">
          <div className="text-center w-100">
            <Button
              htmlType="submit"
              className="keza-btn-primary"
              type="primary"
              onClick={handleWithdraw}
              disabled={loading}
              loading={loading}
            >
              Withdraw
            </Button>
          </div>
        </Row>
        <br />
        <br />
      </div>
    );
  };
  return (
    <div className="container mb-4">
      <div className="row">
        <div className="col-md-12">
          <Row className="mb-4">
            <Col span={24}>
              <span className="cursor-pointer" onClick={() => navigate(-1)}>
                <ArrowLeftOutlined /> Back
              </span>
            </Col>
          </Row>
          <Card title="Wallet Withdrawal">
            {params.accountNumber === "undefined" ? (
              <div className="my-3 text-center">
                <Empty />
              </div>
            ) : (
              renderWalletDetails()
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default WalletWithdraw;
