import { Button, Card, Col, Form, FormInstance, Input, Row, Spin, Typography } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react';
import { showMessage } from "../../helpers";
import { createBusiness, getBusinessById, updateBusinessByID } from '../../api/business';
import { useNavigate, useParams } from 'react-router-dom';
import OptionalForm from './OptionalForm';
import CompulsoryForm from './CompulsoryForm';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { TextArea } = Input;


const CreateBusiness = () => {
    const navigate = useNavigate();
    const formRef = useRef<FormInstance>(null);
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [fetchingProduct, setFetchingProduct] = useState(false);
    const [editMode, setEditMode] = useState(false);
    
    const params = useParams();
    
    const handleSubmit = async () => {
          try {
            if (isLoading) return;
           const payload = form.getFieldsValue();
           
           const downpayment =  editMode ? payload.downPayments :  payload.downPayments.split(",");
           const downpaymentNum = editMode ? payload.downPayments : downpayment.map((item:any) => (Number(item)));
           const repayment = editMode ? payload.repaymentPlans : payload.repaymentPlans.split(",");
           const payloadAddress = {
                state: payload.state,
                city: payload.city,
                address: payload.address,
                country: payload.country
            };
            const address = editMode? payloadAddress.toString() : payloadAddress ;
            const payloads = {
                name: payload.name,
                address: address,
                number: payload.number,
                contact: payload.contact,
                logoUrl: payload.logoUrl,
                branding: {
                    faviconUrl: payload.faviconUrl,
                    primaryColor: payload.primaryColor,
                    secondaryColor: payload.secondaryColor,
                    textColor: payload.textColor,
                    mobileBannerUrl: payload.mobileBannerUrl,
                    bannerUrl:payload.bannerUrl,
                    footerTextColor:payload.footerTextColor,
                    footerBackgroundColor:payload.footerBackgroundColor,
                },
                homepageUrl: payload.kezahomepageUrl,
                repaymentPlans: repayment,
                downPayments: downpaymentNum,
                interest: payload.interest,
                insurance: payload.insurance,
                user: payload.users,
                questions: {
                    identity:payload.identity,
                    employment: payload.employment,
                    endorser: payload.endorser,
                },
                faq:payload.faq,
                consent:payload.consent,
            };
            //console.log(payloads)
            setLoading(true);
            if (editMode) {
                await updateBusinessByID(params.id as string, payloads);
              } else {
                await createBusiness(payloads);
              }
            
            setLoading(false);
            showMessage(
              editMode
                ? "Business update successfull. Redirecting..."
                : "Business created successfully",
                "operation sucessful",
                "success"
            );
      
            form.resetFields();
      
            if (editMode) setTimeout(() => navigate(-1), 2000);
          } catch (error) {
            setLoading(false);
          }
        };
        
    const getBusinessByID = useCallback(async () => {
            try {
              if (!params.id || params.id.trim() === "") return;
        
              setEditMode(true);
              setFetchingProduct(true);
              const response = await getBusinessById(params.id as string);
              const business=response.data.data;
              form.setFieldsValue({
                name: business.name,
                state: business.address.state,
                city: business.address.city,
                address: business.address.address,
                country: business.address.country,
                number: business.number,
                contact: business.contact,
                logoUrl: business.logoUrl,
                faviconUrl: business.branding.faviconUrl,
                primaryColor: business.branding.primaryColor,
                secondaryColor: business.branding.secondaryColor,
                textColor: business.branding.textColor,
                mobileBannerUrl: business.branding.mobileBannerUrl,
                bannerUrl:business.branding.bannerUrl,
                footerTextColor:business.branding.footerTextColor,
                footerBackgroundColor:business.branding.footerBackgroundColor,
                kezahomepageUrl: business.homepageUrl,
                repaymentPlans: business.repaymentPlans,
                downPayments:business.downPayments,
                interest: business.interest.$numberDecimal,
                insurance: business.insurance,
                users:business.users,
                faq:business.faq, 
                identity:business.questions.identity,
                employment: business.questions.employment,
                endorser: business.questions.endorser,
                consent:business.consent,
          });
              setFetchingProduct(false);
        
            } catch (error) {
                setFetchingProduct(false);
            }
        }, [params, form]);
        
    useEffect(() => {
      getBusinessByID();
    },[getBusinessByID]);

    const onFinish = () =>{
        handleSubmit()
    }
    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
  
  return (
    <div className="container">
        <Row className="mb-10" justify={"space-between"}>
            <Typography.Title level={4} className="page-title">
                Add business
            </Typography.Title>
        </Row> 
        <Row gutter={8} justify={"space-between"} align={"middle"}>
                <Col>
                  <span className="cursor-pointer mb-10" onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined /> Back to Business
                  </span>
                </Col>
        </Row>
        <br />
        <br />
        <Card
        title={
            <>
                <Typography.Title level={5} className="page-title">
                 Complete the following 
                </Typography.Title>
            </>
        }
        >
            <div className="profile-cont">

                    {fetchingProduct && (
                            <Row className="mb-2" justify={"center"}>
                                <Spin />
                            </Row>
                            )}
                    <Form
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            layout="vertical"
                            ref={formRef}
                            form={form}
                            onFinishFailed={onFinishFailed}
                            
                            //disabled={componentDisabled}
                            style={{ maxWidth: 960 }}
                        >

                        <Row gutter={24}>
                              <Col span={24}>
                                    <Typography.Title level={5} className="form-title">
                                        Name
                                    </Typography.Title>

                                    <Row gutter={8}>
                                        <Col span={12}>
                                            <Form.Item 
                                                label="Business Name"
                                                name="name" 
                                                rules={[{ 
                                                    required: true,
                                                    message: 'Please input your business name!' }]}
                                                >
                                                    <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>        
                        </Row>

                        <CompulsoryForm/> 

                        <Row gutter={24}>
                              <Col span={24}>
                                    <Typography.Title level={5} className="form-title">
                                        Consent
                                    </Typography.Title>

                                    <Row gutter={8}>
                                        <Col span={20}>
                                            <Form.Item 
                                                label="Consent"
                                                name="consent" 
                                                rules={[{ 
                                                    required: true,
                                                    message: 'Please input your consent!' }]}
                                                >
                                                     <TextArea rows={8} placeholder="consent" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>        
                        </Row>
                        <br/>
                        <br/>

                        <div className='optional-section'>
                            <OptionalForm
                                type="Endorser"
                            />

                            <OptionalForm
                                type="Employment"
                            />

                            <OptionalForm
                                type="Identity"
                            />

                        </div>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                className="keza-btn-primary"
                                type="primary"
                                onClick={onFinish}
                                loading={isLoading}
                            >
                                {editMode ? "Update" : "Create"}
                            </Button>
                        </Form.Item>

                    </Form>

                </div>
        </Card>
            
    </div>
  )
}

export default CreateBusiness