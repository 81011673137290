import axios from "axios";
import { showError } from "../helpers";
import { getUserToken } from "../utilities/storage";
import LogOut from "../utilities/LogOut";
import { Product } from "../types/product";
export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3050/api/v1"
});

api.interceptors.response.use(
  value => Promise.resolve(value),
  error => {
    showError(error);
    if (error.reponse.status === 403) {
      LogOut();
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((req: any) => {
  const token = getUserToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

export const searchProductList = (searchValue: string, type: string) => {
  return api.get(
    `/admin/product/external/search?text=${searchValue}&type=${type}`
  );
};
export const searchGsmArena = (data: { searchValue: string; type: string }) => {
  return api.get(
    `/admin/product/external/search?text=${data.searchValue}&type=${data.type}`
  );
};
export const getProductDetailFromExternalSearch = (
  docId: string,
  type?: string,
  productImageUrl = ""
) => {
  return api.get(
    `/admin/product/external/get-detail?docId=${docId}&type=${type}&productImageUrl=${productImageUrl}`
  );
};

export const createProduct = (payload: Product) => {
  return api.post(`/admin/product/create`, payload);
};
export const uploadImage = (payload: FormData) => {
  return api.post(`/upload/devices/image`, payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const getDetailsFromExternalSearch = (data: {
  docId: string;
  type?: string;
  productImageUrl: string;
}) => {
  return api.get(
    `/admin/product/external/get-detail?docId=${data.docId}&type=${
      data.type
    }&productImageUrl=${data.productImageUrl ?? ""}`
  );
};
