import { Product } from "../../../types/product";

export function validateProduct(product: Product):{valid:boolean, message?:string} {
  const { image, pricing,name } = product;
  if (!product) return { valid: false, message: "Please upload a product" };
  if (!name) return { valid: false, message: "Please enter product name" };
  if (image.length < 1)
    return { valid: false, message: "Please upload an image" };
  if (pricing.length < 1)
    return { valid: false, message: "Please enter a valid price" };

  return { valid: true, message: "Product is valid" };
}
