import { Button, Card, Col, Row, Table, Spin, Select } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getDashboardAnalytics } from "../../api/dashboard";
import { getTag } from "../../utilities/sanitizer";
import { formatDate } from "../../utilities/date-formatter";
import ordersIcon from "../../assets/orders.svg";
import pendingOrder from "../../assets/pendingOrder.svg";
import processedOrder from "../../assets/processedOrder.svg";
import { Link } from "react-router-dom";
import {
  getConfirmPayment,
  getOrders,
  sendPaymentLinkToCustomer
} from "../../api/order";
import { FC } from "react";
import { showError, showMessage } from "../../helpers";

interface IProps {
  IconPath?: string;
  title: string;
  value?: string;
  loading?: boolean;
  span?: number;
  Data: String[];
}

const CounterBox: FC<IProps> = ({
  IconPath,
  title,
  value,
  loading,
  span,
  Data
}) => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  return (
    <Col span={span || 6}>
      <Card className="counterbox flex">
        <div className="counterbox-top">
          <Row>
            {IconPath && (
              <img
                src={IconPath}
                alt="application"
                className="box-img"
                style={{ width: 22 }}
              />
            )}
            <span className="text-top">{title}</span>
          </Row>
        </div>

        <div className="counterbox-mid">
          <Row align={"middle"} justify={"center"}>
            <span className="text-center">
              {loading ? <Spin /> : value || 0}
            </span>

            <Select
              defaultValue=""
              className="select-filter"
              style={{ width: 90 }}
              onChange={handleChange}
              options={[
                Data?.map((data: any) => {
                  return { value: data, label: data };
                })
              ]}
              size="small"
            />
          </Row>
        </div>

        <div className="counterbox-end flex">
          <span className="text-end">
            {" "}
            {value || 0} / {value || 0}
          </span>
        </div>
      </Card>
    </Col>
  );
};

const Orders = () => {
  const [loading, setLoading] = useState(false);
  const { isLoading, data } = useQuery({
    queryKey: ["dashboard_analytics"],
    queryFn: async () => {
      const response = await getDashboardAnalytics();

      return response.data.data;
    }
  });
  const userResponse = useQuery({
    queryKey: ["orders"],
    queryFn: async () => {
      const response = await getOrders();

      return response.data.data;
    }
  });
  const length = userResponse.data?.length || 0;
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: getConfirmPayment,
    mutationKey: ["confirm_payment"],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    }
  });

  const hanldeConfirmPayment = useCallback(
    (paymentReference: string) => {
      mutation.mutate(paymentReference);
    },
    [mutation]
  );
  const sendPaymentLink = useCallback(async (orderId: string) => {
    try {
      if (loading) return;
      setLoading(true);
      await sendPaymentLinkToCustomer(orderId);
      setLoading(false);
      showMessage(
        "Operation successful",
        "Payment Mailed to customer",
        "success"
      );
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  }, []);
  const columns = useMemo(
    () => [
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        render: (record: any, data: any) => (
          <div className="table-info">
            <span className="table-name">{data?.name}</span>
            <span className="table-mail">{data?.email}</span>
          </div>
        )
      },
      {
        title: "Device",
        dataIndex: "productId",
        key: "productId",
        render: (record: any) => <>{record?.name}</>
      },
      {
        title: "Details",
        dataIndex: "details",
        key: "details",
        render: (record: any) => <>{record?.details}</>
      },
      {
        title: "Payment Status",
        dataIndex: "isConfirmed",
        key: "isConfirmed",
        render: (record: any) => <>{getTag(record ? "Confirmed" : "Pending")}</>
      },
      {
        title: "Payment type",
        dataIndex: "paymentType",
        key: "paymentType",
        render: (record: any) => <>{record}</>
      },
      {
        title: "Order Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record: any) => <>{formatDate(record)}</>
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: `${new Date().getTime()}`,
        width: "20%",
        render: (record: any, data: any) => (
          <Row gutter={[8, 0]}>
            <Col>
              <Button
                onClick={() => sendPaymentLink(data?._id)}
                className="mb-2 table-btn"
                disabled={data.isConfirmed}
              >
                Request order
              </Button>
            </Col>
            <Col>
              <Link to={`${record}`}>
                <Button>View</Button>
              </Link>
            </Col>
          </Row>
        )
      }
    ],
    []
  );

  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"]
    }),
    []
  );
  const handleChange = (value: string) => {};
  return (
    <div className="container">
      <Row>
        <Col span={24}>
          <Col>
            <Row gutter={8} justify={"space-between"} align={"middle"}>
              <div>
                <h2 className="page-title">Orders</h2>
              </div>

              <div>
                <Select
                  defaultValue="This month"
                  style={{ width: 100 }}
                  onChange={handleChange}
                  options={[
                    { value: "thisMonth", label: "This month" },
                    { value: "", label: "" },
                    { value: "", label: "" }
                  ]}
                />
              </div>
            </Row>
          </Col>
          <Row gutter={[16, 16]}>
            <CounterBox
              loading={isLoading}
              IconPath={ordersIcon}
              title="Total Orders"
              value={data?.orderCount?.totalOrders}
              Data={["All", "Pending", "Approved", "Declined"]}
            />
            <CounterBox
              loading={isLoading}
              IconPath={ordersIcon}
              title="Fulfilled Orders"
              value={data?.orderCount?.pendingPaymentConfirmation}
              Data={["All", "Pending", "Approved", "Declined"]}
            />
            <CounterBox
              loading={isLoading}
              IconPath={pendingOrder}
              title="Pending Orders"
              value={data?.orderCount?.paymentConfirmed}
              Data={["All", "Pending", "Approved", "Declined"]}
            />

            <CounterBox
              loading={isLoading}
              IconPath={processedOrder}
              title="Completed Orders"
              value={data?.orderCount?.delivered}
              Data={["All", "Pending", "Approved", "Declined"]}
            />
          </Row>
        </Col>
      </Row>

      <br />
      <br />
      <Card
        title={
          <Row className="mb-3">
            <span className="table-title">
              Showing {length / (length / 10) || 0} of {length} results for{" "}
            </span>
          </Row>
        }
      >
        <Table
          className="table"
          loading={userResponse.isLoading || loading}
          dataSource={userResponse.data || []}
          columns={columns}
          pagination={pagination}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};

export default Orders;
