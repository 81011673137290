import { showError, showMessage } from "../helpers";

export async function copyToClipboard(text: string) {
  if (window.navigator) {
    try {
      await window.navigator.clipboard.writeText(text);
      showMessage("Copied","Copied to Clipboard!", "success");
    } catch (err) {
      showError("An Error occurred",);
    }
  }
}