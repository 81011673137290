import axios from "axios";
import { showError } from "../helpers";
import { getUserToken } from "../utilities/storage";
import LogOut from '../utilities/LogOut'
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3050/api/v1",
});

api.interceptors.response.use(
  (value) => Promise.resolve(value),
  (error) => {
    showError(error);
    if(error.repons.status === 403) {
      LogOut()
    }
    if(error.repons.status === 401) {
      LogOut()
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((req: any) => {
  const token = getUserToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

export const getOrders = () => {
  return api.get("/admin/orders");
};

export const getConfirmPayment = (txRef: string) => {
  return api.get(`/admin/order/${txRef}/confirm-paymnet`);
};

export const sendPaymentLinkToCustomer = (orderId: string) => {
  return api.get(`/admin/order/${orderId}/send-paymnet-link`);
};
