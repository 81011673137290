import { FC, useState } from "react";
import { Col, Modal, Row, Popconfirm, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { showError, showMessage } from "../../../helpers";
import { deleteProductById } from "../../../api/business";
import UpdateDeviceModal from "./UpdateDeviceModal";
import { numberWithCommas } from "../../../utilities/number-formatter";

const ShelfPhoneCard: FC<{ phone: any; bussiness: any; reload: any }> = ({
  phone,
  bussiness,
  reload
}) => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const deleteProduct = async (id: any) => {
    try {
      if (loading) return;
      setLoading(true);
      await deleteProductById(id);

      setLoading(false);
      reload.mutate();
      showMessage("Operation successful", "Phone removed", "success");
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  const confirm = (id: any) => {
    deleteProduct(id);
  };
  const cancel = () => {
    message.error("device not removed");
  };

  const minPrice = Math.min(...phone?.prices.map((item:any)=>Number(item.price)))  
  return (
    <div>
      <div className="pcard flex">
        <Col
          className="flex"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "90%"
          }}
        >
          <>
            <Popconfirm
              title="Remove this device from this business"
              onConfirm={() => confirm(phone._id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined style={{ fontSize: "16px" }} />
            </Popconfirm>
          </>

          <>
            <EditOutlined style={{ fontSize: "16px" }} onClick={showModal} />
            <Modal
              open={open}
              title={
                <Row gutter={16}>
                  <Col span={24}>
                    <p>
                      {" "}
                      Update{" "}
                      <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                        {" "}
                        {phone?.product?.name}{" "}
                      </span>{" "}
                      in {bussiness.name}
                    </p>
                  </Col>
                  <Col span={24}>
                    <h6>Select the device details below</h6>
                  </Col>
                </Row>
              }
              onOk={handleOk}
              onCancel={handleCancel}
              footer={""}
              style={{ maxWidth: 1024 }}
            >
              <UpdateDeviceModal
                device={{
                  pricing: phone?.prices,
                  condition: phone?.product?.condition,
                  name: phone?.product?.name,
                  _id: phone?.product?._id,
                }}
                vendor={bussiness}
                reload={reload}
              />
            </Modal>
          </>
        </Col>
        <Col>
          <img
            src={phone?.product?.image[0]}
            alt="phone"
            className="pcard-img"
          />
        </Col>
        <Col className="pcard-info flex">
          <p style={{ fontSize: "16px" }}>{phone?.product?.name}</p>
          <span>{phone?.product?.condition}</span>
        </Col>
        <Col className="pcard-info flex">
          <p style={{ fontSize: "16px",fontWeight:'bold' }}>{numberWithCommas(minPrice)}</p>
        </Col>
      </div>
    </div>
  );
};

export default ShelfPhoneCard;
