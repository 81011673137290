import { Button, Card, Input, Row, Table, Col, message, Popconfirm } from "antd";
import { Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getAllApplications } from "../../api/deviceapplication";
import { formatDateTime } from "../../utilities/date-formatter";
import { getTag } from "../../utilities/sanitizer";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import csvDownload from "json-to-csv-export";
import { deleteApplications } from "../../api/application";
import { showError, showMessage } from "../../helpers";

const DeviceApplication = () => {
  const [loading, setLoading] = useState(false);
  const applicationResponse = useQuery({
    queryKey: ["device_appplications"],
    queryFn: async () => {
      const response = await getAllApplications();

      return response.data;
    }
  });
  const deleteApplication = async (application_id: string) => {
    try {
      if (loading) return;
      setLoading(true);
      await deleteApplications(application_id);

      setLoading(false);

      showMessage("Operation successful", "Application Deleted", "success");
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };
  const confirm = (application_id: any) => {
    deleteApplication(application_id);
  };
  const cancel = () => {
    message.error("Application not deleted");
  };
  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "_id",
        key: "_id"
      },
      {
        title: "Applicant's First name",
        dataIndex: "owner",
        key: "owner",
        render: (record: any) => {
          return <>{record?.firstName}</>;
        }
      },
      {
        title: "Applicant's Last name",
        dataIndex: "owner",
        key: "owner2",
        render: (record: any) => {
          return <>{record?.lastName}</>;
        }
      },
      {
        title: "Product",
        dataIndex: "device",
        key: "device",
        render: (record: any) => {
          return <>{record?.name}</>;
        }
      },

      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: record => formatDateTime(record)
      },
      // {
      //   title: "Approval Status",
      //   dataIndex: "isApproved",
      //   key: "isApproved",
      //   render: (record) => getTag(record ? "approved" : "pending"),
      // },
      {
        title: "Approved",
        dataIndex: "isApproved",
        key: "isApproved",
        render: record => getTag(record ? "approved" : "pending")
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: "Action",
        render: (record:any, data:any) => (
          <Row gutter={[8, 0]}>
            <Col>
              <Link to={`${record}`}>
                <Button>View</Button>
              </Link>
            </Col>

            {data.status !== "DELETED" && (
              <Col>
                  <Popconfirm
                  title={`Delete ${data.owner?.firstName || ""} ${data.owner?.lastName|| ""}'s application`}
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button>
                    Delete
                  </Button>
                </Popconfirm>
              </Col>
            )}
          </Row>
        )
      }
    ],
    []
  );
  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"]
    }),
    []
  );
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    setApplications(applicationResponse.data);
  }, [applicationResponse]);

  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.trim().toLowerCase();

    setSearchValue(searchValue);
  };
  const handleFilter = useCallback(() => {
    if (searchValue.trim() === "")
      return setApplications(applicationResponse.data);

    const filtered = applicationResponse.data.filter((item: any) => {
      return (
        item.owner?.firstName.trim().toLowerCase().includes(searchValue) ||
        item.owner?.lastName.trim().toLowerCase().includes(searchValue) ||
        item._id.trim().toLowerCase().includes(searchValue)
      );
    });

    setApplications(filtered);
  }, [searchValue, applicationResponse.data]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  const [sortValue, setSortValue] = useState("");

  const handleSelect = (value: string) => {
    const sortValue = value;

    setSortValue(sortValue);
  };
  const handleSort = useCallback(() => {
    if (sortValue === "") {
      return setApplications(applicationResponse.data);
    }

    const sorted = applicationResponse.data.filter((item: any) => {
      return item.status?.includes(sortValue);
    });

    setApplications(sorted);
  }, [sortValue, applicationResponse.data]);
  useEffect(() => {
    handleSort();
  }, [handleSort]);

  const Data =
    applications?.map((data: any) => {
      return {
        Id: data?._id,
        firstName: data.owner?.firstName,
        lastName: data.owner?.lastName,
        product: data.device?.name,
        price: data.device?.price,
        deviceColor: data?.deviceColor,
        date: formatDateTime(data?.createdAt),
        disbursementStatus: data?.disbursementStatus,
        isApproved: data?.isApproved,
        isDownPaymentMade: data?.isDownPaymentMade,
        isPaymentMandateActive: data?.isPaymentMandateActive,
        passedCreditAndProfileCheck: data?.passedCreditAndProfileCheck,
        percentageMultiplier: data?.percentageMultiplier,
        status: data?.status,
        tenure: data.meta?.duration
      };
    }) || [];
  const dataToConvert = {
    data: Data,
    filename: "Keza_device_applications.csv",
    delimiter: ",",
    headers: [
      "ID",
      "First Name",
      "Last Name",
      "Product",
      "Price",
      "Colour",
      "Date",
      "Disbursemet status",
      "Approval",
      "Down Payment",
      "Payment Mandate",
      "Credit Check",
      "Multiplier",
      "Status",
      "Tenure"
    ]
  };

  return (
    <div className="container">
      <Card
        title="Starlink Applications"
        extra={
          <div className="flex">
            <Select
              defaultValue="Sort"
              style={{ width: 120 }}
              onChange={handleSelect}
              options={[
                { value: "", label: "ALL" },
                { value: "PENDING", label: "PENDING" },
                { value: "DECLINED", label: "DECLINED" },
                { value: "APPROVED", label: "APPROVED" }
              ]}
            />
            <Input
              prefix={<SearchOutlined />}
              placeholder="search by ID, applicant's name"
              onChange={handleChange}
            />
          </div>
        }
      >
        <br />
        <Row className="mb-3">
          <button
            onClick={() => csvDownload(dataToConvert)}
            style={{
              //pass other props, like styles
              backgroundColor: "#aa4428",
              borderRadius: "6px",
              display: "inline-block",
              cursor: "pointer",
              color: "#ffffff",
              fontSize: "15px",
              fontWeight: "bold",
              padding: "6px 24px",
              textDecoration: "none",
              border: "none"
            }}
          >
            Download Applications
          </button>
        </Row>
        <br />
        <Table
          className="mt-3"
          loading={applicationResponse.isLoading}
          dataSource={applications || []}
          columns={columns}
          pagination={pagination}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};

export default DeviceApplication;
