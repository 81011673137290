import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { loginApi } from "../../api/auth";
import KezaLogo from "../../assets/kezalogo.svg";
import { showError } from "../../helpers";
import { UserPofileState, UserTokenState } from "../../resources/user.resource";
import "./login.css";
// email super.admin@kezaafrica.com
// password hello@kezaafrica.com@1256

const Login = () => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [, setProfileValue] = useRecoilState(UserPofileState);
  const [, setUserTokenValue] = useRecoilState(UserTokenState);
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const response = await loginApi(values.email, values.password);
      setLoading(false);
      const { token, ...rest } = response.data;

      setUserTokenValue(token);
      setProfileValue(rest);
      
    } catch (error: any) {
      setLoading(false);
      showError(error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="login-form-wrapper">
            <div className="d-flex justify-content-center mb-3">
              <img alt="keza" src={KezaLogo} />
            </div>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout={"vertical"}
              form={form}
            >
              <Form.Item
                required
                rules={[
                  { required: true, message: "Please input your email!" }
                ]}
                label="Email Address"
                name={"email"}
              >
                <Input type="email" placeholder="john.doe@kezaafrica.com" />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please input your passowrd!" }
                ]}
                required
                label="Password"
                name={"password"}
              >
                <Input.Password placeholder="password" />
              </Form.Item>
              <br />
              <Form.Item>
                <div className="d-flex justify-content-center">
                  <Button
                    htmlType="submit"
                    className="keza-btn-primary"
                    type="primary"
                    loading={isLoading}
                  >
                    Sign in to Dashboard
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
