import { Empty, Table } from "antd";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getUserActivities } from "../../../api/user";
import { getTag } from "../../../utilities/sanitizer";

const Activity = () => {
  const params = useParams();
  const { isLoading, error, data } = useQuery({
    queryKey: ["activitiy", params.id],
    queryFn: async () => {
      const response = await getUserActivities(params.id as string);
      return response.data.data;
    }
  });

  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"]
    }),
    []
  );
  const columns = useMemo(
    () => [
      {
        title: "Title",
        dataIndex: "title",
        key: "title"
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description"
      },
      {
        title: "Status",
        dataIndex: "isCompleted",
        key: "isCompleted",
        render: (record: any) => <>{getTag(record ? "completed" : "pending")}</>
      }
    ],
    []
  );
  if (error) return <Empty />;

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Table
            columns={columns}
            loading={isLoading}
            locale={{ emptyText: <Empty /> }}
            dataSource={data || []}
            pagination={pagination}
          />
        </div>
      </div>
    </div>
  );
};

export default Activity;
