import "./dashboard.css";
import Meta from "antd/lib/card/Meta";
import { useRecoilValue } from "recoil";
import hey from "../../assets/hey.svg";
import { UserPofileState } from "../../resources/user.resource";
import { useMutation, useQuery } from "react-query";
import { getDashboardAnalytics } from "../../api/dashboard";
import Chart from "chart.js/auto";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChartData } from "chart.js";
import { Card, Col, DatePicker, Row, Space, Spin } from "antd";
import { IDashboardAnalytics, ShortMonth } from "@app-interfaces";
import { RANGE_PRESETS } from "../../utilities/date-formatter";
import moment from "moment";

const shortMonthsinYear: ShortMonth[] = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const Dashboard = () => {
  const salesChartRef = useRef() as unknown as React.MutableRefObject<
    Chart<"line", any[], any>
  >;
  const customerChartRef = useRef() as unknown as React.MutableRefObject<
    Chart<"pie", any[], any>
  >;
  const salesCanvasRef =
    useRef() as unknown as React.MutableRefObject<HTMLCanvasElement>;
  const usersCanvasRef =
    useRef() as unknown as React.MutableRefObject<HTMLCanvasElement>;

  const isMounted = useRef(false);

  const [appData, setAppData] = useState<IDashboardAnalytics | null>();
  const { isLoading } = useQuery({
    queryKey: ["dashboard_analytics"],
    queryFn: async () => {
      const response = await getDashboardAnalytics();
      setAppData(response.data.data);
      isMounted.current = true;
      return response.data.data as IDashboardAnalytics;
    }
  });
  const profile = useRecoilValue(UserPofileState);

  const [dateRange, setDateRange] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
  }>({ endDate: undefined, startDate: undefined });

  const getGradient = useCallback(
    (ctx: CanvasRenderingContext2D, chartArea: any) => {
      let width = 0,
        height = 0,
        gradient!: CanvasGradient;

      const chartWidth = chartArea.right - chartArea.left;
      const chartHeight = chartArea.bottom - chartArea.top;
      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(
          0,
          chartArea.bottom,
          0,
          chartArea.top
        );
        gradient.addColorStop(0, "#aa44287d");
        gradient.addColorStop(1, "#aa44287d");
      }

      return gradient;
    },
    []
  );

  const filterData = useMutation({
    mutationFn: async (input: any) => {
      const response = await getDashboardAnalytics(input);
      setAppData(response.data.data);
      return response.data.data as IDashboardAnalytics;
    }
  });

  const getBackgroundGradient = useCallback(
    (ctx: CanvasRenderingContext2D, chartArea: any) => {
      let width = 0,
        height = 0,
        gradient!: CanvasGradient;

      const chartWidth = chartArea.right - chartArea.left;
      const chartHeight = chartArea.bottom - chartArea.top;
      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;

        gradient = ctx.createLinearGradient(
          0,
          chartArea.bottom,
          width,
          chartArea.top
        );
        gradient.addColorStop(0, "#aa44287d");
        gradient.addColorStop(1, "#aa442833");
      }

      return gradient;
    },
    []
  );

  useEffect(() => {
    if (appData && salesChartRef.current && customerChartRef.current) {
      salesChartRef.current.data.datasets.forEach(dataset => {
        dataset.data = [
          appData?.applicationCountByMonth.find(item => item.month === "Jan")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "Feb")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "Mar")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "Apr")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "May")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "Jun")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "Jul")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "Aug")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "Sep")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "Oct")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "Nov")
            ?.totalCount || 0,
          appData?.applicationCountByMonth.find(item => item.month === "Dec")
            ?.totalCount || 0
        ];
      });

      customerChartRef.current.data.datasets.forEach(dataset => {
        dataset.data = [
          appData?.userCount.pending || 0,
          appData?.userCount.approved || 1
        ];
      });

      salesChartRef.current.update();

      customerChartRef.current.update();
    }
  }, [appData, salesChartRef, customerChartRef]);

  const generateSalesChart = useCallback(() => {
    if (!salesCanvasRef.current) return;
    const ctx = new Chart(salesCanvasRef.current, {
      type: "line",
      data: {
        labels: shortMonthsinYear,
        datasets: [
          {
            label: "Sales Performance",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            backgroundColor: context => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return;
              }
              return getBackgroundGradient(ctx, chartArea);
            },
            borderColor: function (context) {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return;
              }
              return getGradient(ctx, chartArea);
            }
          }
        ]
      },

      options: {
        responsive: true,
        backgroundColor: "#aa44287d",
        plugins: {
          legend: {
            display: false,

            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 14
              }
            }
          },
          tooltip: {
            enabled: true
          }
        }
      }
    });

    salesChartRef.current = ctx;
  }, []);

  const generateCustomerChart = useCallback(() => {
    if (!usersCanvasRef.current) return;
    const ctx = new Chart(usersCanvasRef.current, {
      type: "pie",
      data: {
        labels: ["Unverified Users", "Verified Users"],
        datasets: [
          {
            data: [0, 1],
            backgroundColor: ["#E7E3DC", "#AA4428"],
            hoverOffset: 4
          }
        ]
      },

      options: {
        responsive: false,
        backgroundColor: "#aa44287d",
        plugins: {
          legend: {
            display: true,

            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 14
              }
            }
          },
          tooltip: {
            enabled: true
          }
        }
      }
    });

    customerChartRef.current = ctx;
  }, []);

  useEffect(() => {
    generateSalesChart();

    generateCustomerChart();
  }, [generateSalesChart, generateCustomerChart]);

  useEffect(() => {
    if (!isMounted.current) return;

    filterData.mutate(dateRange);
  }, [dateRange]);

  useEffect(() => {
    return () => {
      // console.log("called to destroy");

      salesChartRef.current.destroy();

      customerChartRef.current.destroy();
    };
  }, []);

  const handleOnRangeChange = (dates: any, dateStrings: [string, string]) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    setDateRange({ endDate, startDate });
  };

  return (
    <div className="dsb-overview container">
      <Meta
        title={
          <div className="flex">
            {`Welcome back ${profile?.firstName}`}
            <img src={hey} alt="hey" style={{ width: 20 }} />
          </div>
        }
        description="Take a look at Keza’s latest activity"
      />
      <br />

      <br />

      <Row className="mt-10" gutter={[16, 0]}>
        <Col md={14} sm={24}>
          <Card
            className="card-padding"
            title=""
            extra={<DatePicker.YearPicker />}
          >
            {(filterData.isLoading || isLoading) && (
              <Row justify={"center"} className="mb-19">
                <Spin />
              </Row>
            )}
            <Row justify={"center"}>
              <canvas ref={salesCanvasRef} id="myChart2">
                <p>Sales Performance</p>
              </canvas>
            </Row>
          </Card>
        </Col>
        <Col md={10} sm={24}>
          <Card
            extra={
              <DatePicker.RangePicker
                value={[
                  moment(dateRange.startDate || new Date()),
                  moment(dateRange.endDate || new Date())
                ]}
                ranges={RANGE_PRESETS}
                onChange={handleOnRangeChange}
              />
            }
            className="card-padding"
            title="Metrics"
          >
            {(filterData.isLoading || isLoading) && (
              <Row justify={"center"} className="mb-19">
                <Spin />
              </Row>
            )}
            <Row justify={"center"}>
              <canvas ref={usersCanvasRef} id="userChart">
                <p>Sales Performance</p>
              </canvas>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
