import { useMemo, useRef, useState } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import type { FormInstance } from "antd/es/form";
import { showError, showMessage } from "../../helpers";
import { createAdmin } from "../../api/admins";

const AdminForm: React.FC = () => {
  const formRef = useRef<FormInstance>(null);
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const roles = useMemo(
    () => [
      { value: "ops_manager", label: "Ops Manager", type: "OPS_MANAGER" },
      { value: "ops_level_1", label: "Ops Level 1", type: "OPS_LEVEL1" },
      { value: "ops_level_2", label: "Ops Level 2", type: "OPS_LEVEL2" },
      { value: "agent", label: "Agent", type: "AGENT" }
    ],
    []
  );

  const handleSubmit = async () => {
    try {
      if (isLoading) return;

      const payload = form.getFieldsValue();

      setLoading(true);

      const request = {
        status: false,
        email: payload.email?.target?.value,
        lastName: payload.lastName?.target?.value,
        password: payload.password?.target?.value,
        username: payload.username?.target?.value,
        firstName: payload.firstName?.target?.value,
        phoneNumber: payload.phoneNumber?.target?.value,
        role: roles.find(role => role.value === payload.role)?.type
      };

      await createAdmin(request);

      setLoading(false);
      showMessage(
        "Operation successful",
        "Admin created successfully",
        "success"
      );

      form.resetFields();
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showError(errorInfo);
  };

  return (
    <div className="profile-cont">
      <Form
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 16 }}
        layout="vertical"
        ref={formRef}
        form={form}
        onFinishFailed={onFinishFailed}
        style={{ maxWidth: 960 }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              valuePropName="firstName"
              rules={[
                { required: true, message: "Please input your First name!" }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              valuePropName="lastName"
              rules={[
                { required: true, message: "Please input your Last name!" }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Username"
              name="username"
              valuePropName="username"
              rules={[
                { required: true, message: "Please input your username!" }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              valuePropName="phoneNumber"
              rules={[
                { required: true, message: "Please input your phone number!" }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              valuePropName="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Role"
              name="role"
              valuePropName="role"
              rules={[{ required: true, message: "Please input your role!" }]}
            >
              <Select defaultValue="" style={{ width: 120 }} options={roles} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Password"
              name="password"
              valuePropName="password"
              rules={[
                { required: true, message: "Please input your password!" }
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              valuePropName="confirmPassword"
              rules={[
                { required: true, message: "Please confirm your password!" }
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Button onClick={handleSubmit} className="keza-btn-primary">
          Add
        </Button>
      </Form>
    </div>
  );
};

export default AdminForm;
