import axios from "axios";
import { showError } from "../helpers";
import LogOut from "../utilities/LogOut";
import { getUserToken } from "../utilities/storage";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3050/api/v1"
});

api.interceptors.response.use(
  value => Promise.resolve(value),
  error => {
    showError(error);
    if (error.response.status === 403) {
      LogOut();
    }
    if (error.response.status === 401) {
      LogOut();
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((req: any) => {
  const token = getUserToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});





export const getDashAnalyticsKeza = (dateRange?: {
  startDate: string;
  endDate: string;
}
) => {
  return api.get(
    `/admin/dashboard/analytics?startDate=${
      dateRange?.startDate || ""
    }&endDate=${dateRange?.endDate || ""}&userType=${"keza"}`
  );
};

export const getDashboardAnalytics = (dateRange?: {
  startDate: string;
  endDate: string;
}
) => {
  return api.get(
    `/admin/dashboard/analytics?startDate=${
      dateRange?.startDate || ""
    }&endDate=${dateRange?.endDate || ""}&userType=${"all"}`
  );
};
