import { Col, Form, Input, Row, Space, Typography, Button, InputNumber } from 'antd'
import { FC } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
    const CompulsoryForm :FC = () => {
    return (
                <div>
                        <Row gutter={8}>
                                    <Col span={24}>
                                            <Typography.Title level={5} className="form-title">
                                                Address
                                            </Typography.Title>
                                            <Row gutter={8}>
                                                    <Col span={8}>
                                                        <Form.Item 
                                                        label="Address" name="address"
                                                        rules={[{ required: true, message: 'Please input your address!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item 
                                                        label="City" name="city"
                                                        rules={[{ required: true, message: 'Please input your city!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item 
                                                            label="state" name="state" 
                                                            rules={[{ required: true, message: 'Please input your state!' }]}
                                                            >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item 
                                                        label="Country" name="country" 
                                                        rules={[{ required: true, message: 'Please input your country!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                            </Row>
                                    </Col>
                        </Row>

                        <Row gutter={8}>
                                    <Col span={24}>
                                            <Typography.Title level={5} className="form-title">
                                                Contact
                                            </Typography.Title>

                                            <Row gutter={8}>
                                                    <Col span={8}>
                                                        <Form.Item 
                                                        label="Phone Number" name="contact" 
                                                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item 
                                                        label="Registrtion Number" name="number" 
                                                        rules={[{ required: true, message: 'Please input your registration number!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item 
                                                        label="Logo Url" name="logoUrl" 
                                                        rules={[{ required: true, message: 'Please input your logo url!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                            </Row>
                                    </Col>
                        </Row>

                        <Row gutter={8}>
                                    <Col span={24}>
                                            <Typography.Title level={5} className="form-title">
                                                Branding
                                            </Typography.Title>
                                            <Row gutter={16}>
                                                    <Col span={10}>
                                                        <Form.Item 
                                                        label="Favicon Url" name="faviconUrl" 
                                                        rules={[{ required: true, message: 'Please input your fevicon url!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                            </Row>

                                            <Row gutter={16}>
                                                    <Col span={6}>
                                                        <Form.Item 
                                                        label="Primary Color" name="primaryColor" 
                                                        rules={[{ required: true, message: 'Please input your primary color!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item 
                                                        label="Secondary Color" name="secondaryColor"
                                                        rules={[{ required: true, message: 'Please input your secondary color!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item 
                                                            label="Text Color" name="textColor"
                                                            rules={[{ required: true, message: 'Please input your text color!' }]}
                                                            >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                            </Row>

                                            <Row gutter={16}>
                                                    <Col span={10}>
                                                        <Form.Item 
                                                        label="Banner Url" name="bannerUrl" 
                                                        rules={[{ required: true, message: 'Please input your banner url!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={10}>
                                                        <Form.Item 
                                                        label="Mobile Banner Url" name="mobileBannerUrl" 
                                                        rules={[{ required: true, message: 'Please input your mobile banner url!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                            </Row>

                                            <Row gutter={16}>
                                                            <Col span={10}>
                                                                <Form.Item 
                                                                label="Footer Background Color" name="footerBackgroundColor" 
                                                                rules={[{ required: true, message: 'Please input your footer background color!' }]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={10}>
                                                                <Form.Item 
                                                                label="Footer Text Color" name="footerTextColor"
                                                                rules={[{ required: true, message: 'Please input your footer text color!' }]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                            </Row>
                                    </Col>
                        </Row>

                        <Row gutter={8}>
                                    <Col span={24}>
                                            <Typography.Title level={5} className="form-title">
                                                Business Details
                                            </Typography.Title>
                                            <Row gutter={16}>
                                                    <Col span={10}>
                                                        <Form.Item 
                                                        label="Keza Home page url" name="kezahomepageUrl"
                                                        rules={[{ required: true, message: 'Please input your home page url!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={10}>
                                                        <Form.Item 
                                                        label="Repayment Plans" name="repaymentPlans"
                                                        rules={[{ required: true, message: 'Please input your repayment plans!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                            </Row>

                                            <Row gutter={16}>
                                                    <Col span={10}>
                                                        <Form.Item 
                                                        label="Down Payments" name="downPayments"
                                                        rules={[{ required: true, message: 'Please input your down payments!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item 
                                                        label="Interest" name="interest"
                                                        rules={[{ required: true, message: 'Please input your interest!' }]}
                                                        >
                                                            <InputNumber />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item 
                                                            label="Insurance" name="insurance"
                                                            rules={[{ required: true, message: 'Please input your insurance!' }]}
                                                            >
                                                            <InputNumber />
                                                        </Form.Item>
                                                    </Col>
                                            </Row>
                                    </Col>
                        </Row>
                        
                        <Row gutter={8}>
                                    <Col span={24}>
                                            <Typography.Title level={5} className="form-title">
                                                User Details
                                            </Typography.Title>

                                            <Form.List name="users"> 
                                            {(fields, { add, remove }) => (
                                                <>
                                                {fields.map(({ key, name,...restField }) => (
                                                    <Space key={key} style={{ display: 'block', marginBottom: 8 }} align="baseline">
                                                                    
                                                        <Row gutter={16}>
                                                                <Col span={10}>
                                                                    <Form.Item 
                                                                    {...restField} 
                                                                    label="First Name" name={[name,"firstName" ]}
                                                                    rules={[{ required: true, message: 'Please input your first name!' }]}
                                                                    >
                                                                        <Input />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={10}>
                                                                    <Form.Item
                                                                    {...restField}  
                                                                    label="Last Name" name={[name,"lastName" ]}
                                                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                                                    >
                                                                        <Input />
                                                                    </Form.Item>
                                                                </Col>
                                                        </Row>

                                                        <Row gutter={16}>
                                                                <Col span={6}>
                                                                        <Form.Item 
                                                                        {...restField}
                                                                            label="Phone Number" name={[name,"phoneNumber"]}
                                                                            rules={[{ required: true, message: 'Please input your phone number!' }]}
                                                                            >
                                                                                <Input />
                                                                        </Form.Item>
                                                                </Col>
                                                                <Col span={6}>
                                                                        <Form.Item
                                                                             {...restField}
                                                                            label="Email" name={[name,"email" ]}
                                                                            rules={[{ required: true, message: 'Please input your email!' }]}
                                                                            >
                                                                                <Input />
                                                                        </Form.Item>
                                                                </Col>
                                                                <Col span={6}>
                                                                        <Form.Item
                                                                             {...restField}
                                                                            label="Password" name={[name,"password" ]}
                                                                            rules={[{ required: true, message: 'Please input your password!' }]}
                                                                            >
                                                                                <Input />
                                                                        </Form.Item>
                                                                </Col>
                                                                <Col span={4}>
                                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                                </Col>
                                                        </Row>
                                                    </Space>
                                            ))}
                                                    <Form.Item>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                            Add field
                                                            </Button>
                                                    </Form.Item>
                                            </>
                                            )}
                                            </Form.List>
                                            
                                    </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col span={24}>
                                <Typography.Title level={5} className="form-title">
                                    FAQ
                                </Typography.Title>

                                    <Form.List name="faq">
                                                {(fields, { add, remove }) => (
                                                    <>
                                                    {fields.map(({ key, name, ...restField}) => (
                                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                            <Row gutter={16}>
                                                                    <Col span={8}>
                                                                        <Form.Item
                                                                        {...restField} 
                                                                        label="Question" name={[name, "question"]}
                                                                        rules={[{ required: true, message: 'Please input your question!' }]}
                                                                        >
                                                                            <Input />
                                                                            
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                        {...restField}
                                                                        label="Answer" name={[name, "answer" ]}
                                                                        rules={[{ required: true, message: 'Please input your answer!' }]}
                                                                        >
                                                                            <Input />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                                    </Col>
                                                            </Row>
                                                        </Space>
                                                    ))}
                                                        <Form.Item>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                            Add field
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                    </Form.List>
                            </Col>
                        </Row>
                </div>
    )
    }

export default CompulsoryForm