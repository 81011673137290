import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import VendorProfile from "./VendorProfile/VendorProfile";
import VendorApplications from "./VendorApplications/VendorApplications";
import VendorUsers from "./VendorUsers/VendorUsers";

const VendorDetails = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Row>
            <Col span={24}>
              <Row gutter={8} justify={"space-between"} align={"middle"}>
                <Col>
                  <span className="cursor-pointer" onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined /> Back to vendors
                  </span>
                </Col>
              </Row>
              <br />
              <br />

              <Tabs>
                <Tabs.TabPane tab="Vendor Details" key="item-1">
                  <VendorProfile />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Applications" key="item-2">
                  <VendorApplications />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Users" key="item-3">
                  <VendorUsers />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default VendorDetails;
