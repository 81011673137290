import { ArrowLeftOutlined } from "@ant-design/icons";
import {  Card, Col,  Row, Tabs } from "antd";
import OrderProfile from "./OrderProfile/OrderProfile"; 
import VendorProfile from "./VendorProfile/VendorProfile"; 
import { useNavigate, } from "react-router-dom";

const OrderDetails = () => {
    const navigate = useNavigate();
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Row>
            <Col span={24}>
            <Row gutter={8} justify={"space-between"} align={"middle"}>
                  
                 <Col>
                      <span className="cursor-pointer" onClick={() => navigate(-1)}>
                        <ArrowLeftOutlined /> Back to orders
                      </span>
                 </Col>
                 
              </Row>
              <br/>
              <br/>
              <Card>
                <Tabs>
                  <Tabs.TabPane tab="Order Details" key="item-1">
                     <OrderProfile/>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Vendors" key="item-2">
                    <VendorProfile  />
                  </Tabs.TabPane>
                  
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default OrderDetails