import {
  Button,
  Card,
  Col,
  Input,
  InputRef,
  Row,
  Table,
  Popover,
  Radio,
  DatePicker,
  Select,
  Form
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import userPlus from "../../assets/userPlus.svg";
import userIcon from "../../assets/userIcon.svg";
import userIcon2 from "../../assets/userIcon2.svg";
import filter from "../../assets/filter.svg";
import { useMutation, useQuery } from "react-query";
import { getDashAnalyticsKeza } from "../../api/dashboard";
import {
  exportUserDateRange,
  //getAllUsers,
  searchUserApiKez
} from "../../api/user";
import { getTag, sanitizeValues } from "../../utilities/sanitizer";
import { formatDate, RANGE_PRESETS } from "../../utilities/date-formatter";
import { Link } from "react-router-dom";
import FilterCounterBox from "../Dashboard/CounterBox/FilterCounterBox";
import { channels } from "../../constants/user.constant";
import { IUserSearch } from "@app-interfaces";
import moment from "moment";
import csvDownload from "json-to-csv-export";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { paginationData } from "../../types/pagination.inteface";

const KezaUsers = () => {
  /*const { isLoading, data } = useQuery({
    queryKey: ["user_dashboard_analytics"],
    queryFn: async () => {
      const response = await getDashboardAnalytics();
      return response.data.data;
    }
  });*/
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const Dashboardanalytics = useMutation({
    mutationFn: async (input: any) => {
      const response = await getDashAnalyticsKeza(input);
      setData(response.data.data);
      setIsLoading(false);
    }
  });
  const [users, setUsers] = useState([]);
  const isMounted = useRef(false);
  const [form] = Form.useForm();

  const searchUser = useMutation({
    mutationFn: async (input: IUserSearch) => {
      const response = await searchUserApiKez(input);
      setPaginations({ ...paginations, ...response.data.pagination });
      setUsers(response.data.content);
      //console.log(response.data.content)
      return response.data.content;
    }
  });

  const exportUsers = useMutation({
    mutationFn: async (input: IUserSearch) => {
      const response = await searchUserApiKez(input);

      return response.data.content;
    }
  });

  const [paginations, setPaginations] = useState<paginationData>({
    numberPages: 1,
    totalDocs: 10,
    query: {
      keyword: "",
      page: 1,
      size: 10
    }
  });

  const columns = useMemo(
    () => [
      {
        title: "Full name",
        dataIndex: "user",
        key: "user",
        render: (record: any, data: any) => (
          <p className="table-name">
            {data?.firstName} {data?.lastName}
          </p>
        )
      },

      {
        title: "Email address",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Phone number",
        dataIndex: "phone_number",
        key: "phone_number"
      },
      {
        title: "Profile status",
        dataIndex: "isApproved",
        key: "isApproved",
        render: (record: any) => <>{getTag(record ? "success" : "pending")}</>
      },
      {
        title: "Date onboarded",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record: any) => <>{formatDate(record)}</>
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: `${new Date().getTime()}`,
        render: record => (
          <Link to={`${record}`}>
            <Button>View</Button>
          </Link>
        )
      }
    ],
    []
  );

  useEffect(() => {
    const formValues = sanitizeValues(form.getFieldsValue()) as IUserSearch;

    searchUser.mutate({
      ...formValues,
      page: paginations.query.page,
      size: paginations.query.size,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      userType: "keza"
    });
  }, [paginations.query]);

  const onFinish = (values: IUserSearch) => {
    const formValues = sanitizeValues({ ...values });
    searchUser.mutate({
      ...formValues,
      page: paginations.query.page,
      size: paginations.query.size,
      userType: "keza"
    });
  };

  const handleFormReset = () => {
    form.resetFields();
    setDateRange({ endDate: undefined, startDate: undefined });
    searchUser.mutate({ page: 0, size: 10, userType: "keza" });
  };

  const UserFilterComponent = () => {
    return (
      <div>
        <Form
          onFinish={onFinish}
          layout="vertical"
          style={{ width: "400px" }}
          form={form}
        >
          <Form.Item rules={[{ required: false }]} label="User ID" name={"id"}>
            <Input placeholder="User ID" />
          </Form.Item>
          <Form.Item rules={[{ required: false }]} label="Name" name={"name"}>
            <Input placeholder="John Doe" />
          </Form.Item>
          <Form.Item rules={[{ required: false }]} label="Email" name={"email"}>
            <Input placeholder="user@email.com" />
          </Form.Item>
          <Form.Item
            rules={[{ required: false }]}
            label="Phone number"
            name={"phone_number"}
          >
            <Input placeholder="Phone" />
          </Form.Item>
          <Form.Item rules={[{ required: false }]} label="BVN" name={"bvn"}>
            <Input placeholder="BVN" />
          </Form.Item>
          <Form.Item label="Status" initialValue={"ALL"} name="status">
            <Radio.Group className="flex">
              <Radio value={"ALL"}>All</Radio>
              <Radio value={"APPROVED"}>Approved</Radio>
              <Radio value={"PENDING"}>Pending</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            initialValue={[]}
            label="Channels"
            name="channel"
            valuePropName="channel"
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Choose  channel"
              options={channels.map(item => ({ label: item, value: item }))}
            />
          </Form.Item>
          <Row justify={"center"} gutter={[16, 0]}>
            <Button
              htmlType="submit"
              className="keza-btn-primary"
              type="primary"
              loading={searchUser.isLoading}
            >
              Filter
            </Button>
            <Button onClick={handleFormReset}>Reset</Button>
          </Row>
        </Form>
      </div>
    );
  };

  const UsersExportComponent = () => {
    return (
      <div>
        <Form
          onFinish={values => {
            const payload = {
              page: Number(values.page),
              size: Number(values.size),
              userType: "keza"
            };

            exportUsers.mutate(payload, {
              onSuccess: data => {
                const dataToConvert = {
                  data: data
                    .map((user: any) => ({
                      id: user._id || "-",
                      firstName: user?.firstName || "-",
                      lastName: user?.lastName || "-",
                      email: user?.email || "-",
                      phone: user?.phone_number || "-",
                      status: user?.isApproved
                        ? "Approved"
                        : user?.isApproved === false
                        ? "Pending"
                        : "-",
                      joinedAt: new Date(user?.createdAt).toLocaleString()
                    }))
                    .sort((a: any, b: any) =>
                      values.sort === "Ascending"
                        ? new Date(a?.joinedAt).getTime() -
                          new Date(b?.joinedAt).getTime()
                        : new Date(b?.joinedAt).getTime() -
                          new Date(a?.joinedAt).getTime()
                    ),
                  filename: "Keza_users.csv",
                  delimiter: ",",
                  headers: [
                    "ID",
                    "First Name",
                    "Last Name",
                    "Email Address",
                    "Phone Number",
                    "Status",
                    "Joined At"
                  ]
                };
                csvDownload(dataToConvert);
              }
            });
          }}
          layout="vertical"
          style={{ width: "400px" }}
          form={form}
        >
          <Form.Item
            rules={[{ required: false }]}
            label="How many sets of users do you want to export"
            name={"size"}
          >
            <Input placeholder="10" max={500} type="number" />
          </Form.Item>
          <Form.Item rules={[{ required: false }]} label="Page" name={"page"}>
            <Input placeholder="1" type="number" />
          </Form.Item>
          <Form.Item
            label="Sorting Order"
            initialValue={"Descending"}
            name="sort"
          >
            <Radio.Group className="flex">
              <Radio value={"Descending"}>Descending</Radio>
              <Radio value={"Ascending"}>Ascending</Radio>
            </Radio.Group>
          </Form.Item>

          <Row justify={"center"} gutter={[16, 0]}>
            <Button
              htmlType="submit"
              className="keza-btn-primary"
              type="primary"
              loading={exportUsers.isLoading}
            >
              Export
            </Button>
          </Row>
        </Form>
      </div>
    );
  };

  const [dateRange, setDateRange] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
  }>({ endDate: undefined, startDate: undefined });

  const handleOnRangeChange = (dates: any, dateStrings: [string, string]) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    setDateRange({ endDate, startDate });
  };

  useEffect(() => {
    const formValues = sanitizeValues(form.getFieldsValue()) as IUserSearch;
    searchUser.mutate({
      ...formValues,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      page: paginations.query.page,
      size: paginations.query.size,
      userType: "keza"
    });
  }, [dateRange]);

  useEffect(() => {
    const formValues = sanitizeValues(form.getFieldsValue()) as IUserSearch;
    Dashboardanalytics.mutate({
      ...formValues,
      startDate: dateRange.startDate || "",
      endDate: dateRange.endDate || ""
    });
  }, [dateRange]);

  const handleExport = async () => {
    try {
      const response = await exportUserDateRange(
        dateRange.startDate || "",
        dateRange.endDate || "",
        "keza"
      );
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `user-${dateRange.startDate} - ${dateRange.endDate}.xlsx`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.log("ERROR DOWNLOADING USERS> ", error);
    }
  };

  return (
    <div className="container">
      <Row>
        <Col span={24}>
          <Col>
            <Row gutter={8} justify={"space-between"} align={"middle"}>
              <div>
                <h2 className="page-title">Keza Users</h2>
              </div>

              <Row gutter={[8, 8]}>
                <DatePicker.RangePicker
                  onChange={handleOnRangeChange}
                  ranges={RANGE_PRESETS}
                  value={[
                    moment(dateRange.startDate),
                    moment(dateRange.endDate)
                  ]}
                />
                <Button onClick={handleExport}>
                  <CloudDownloadOutlined />
                  Export
                </Button>
              </Row>
            </Row>
          </Col>

          <Row gutter={[16, 16]}>
            <FilterCounterBox
              loading={searchUser.isLoading}
              IconPath={userPlus}
              title="Total Users"
              value={`${data?.userCount?.total}`}
            />
            <FilterCounterBox
              loading={isLoading}
              IconPath={userIcon}
              title="Active Users"
              value={data?.userCount?.approved}
            />
            <FilterCounterBox
              loading={isLoading}
              IconPath={userIcon2}
              title="InActive Users"
              value={data?.userCount?.inActive}
            />
          </Row>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <br />
      <Card
        title={
          <Row className="mb-3">
            <span className="table-title">
              Showing {paginations.query.size || 0} of{" "}
              {paginations.totalDocs || 0} results for{" users"}
            </span>
          </Row>
        }
        extra={
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              gap: "10px"
            }}
          >
            <Popover
              placement="bottomRight"
              title={"Filter "}
              content={UserFilterComponent}
              trigger="click"
            >
              <Button className="flex filter-btn">
                Filter
                <img
                  src={filter}
                  style={{ width: "16px", marginRight: "5px" }}
                  alt="filter"
                  className="pl-3"
                />
              </Button>
            </Popover>
            <Popover
              placement="bottomRight"
              title={"Filter "}
              content={UsersExportComponent}
              trigger="click"
            >
              <Button className="flex filter-btn">Export as CSV</Button>
            </Popover>
          </div>
        }
      >
        <Table
          style={{ background: "#FFF7F4 !important" }}
          loading={searchUser.isLoading}
          dataSource={users || []}
          columns={columns}
          pagination={{
            pageSize: paginations.query.size,
            total: paginations.totalDocs,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "30", "40"],
            onChange(page, size) {
              setPaginations({
                ...paginations,
                query: { ...paginations.query, page, size }
              });
            },
            onShowSizeChange(page, size) {
              setPaginations({
                ...paginations,
                query: { ...paginations.query, page, size }
              });
            }
          }}
          scroll={{ x: 1000 }}
          className="table"
        />
      </Card>
    </div>
  );
};

export default KezaUsers;
