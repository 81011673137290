import {
  Route,
  Routes,
  Navigate,
  Outlet
} from "react-router-dom";
import {useRecoilValue } from "recoil";
import "./styles/App.css";
import "antd/dist/antd.css";
import Dashboard from "./pages/Dashboard/dashboard";
import DashboardHoc from "./components/DashHoc/DashboardHoc";
import Applications from "./pages/Applications/Application";
import ApplicationDetail from "./pages/ApplicationDetail/ApplicationDetail";
import Users from "./pages/Users/user";
import UserDetail from "./pages/UserDetail/UserDetail";
import Login from "./pages/Login/LoginPage";
import Orders from "./pages/orders/Orders";
import Products from "./pages/Products/Products";
import NewProducts from "./pages/NewProduct/NewProduct";
import Payments from "./pages/Payments/payments";
import PaymentDetails from "./pages/PaymentDetails/PaymentDetails";
import Disbursements from "./pages/Disbursements/Disbursments";
import WalletWithdraw from "./pages/WalletWithdaw/WalletWithdaw";
import DebitHistory from "./pages/DebitHistory/DebitHistory";
import FundApplication from "./pages/FundApplication/fundApplication";
import { UserTokenState } from "./resources/user.resource";
import OrderDetails from "./pages/orderDetails/orderDetails";
import ProductDetailOld from "./pages/ProductDetail/ProductDetailOld";
import Wallet from "./pages/wallets/Wallet";
import AdminCreate from "./pages/AdminCreate/AdminCreate";
import Vendors from "./pages/Vendors/Vendors";
import DeviceApplication from "./pages/DeviceApplication/DeviceApplication";
import DeviceApplicationDetails from "./pages/DeviceApplication/components/DeviceApplicationDetails";
import Settings from "./pages/Settings/Settings";
import Notfound from "./pages/notfound/notfound";
import VendorDetails from "./pages/VendorDetails/VendorDetails";
import { UserPathState } from "./resources/user.resource";
import Bussiness from "./pages/Bussiness/Bussiness";
import CreateBusiness from "./pages/Bussiness/CreateBusiness";
import BvnDetails from "./pages/BvnDetails/BvnDetails";
import AddProduct from "./pages/AddProduct/AddProduct";
import Devices from "./pages/Devices/Devices";
import BusinessApplication from "./pages/BusinessApplication/BusinessApplication";

const HomHoc = () => {
  const path = useRecoilValue(UserPathState);
  const isUserTokenAvailable = useRecoilValue(UserTokenState);
  if (isUserTokenAvailable && isUserTokenAvailable.trim() !== "")
    return <Navigate to={path || "/dashboard"} />;
  return (
    <>
      <Outlet />
    </>
  );
};
function App() {
  return (
    <Routes>
      <Route path="" element={<HomHoc />}>
        <Route index element={<Login />} />
      </Route>
      <Route path="/dashboard" element={<DashboardHoc />}>
        <Route index element={<Dashboard />} />
        <Route path="applications" element={<Applications />} />
        <Route path="applications/:id" element={<ApplicationDetail />} />
        <Route path="starlink/applications" element={<DeviceApplication />} />
        <Route
          path="starlink/applications/:id"
          element={<DeviceApplicationDetails />}
        />
        <Route
          path="applications/:id/mandate/payment-history"
          element={<DebitHistory />}
        />
        <Route path="applications/:id/fund" element={<FundApplication />} />
        <Route
          path="wallet/:accountNumber/withdraw"
          element={<WalletWithdraw />}
        />
        <Route path="products" element={<Products />} />
        <Route path="products/addProduct" element={<AddProduct />} />
        <Route path="product/:id" element={<ProductDetailOld />} />
        <Route path="product/:id/edit" element={<NewProducts />} />
        <Route path="users" element={<Users />} />
        <Route path="users/:id" element={<UserDetail />} />
        <Route path="orders" element={<Orders />} />
        <Route path="orders/:id" element={<OrderDetails />} />
        <Route path="payment" element={<Payments />} />
        <Route path="payment/:id" element={<PaymentDetails />} />
        <Route path="disbursements" element={<Disbursements />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="admin" element={<AdminCreate />} />
        <Route path="vendors" element={<Vendors />} />
        <Route path="vendors/:id" element={<VendorDetails />} />
        <Route path="business" element={<Bussiness />} />
        <Route path="business/create" element={<CreateBusiness />} />
        <Route path="business/applications" element={<BusinessApplication />} />
        <Route path="business/create/:id" element={<CreateBusiness />} />
        <Route path="business/devices/:id" element={<Devices />} />
        <Route path="bvn/details" element={<BvnDetails />} />
        <Route path="settings" element={<Settings />} />
      </Route>
      <Route path="*" element={<Notfound />} />
    </Routes>
  );
}
export default App;
