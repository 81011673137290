import { Empty, Row, Spin, Tabs,Typography } from "antd";
import MyProfile from "./MyProfile/MyProfile";
import LeaderBoard from "./LeaderBoard/LeaderBoard";
import Wallet from "./Wallet/Wallet";
import {useQuery} from "react-query";
//import {getSingleApplication} from "../../api/application";
import {useParams } from "react-router-dom";
import "./settings.css"
import { UserPofileState } from "../../resources/user.resource";
import { useRecoilValue } from "recoil";


const Settings = () => {
    const params = useParams();
    const profileResponse = useQuery({
        queryKey: ["application", params.id],
        queryFn: async () => {
          //const response = await getSingleApplication(params.id as string);
          //return response.data.data;
        }
      });
      const profile = useRecoilValue(UserPofileState);
      
  return (
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <Row className="" justify={"space-between"}>
                    <Typography.Title level={4}>Settings</Typography.Title>
                </Row>
                <br/>
                <div className="d-flex justify-content-between">
                  
                    <Tabs>
                      {/*<Tabs.TabPane tab="My Profile" key="item-1">
                        {profileResponse.isLoading ? (
                          <Spin />
                        ) : profileResponse.error ? (
                          <Empty />
                        ) : profileResponse.data ? (
                          <MyProfile
                            user={profileResponse.data || {}}
                          />
                        ) : (
                          <Empty />
                        )}
                      </Tabs.TabPane>*/}
                      <Tabs.TabPane tab="My Profile" key="item-1">
                        <MyProfile  user={profileResponse.data || {}}/>
                      </Tabs.TabPane>
                      {profile.role === "AGENT" ? (
                        <Tabs.TabPane tab="Wallet" key="item-2">
                          <Wallet  user={profileResponse.data || {}}/>
                        </Tabs.TabPane>
                      ):("")}
                      {/*<Tabs.TabPane tab="Wallet" key="item-2">
                        {profileResponse.isLoading ? (
                          <Spin />
                        ) : profileResponse.error ? (
                          <Empty />
                        ) : profileResponse.data ? (
                            <Wallet
                            user={profileResponse.data || {}}
                          />
                        ) : (
                          <Empty />
                        )}
                        </Tabs.TabPane>*/}
                      <Tabs.TabPane tab="Leaderboard" key="item-3">
                        <LeaderBoard />
                      </Tabs.TabPane>
                    </Tabs>
                    
                </div>

            </div>
        </div>
    </div>
  )
}

export default Settings