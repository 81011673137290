import { Button, Modal } from "antd";
import { FC, useState } from "react";
import { useMutation } from "react-query";
import {
  requestDeviceLoanAgreement,
  requestLoanAgreement
} from "../../../api/application";
import { showError, showMessage } from "../../../helpers";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import NovoModal from "../Modal/Modal";
//import { useNavigate } from "react-router-dom";
import UpdateDeviceImeiModal from "../Modal/UpdateDeviceImeiModal";
import ReviewReceit from "./ReviewReceit";
import SendReceipt from "./SendReceipt";


export interface ActionProps {
  applicationId: string;
  isDeviceApplication?: boolean;
  data?: any;
}

const Actions: FC<ActionProps> = ({
  applicationId,
  isDeviceApplication = false,
  data
}) => {
  //const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: isDeviceApplication
      ? requestDeviceLoanAgreement
      : requestLoanAgreement,
    onSuccess: data => {
      showMessage(
        data.data?.message || "Operation Successful",
        "Successfully requested for a loan agreement",
        "success"
      );
    },
    onError: error => {
      showError(error);
    }
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isLoanAgreementPresent = Boolean(data?.loanAgreementUrl);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Box>
            <Flex gap={"1rem"} mb={"1rem"}>
              <Button onClick={() => mutation.mutate(applicationId)}>
                Request Loan Agreement
              </Button>
              <Button onClick={onOpen}>Update Novo</Button>
              <NovoModal
                onClose={onClose}
                isOpen={isOpen}
                applicationId={applicationId}
              />

              <a
                href={isLoanAgreementPresent ? data?.loanAgreementUrl : "#"}
                target={isLoanAgreementPresent ? "_blank" : "_self"}
                rel="noreferrer"
              >
                <Button>
                  {isLoanAgreementPresent
                    ? "Download Loan Agreement"
                    : "No loan agreement"}
                </Button>
              </a>
              <>
                <Button onClick={showModal}>Update device imei</Button>
                <Modal
                  title="Update device imeis"
                  open={isModalOpen}
                  footer={""}
                  onCancel={handleCancel}
                >
                  <UpdateDeviceImeiModal
                    applicationId={applicationId}
                    close={handleCancel}
                  />
                </Modal>
              </>
              <ReviewReceit applicationId={applicationId}/>
              <SendReceipt applicationId={applicationId}/>
            </Flex>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Actions;
