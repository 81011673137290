import { Button, Empty, Table } from "antd";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getUserApplications } from "../../../api/user";
import { formatDate } from "../../../utilities/date-formatter";
import { getTag } from "../../../utilities/sanitizer";

const UserOrders = () => {
  const params = useParams() as any;

  const { isLoading, data, error } = useQuery({
    queryKey: ["user-applications", params.id],
    queryFn: async () => {
      const response = await getUserApplications(params.id);
      return response.data.data;
    },
  });
  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "Product",
        dataIndex: "product",
        key: "product",
        render: (record: any) => {
          return <>{record?.name}</>;
        },
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record) => formatDate(record),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (record) => getTag(record),
      },
      {
        title: "Verification",
        dataIndex: "status",
        key: "status",
        render: (record) => getTag(record),
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: "Action",
        render: (record) => (
          <Link to={`/dashboard/applications/${record}`}>
            <Button>View</Button>
          </Link>
        ),
      },
    ],
    []
  );
  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"],
    }),
    []
  );

  if (error) return <Empty />;
  return (
    <Table
      loading={isLoading}
      dataSource={data || []}
      columns={columns}
      pagination={pagination}
      locale={{ emptyText: <Empty /> }}
    />
  );
};

export default UserOrders;
