export function getPricingName(name: string) {
  if (!name) return;
  switch (name.toLowerCase()) {
    case "storageram":
      return "RAM";
    case "storagerom":
      return "ROM";
    case "pricenew":
      return "Brand new";
    case "priceold":
      return "Pre-owned";

    default:
      return null;
  }
}
