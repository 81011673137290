import { Box, Button, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { GsmArenaPhone } from "./ImportModal";
import { useRecoilState } from "recoil";
import { ImportedPhoneState } from "../../../resources/product.resource";
import {
  useGetDetailsFromeExternal,
  useUploadImage
} from "../../../api/mutations/product";
import { Product } from "../../../types/product";

export default function GsmArenaPhoneCard(
  phone: GsmArenaPhone & { category: string; onClose: any }
) {
  const [importedPhone, setImportedPhone] =
    useRecoilState<any>(ImportedPhoneState);
  const { getDetailsFromExternalSearch, result } = useGetDetailsFromeExternal();

  const getDetails = async () => {
    const res = await getDetailsFromExternalSearch({
      docId: phone.docId,
      type: phone.category,
      productImageUrl: phone.imageUrl
    });

    setImportedPhone({
      ...importedPhone,
      name: res.data.data.name,
      colors: [
        ...String(res.data.data.colors)
          .split(",")
          .map(item => item.trim())
      ],
      image: [res.data.data.imageUrl],
      component: {
        camera: res.data.data.camera,
        display: res.data.data.dimensions,
        chip: res.data.data.cpu,
        batteryName: res.data.data.battery
      }
    });
    phone.onClose();
  };
  return (
    <Stack
      py={"4"}
      alignItems={"center"}
      spacing={"1.5rem"}
      bg={"rgba(29,29,29,.05)"}
      h={"20rem"}
      w="15rem"
      borderRadius={"10px"}
    >
      <Box maxW={"8rem"}>
        <Image src={phone.imageUrl} alt="img" width={"100%"} />
      </Box>
      <Text fontSize="md" fontWeight="semibold">
        {phone.name}
      </Text>

      <Button
        key="submit"
        className="modal-btn"
        bg={"var(--keza-brown)"}
        color={"white"}
        _hover={{
          background: "var(--keza-brown)"
        }}
        isLoading={result.isLoading}
        // isDisabled={loading}
        onClick={getDetails}
      >
        Import
      </Button>
    </Stack>
  );
}
