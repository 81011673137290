import { FC } from "react"


const ApplicationDetails:FC<{section:any, details:any}> = ({section, details}) => {
  return (
    <div>

    </div>
  )
}

export default ApplicationDetails