import { FC, useEffect } from "react";
import { Col, Empty, Row, Spin, Typography } from "antd";
import SectionHeader from "../components/SectionTitle";
import { getTag } from "../../../utilities/sanitizer";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { getSingleVendor } from "../../../api/vendors";
import { useRecoilState } from "recoil";
import { VendorDetailState } from "../../../resources/vendor.resource";
import { showError } from "../../../helpers";

const VendorProfile: FC = () => {
  const [vendor, setVendor] = useRecoilState(VendorDetailState);
  const { id } = useParams();

  const vendorResponse = useMutation({
    mutationFn: getSingleVendor,
    onSuccess: data => {
      if (data?.data?.data) {
        setVendor(data?.data?.data);
      }
    },
    onError: error => {
      showError(error);
    }
  });

  useEffect(() => {
    if (id) {
      vendorResponse.mutate(id);
    }
    // eslint-disable-next-line
  }, []);

  return vendorResponse.isLoading ? (
    <Spin />
  ) : vendorResponse.error ? (
    <Empty />
  ) : (
    <div className="container profile-cont">
      <div className="row">
        <div className="col-md-12">
          <Row gutter={[32, 0]} justify={"space-between"}>
            <Col>
              <Col className="header-span">
                <span>Vendor ID:</span> {vendor?._id || ""}
              </Col>
              <Col className="header-span">
                <span>Status:</span>{" "}
                {getTag(vendor?.isActive ? "Approved" : "Pending")}
              </Col>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
          <Row>
            <Col span={12} className="flex info-items">
              <Row>
                <Col>
                  <SectionHeader title="Personal details" />
                </Col>
              </Row>

              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <span className="info-span">First name</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.firstName || ""}
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <span className="info-span">Last name</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.lastName || ""}
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <strong></strong>
                    <span className="info-span">Email address</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.email || ""}
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <span className="info-span">Phone number</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.phoneNumber || ""}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={12} className="flex info-items">
              <Row>
                <Col>
                  <SectionHeader title="Business Compliance" />
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <span className="info-span">Years in Business</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.business?.yearsInBusiness || ""}
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  {vendor.business?.photo ? (
                    <img
                      className="img-thumb"
                      src={vendor?.business?.photo}
                      alt={`${vendor?.business?.name} Shop Image`}
                    />
                  ) : (
                    <Empty />
                  )}
                  <Typography.Text>
                    <strong>Image of Vendor Shop</strong>
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="flex info-items">
              <Row gutter={[16, 0]}>
                <Col>
                  <SectionHeader title="Business Details" />
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <span className="info-span">Business Name</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.business?.name || ""}
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <span className="info-span">Business Number</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.business?.number || ""}
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <span className="info-span">Business Address</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.business?.address || ""}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={12} className="flex info-items">
              <Row>
                <Col>
                  <SectionHeader title="Financial Details" />
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <span className="info-span">Account Number</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.accountNumber || ""}
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <span className="info-span">Bank Name</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.bankName || ""}
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3" gutter={[16, 0]}>
                <Col>
                  <Typography.Text>
                    <span className="info-span">Account Name</span>
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className="info-des">
                    {vendor?.accountName || ""}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default VendorProfile;
