import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Spin
} from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  applicationFinancialDetail,
  getSingleApplication,
  requestFunding
} from "../../api/application";
import { showError, showMessage } from "../../helpers";
import { formatAsCurrency } from "../../utilities/number-formatter";
import { getTag } from "../../utilities/sanitizer";

const FundApplication = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const params = useParams();
  const applicationDetailResponse = useQuery({
    queryKey: ["application", params.id],
    queryFn: async () => {
      const response = await getSingleApplication(params.id as string);
      return response.data.data;
    }
  });
  const applicationFinancialResponse = useQuery({
    queryKey: ["application-fund-detail", params.id],
    queryFn: async () => {
      const response = await applicationFinancialDetail(params.id as string);
      return response.data;
    }
  });
  const getProductPrice = () => {
    const productPrice = applicationDetailResponse.data?.product?.pricing?.find(
      (item: any) =>
        item.storageROM === applicationDetailResponse.data?.phoneStorage
    );

    const amount =
      applicationDetailResponse.data?.phonCondition === "NEW"
        ? productPrice?.priceNew
        : productPrice?.priceOld;

    return amount;
  };

  const getInsurance = () => {
    return `${Math.ceil(0.05 * Number(getProductPrice()))}`;
  };

  const getTotalPrice = () => {
    return `${Math.ceil(1.05 * Number(getProductPrice()))}`;
  };

  useEffect(() => {
    form.setFieldsValue({
      amount: applicationFinancialResponse.data?.totalRepaymentAmount,
      tenure: applicationFinancialResponse.data?.tenure
    });
  }, [applicationFinancialResponse.data, form]);

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      if (loading) return;
      if (!applicationDetailResponse.data?.owner?.isApproved)
        return showMessage(
          "Operation failed",
          "Account is yet to be approved",
          "error"
        );

      if (
        !applicationDetailResponse.data?.owner?.accountNumber ||
        applicationDetailResponse.data?.owner?.accountNumber.trim() === ""
      )
        return showMessage(
          "Operation failed",
          "Account is yet to be provisioned on provider",
          "error"
        );

      setLoading(true);

      await requestFunding(params.id as string, values);

      showMessage(
        "Operation successful",
        "Account successfully funded",
        "success"
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Row className="mb-4">
        <Col span={24}>
          <span className="cursor-pointer" onClick={() => navigate(-1)}>
            <ArrowLeftOutlined /> Back
          </span>
        </Col>
      </Row>
      <div className="row mb-3">
        <div className="col-md-12">
          <Card title="Request Funding">
            <div className="row">
              <div className="col-md-6">
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout={"vertical"}
                  form={form}
                >
                  <Form.Item
                    required
                    rules={[
                      { required: true, message: "Please provide tenure!" }
                    ]}
                    label="Amount"
                    name={"amount"}
                  >
                    <Input type="number" placeholder="0" />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please provide tenure!" }
                    ]}
                    required
                    label="Tenure"
                    name={"tenure"}
                  >
                    <Input type="number" placeholder="0" />
                  </Form.Item>
                  <br />

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please select the Provider Bank!"
                      }
                    ]}
                    required
                    label="Select A Bank For Funding"
                    name={"provider"}
                  >
                    <Radio.Group
                      // defaultValue="vfd"
                      buttonStyle="outline"
                      optionType="button"
                    >
                      <Radio.Button value="vfd">VFD</Radio.Button>
                      <Radio.Button value="pennywise" disabled>
                        PennyWise
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item>
                    <div className="d-flex justify-content-center ">
                      <Button
                        htmlType="submit"
                        className="keza-btn-primary"
                        type="primary"
                        disabled={
                          applicationFinancialResponse?.isLoading || loading
                        }
                        loading={
                          applicationFinancialResponse?.isLoading || loading
                        }
                      >
                        Request Fund
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
              <div className="col-md-6">
                {applicationDetailResponse.isLoading ? (
                  <div className="text-center">
                    <Spin />
                  </div>
                ) : (
                  <>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <Card title="Applicant Details">
                          <table className="w-100 table table-striped">
                            <tbody>
                              <tr>
                                <td>First Name</td>
                                <td>
                                  {
                                    applicationDetailResponse.data?.owner
                                      ?.firstName
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Last Name</td>
                                <td>
                                  {
                                    applicationDetailResponse.data?.owner
                                      ?.lastName
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td>
                                  {applicationDetailResponse.data?.owner?.email}
                                </td>
                              </tr>
                              <tr>
                                <td>Phone Number</td>
                                <td>
                                  {
                                    applicationDetailResponse.data?.owner
                                      ?.phone_number
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Status</td>
                                <td>
                                  {getTag(
                                    applicationDetailResponse.data?.owner
                                      ?.isApproved
                                      ? "Approved"
                                      : "Pending"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Account Number</td>
                                <td>
                                  {
                                    applicationDetailResponse.data?.owner
                                      ?.accountNumber
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Bank </td>
                                <td>
                                  {
                                    applicationDetailResponse.data?.owner
                                      ?.accountProvider
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <Card title="Product Details">
                          <table className="w-100 table table-striped">
                            <tbody>
                              <tr>
                                <td>Product Name</td>
                                <td>
                                  {
                                    applicationDetailResponse.data?.product
                                      ?.name
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Storage</td>
                                <td>
                                  {applicationDetailResponse.data?.phoneStorage}
                                </td>
                              </tr>
                              <tr>
                                <td>Color</td>
                                <td>
                                  {applicationDetailResponse.data?.phoneColor}
                                </td>
                              </tr>
                              <tr>
                                <td>Condition</td>
                                <td>
                                  {getTag(
                                    applicationDetailResponse.data
                                      ?.phonCondition || ""
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <Card title="Financials">
                          <table className="w-100 table table-striped">
                            <tbody>
                              <tr>
                                <td>Price</td>
                                <td>{formatAsCurrency(getProductPrice())}</td>
                              </tr>
                              <tr>
                                <td>Tenure</td>
                                <td>
                                  {
                                    applicationDetailResponse.data?.meta
                                      ?.duration
                                  }{" "}
                                  Months
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FundApplication;
