import {
  DownOutlined,
  SearchOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from "@ant-design/icons";
import { Col, Input, Row, Card, Typography } from "antd";
import avatar from "../../assets/avatar.svg";
import { useContext } from "react";
import { getTodayDate, getDay } from "../../utilities/date-formatter";
import { useRecoilValue } from "recoil";
import { UserPofileState } from "../../resources/user.resource";
import { MenuContext } from "../../providers/AppProvider";

const Header = () => {
  const [menuOpen, setMenuOpen] = useContext(MenuContext);
  const profile = useRecoilValue(UserPofileState);
  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
    if (menuOpen === true) {
    }
  };
  return (
    <Row className="app-header">
      <div
        className="ham-cont"
        style={menuOpen ? { left: "180px" } : { left: "0px" }}
      >
        {menuOpen ? (
          <MenuFoldOutlined className="ham-icon" onClick={handleMenuOpen} />
        ) : (
          <MenuUnfoldOutlined className="ham-icon" onClick={handleMenuOpen} />
        )}
      </div>
      <Row gutter={[16, 0]} className="app-header" justify={"space-between"}>
        <Col span={6}>
          <Row align={"middle"} className="date-cont">
            <Typography.Text className="date" style={{ marginBottom: 0 }}>
              {getDay()}, {getTodayDate()}
            </Typography.Text>
          </Row>
        </Col>
        <Col span={10}>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search by application ID, product, user, complaint...."
            className="search-input"
          />
        </Col>
        <Col span={6}>
          <Row className="cursor-pointer" justify={"end"} align={"middle"}>
            <Col>
              <div className="avatar-card cursor-pointer">
                <Row align={"middle"}>
                  <img style={{ height: 40 }} src={avatar} alt="user" />
                </Row>
              </div>
            </Col>
            <Col>
              <Row align={"middle"} className="flex">
                <div className="hd-user-name">
                  <h3>{profile?.firstName}</h3>
                  <p>{profile?.role}</p>
                </div>
                <DownOutlined />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default Header;
