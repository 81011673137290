import { useErrorBoundary } from "react-error-boundary";

export default function ErrorFallback({ error }: { error: any }) {
    const { resetBoundary } = useErrorBoundary();
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
        <button onClick={resetBoundary}>Try again</button>
      </div>
    );
  }