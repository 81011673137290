import { atom } from "recoil";
import { VendorType } from "../types/vendor.interface";

export const VendorDetailState = atom<VendorType>({
  key: "VendorDetailState",
  default: {
    business: {
      yearsInBusiness: 1,
      address: "",
      name: "",
      number: "",
      compliance: "",
      photo: "",
      logo: ""
    },
    accountNumber: "",
    accountName: "",
    bankName: "",
    createdAt: "",
    email: "",
    firstName: "",
    isActive: false,
    lastName: "",
    phoneNumber: "",
    rcNumber: "",
    settings: {
      deviceConditions: [],
      deviceModels: []
    },
    updatedAt: "",
    username: "",
    _id: ""
  }
});
