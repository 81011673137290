import { Card, Col, Row, Spin } from "antd";
import { FC } from "react";

interface IProps {
  IconPath?: string;
  title: string;
  value?: string;
  loading?: boolean;
  span?: number;
}

const CounterBox: FC<IProps> = ({ IconPath, title, value, loading, span }) => {
  return (
    <Col span={span || 6}>
      <Card className="counterbox flex">
        <div className="counterbox-top">
          <Row>
            {IconPath && (
              <img
                src={IconPath}
                alt="application"
                className="box-img"
                style={{ width: 22 }}
              />
            )}
            <span className="text-top">{title}</span>
          </Row>
        </div>

        <div className="counterbox-mid">
          <Row align={"middle"} justify={"center"}>
            <span className="text-center">
              {loading ? <Spin /> : value || 0}
            </span>
          </Row>
        </div>
      </Card>
    </Col>
  );
};

export default CounterBox;
