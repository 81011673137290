import { Row, Table, Typography, Card, Col, Popconfirm, message } from "antd";
import { useMemo, useState } from "react";
import { showMessage } from "../../helpers";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  useDisclosure
} from "@chakra-ui/react";
import { useFecthAccountFromBvn, useDeleteBvn, useSearchBvn } from "../../api/mutations/bvn";
import CustomModal from "./AccountModal/CustomModal";

const BvnDetails = () => {
  const [bvnNumber, setBvnnumber] = useState("");
  const [bvn, setBvn] = useState({});
  const { searchBvn, result } = useSearchBvn();
  const { deleteBvnDetails, ...dBvn } = useDeleteBvn();
  const {isOpen,onClose,onOpen} = useDisclosure()

  const cancel = () => {
    message.error("bvn not deleted");
  };


  const columns = useMemo(
    () => [
      {
        title: "Bvn number",
        dataIndex: "bvn",
        key: "bvn"
      },
      {
        title: "First name",
        dataIndex: "first_name",
        key: "first_name"
      },
      {
        title: "Last name",
        dataIndex: "last_name",
        key: "last_name"
      },
      {
        title: "DOB",
        dataIndex: "date_of_birth",
        key: "dateofBirth"
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: "Action",
        render: (record: any, data: any) => (
          <Row gutter={[8, 0]}>
            <Col>
              {/* <Popconfirm
                title="Delete this bvn details"
                //description="Are you sure to delete this bvn details?"
                onConfirm={() => confirm(record)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              > 
                {/* <Button
                  color={"var(--keza-brown"}
                  borderColor={"var(--keza-brown)"}
                  variant="outline"
                  isLoading={dBvn.result.isLoading}
                >
                  Delete
                </Button> */}
                <Button
                  color={"var(--keza-brown"}
                  borderColor={"var(--keza-brown)"}
                  variant="outline"
                  isLoading={dBvn.result.isLoading}
                  onClick={onOpen}
                >
                  View Account
                </Button>
              {/* </Popconfirm> */}
            </Col>
          </Row>
        )
      }
    ],
    []
  );

  async function fetchBvn() {
    if (!bvnNumber) return;
    try {
      const res = await searchBvn(bvnNumber);
      showMessage("Operation successfull", "", "success");
      setBvn(res.data);
    } catch (error) {
      setBvn({});
    }
  }

  const confirm = async (record: any) => {
    try {
      await deleteBvnDetails(record);
      showMessage("Bvn Deleted", "", "success");
      setBvn({});
    } catch (error) {}
  };
  return (
    <div className="container">
      <Box position={"relative"} w="60%">
        <InputGroup size="md">
          <Input
            value={bvnNumber}
            onChange={e => setBvnnumber(e.target.value)}
            borderColor={"black"}
            placeholder="Enter bvn number"
          />
          <InputRightElement width="4.5rem">
            <Button
              mr={"4"}
              isLoading={result.isLoading}
              h="1.75rem"
              size="sm"
              onClick={fetchBvn}
            >
              Search
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>

      <Row className="mb-10" justify={"space-between"}>
        <Typography.Title level={4} className="page-title">
          Bvn Details
        </Typography.Title>
      </Row>
      <CustomModal isOpen={isOpen} onClose={onClose} bvn={bvnNumber} />

      {Object.keys(bvn).length > 0 && (
        <div>
          <h3>Bvn Details:</h3>
          {/* Display additional details from 'bvn' state */}
        </div>
      )}
      {Object.keys(bvn).length > 0 ? (
        <Card title={""}>
          <Table
            className="table"
            loading={result.isLoading}
            dataSource={bvn ? [{ ...bvn }] : []}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "30", "40"]
            }}
            columns={columns}
            scroll={{ x: 1000 }}
          />
        </Card>
      ) : undefined}
    </div>
  );
};

export default BvnDetails;
