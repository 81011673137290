import { ReactNode, createContext, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import IdleTimer from "../utilities/idleTimer";
import ErrorFallback from "../components/ErrorFallBack/ErrorFallBack";
import ThemeProvider from "./ThemeProvider";
export const MenuContext = createContext<boolean | any>(false);

type AppProviderProps = {
  children: ReactNode;
};
export default function AppProvider({ children }: AppProviderProps) {
  const logError = (error: Error, info: { componentStack: string }) => {
    window.location.reload();
  };
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      <QueryClientProvider client={new QueryClient()}>
        <RecoilRoot>
          <BrowserRouter>
            <IdleTimer>
              <MenuContext.Provider value={[menuOpen, setMenuOpen]}>
                <ThemeProvider>

                {children}
                </ThemeProvider>
              </MenuContext.Provider>
            </IdleTimer>
          </BrowserRouter>
        </RecoilRoot>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
