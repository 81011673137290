import { FC, useState } from "react";
import { Row,
    Button,
    Typography,
    Card,
    Form,
    Input,
    Col, } 
from "antd";
import Edit from "../../../assets/Edits.svg"
import avatar from "../../../assets/avatar.svg"
import {useRef} from 'react';
import type { FormInstance } from 'antd/es/form';
import {showMessage } from "../../../helpers";

const MyProfile: FC<{
    user: any;
  }> = ({ user }) => {

    const formRef = useRef<FormInstance>(null);
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isEditMode, setEditMode] = useState(false);

  const handleSubmit = async () => {
        try {
          if (isLoading) return;
         const payload = form.getFieldsValue();
    
          setLoading(true);
          const request = {
            firstName:payload.firstName,
            lastName:payload.lastname,
            email: payload.email,
            status: isActive? "ONLINE" : "BLOCKED",
            password:payload.password,
            confirmPassword:payload.confrimPassword,
            role: payload.role,
            
          };
         // await createAdmin(request);
          setLoading(false);
          showMessage(
            "Operation successful",
            isEditMode
          ? "Product update successfull. Redirecting..."
          : "Product created successfully",
        "success"
          );
    
          form.resetFields();
    
          
        } catch (error) {
          setLoading(false);
        }
      };
  /*const handleUpdate = () =>{
        setEditMode(true)
        form.setFieldsValue({
            firstName:,
            lastName:,
            email: ,
            status: ,
            password:,
            confirmPassword:,
            role:,
        })
    }*/

      const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
      };
  return (
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <Card
                className="card-bord"
                title={
                    <Row className="" justify={"space-between"}>
                        <Typography.Title level={5}>My Profile</Typography.Title>

                        <Button className="flex filter-btn">
                            Edit profile
                            <img
                            src={Edit}
                            style={{ width: "24px", marginRight: "5px" }}
                            alt="filter"
                            className="pl-3"
                            />
                        </Button>
                    </Row>
                }
                >
                    <hr/>
                    <div className=" myprofile grid">
                        <div className="profile-left flex">
                            <div className="left-img flex">
                                <img src={avatar} alt="avatar" />
                            </div>

                            <div className="left-des flex">
                                <div className="des-cont">
                                    <p>Admin ID:</p>
                                    <span></span>
                                </div>
                                <div className="des-cont">
                                    <p>Profile status</p>
                                    <span></span>
                                </div>
                                <div className="des-cont">
                                   <button className="change-btns">Change my password</button>
                                </div>
                            </div>
                        </div>
                        <div className="profile-right">
                            <div className="profile-cont">
                                <Form
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    layout="vertical"
                                    ref={formRef}
                                    form={form}
                                    onFinishFailed={onFinishFailed}
                                    //disabled={componentDisabled}
                                    style={{ maxWidth: 960 }}
                                >
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item 
                                            label="First Name" name="firstName" valuePropName="firstName"
                                            rules={[{ required: true, message: 'Please input your First name!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item 
                                            label="Last Name" name="lastName" valuePropName="lastName"
                                            rules={[{ required: true, message: 'Please input your Last name!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        
                                    </Row>

                                    <Row gutter={24}>
                                        <Col span={12}>
                                        <Form.Item 
                                            label="Work email" name="Work_email" valuePropName="email"
                                            rules={[{ required: true, message: 'Please input your email!' }]}
                                            >
                                            <Input />
                                        </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                        <Form.Item 
                                            label="Phone number" name="Phone_number" valuePropName="role"
                                            rules={[{ required: true, message: 'Please input your phone number!' }]}
                                            >
                                            <Input />
                                        </Form.Item>
                                        </Col>
                                        
                                    </Row>

                                    <Row gutter={24}>
                                        <Col span={12}>
                                        <Form.Item 
                                            label="Technical role" name="Technical_role" valuePropName="role"
                                            rules={[{ required: true, message: 'Please input your role!' }]}
                                            >
                                            <Input />
                                        </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                        <Form.Item 
                                            label="Division" name="Division" valuePropName="role"
                                            rules={[{ required: true, message: 'Please input your division!' }]}
                                            >
                                            <Input />
                                        </Form.Item>
                                        </Col>  
                                    </Row>
                                    
                                </Form>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </div>
  )
}

export default MyProfile