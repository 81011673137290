import { Button, Card, Input, InputRef, Row, Col, Table } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import CounterBox from "../Dashboard/CounterBox/CounterBox";
import smallApplicationIcon from "../../assets/smallApplicationIcon.svg";
import folderIcon from "../../assets/folderIcon.svg";
import folderIcon2 from "../../assets/folderIcon2.svg";
import folderIcon3 from "../../assets/folderIcon3.svg";
import load from "../../assets/load.svg";
import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { getDisbursments } from "../../api/application";
import { formatDate } from "../../utilities/date-formatter";
import { formatAsCurrency } from "../../utilities/number-formatter";
import { getTag } from "../../utilities/sanitizer";
import { Link } from "react-router-dom";

const Disbursments = () => {
  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const response = await getDisbursments();
      return response.data.data.data;
    },
    queryKey: ["disbursements"]
  });
  const columns = useMemo(
    () => [
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        render: (record: any, data: any) => (
          <>
            <p className="table-name">{`${data.applicant?.firstName} ${data.applicant?.lastName}`}</p>
            <span className="table-mail">{`${data.applicant?.email}`}</span>
          </>
        )
      },
      {
        title: "Device",
        dataIndex: "productId",
        key: "productId",
        render: (record: any) => <>{record?.name}</>
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (record: any, data: any) => formatAsCurrency(record)
      },

      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record: any) => <>{formatDate(record)}</>
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (record: any) => <>{getTag(record)}</>
      },
      {
        title: "Actions",
        dataIndex: "_id",
        render: (record: any, data: any) => {
          return (
            <Row gutter={[8, 0]}>
              <Col>
              <Link to={`/dashboard/wallet/${data.accountNumber}/withdraw`}>
                
                <Button>Withdraw</Button>
              </Link>
              </Col>
              <Col>
                <Button>
                  <img src={load} alt="retry" />
                </Button>
              </Col>
            </Row>
          );
        }
      }
    ],
    []
  );

  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"]
    }),
    []
  );
  const [disbursements, setDisbursements] = useState([]);
  const inputRef: React.Ref<InputRef> = useRef() as React.Ref<InputRef>;

  useEffect(() => {
    setDisbursements(data || []);
  }, [data]);

  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.trim().toLowerCase();

    setSearchValue(searchValue);
  };
  const handleFilter = useCallback(() => {
    if (searchValue.trim() === "") return setDisbursements(data || []);

    const filtered = (data || []).filter((item: any) => {
      return (
        item.application.trim().toLowerCase().includes(searchValue) ||
        item.applicant.lastName.trim().toLowerCase().includes(searchValue) ||
        item.applicant.firstName.trim()?.toLowerCase()?.includes(searchValue) ||
        item.applicant.email.trim().toLowerCase().includes(searchValue) ||
        item.applicant._id.trim().toLowerCase().includes(searchValue) ||
        item.transRef.trim().toLowerCase().includes(searchValue)
      );
    });

    setDisbursements(filtered);
  }, [searchValue, data]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  const [sortValue, setSortValue] = useState("");

  const handleSelect = (value: string) => {
    const sortValue = value;

    setSortValue(sortValue);
  };
  const handleSort = useCallback(() => {
    if (sortValue === "") {
      return setDisbursements(data);
    }

    const sorted = data.filter((item: any) => {
      return item.status.trim().toLowerCase().includes(sortValue);
    });
    setDisbursements(sorted);
  }, [sortValue, data]);
  useEffect(() => {
    handleSort();
  }, [handleSort]);
  return (
    <div className="container">
      <Row>
        <Col span={24}>
          <Card title="Disbursements">
            <Row gutter={[16, 16]}>
              <CounterBox
                loading={isLoading}
                IconPath={folderIcon}
                title="Account Balance"
                value={data?.applicationCount?.total}
              />
              <CounterBox
                loading={isLoading}
                IconPath={smallApplicationIcon}
                title="Successful Disbursements"
                value={data?.applicationCount?.approved}
              />
              <CounterBox
                loading={isLoading}
                IconPath={folderIcon2}
                title="Failed Disbursements"
                value={data?.applicationCount?.pending}
              />
              <CounterBox
                loading={isLoading}
                IconPath={folderIcon3}
                title="Withdrawn Disbursements"
                value={data?.applicationCount?.declined}
              />
            </Row>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-md-12">
          <Card
            title="Disbursments"
            className="mb-3"
            extra={
              <div className="flex">
                <Select
                  defaultValue="Sort"
                  style={{ width: 120 }}
                  onChange={handleSelect}
                  options={[
                    { value: "", label: "ALL" },
                    { value: "success", label: "SUCCESS" },
                    { value: "declined", label: "DECLIEND" },
                    { value: "pending", label: "PENDING" }
                  ]}
                />
                <Input
                  prefix={<SearchOutlined />}
                  placeholder="search"
                  onChange={handleChange}
                  ref={inputRef}
                />
              </div>
            }
          >
            <Table
              loading={isLoading}
              dataSource={disbursements || []}
              columns={columns}
              pagination={pagination}
              scroll={{ x: 1000 }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Disbursments;
