import { Col, Row } from "antd";
import { useEffect } from "react";
import Header from "../Header/Header";
import SideBar from "../Sidebar/Sidebar";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { UserTokenState } from "../../resources/user.resource";
import "./DashboardHoc.css";

const DashboardHoc = () => {
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);
  const isUserTokenAvailable = useRecoilValue(UserTokenState);

  if (!isUserTokenAvailable || isUserTokenAvailable.trim() === "")
    return <Navigate to={"/"} />;

  return (
    <div className="ds-main">
      <Row className="ds-main-cont">
        <Col>
          <SideBar />
        </Col>
        <Col>
          <Header />
          <Col className="main-cont">
            <Outlet />
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardHoc;
