import { useMutation } from "react-query";
import { fetchAccountFromBvn, deleteBvnDetails, searchBvn } from "../bvn";

export const useSearchBvn = () => {
  const { mutateAsync, ...result } = useMutation(searchBvn);
  return {
    searchBvn: mutateAsync,
    result
  };
};

export const useDeleteBvn = () => {
  const { mutateAsync, ...result } = useMutation(deleteBvnDetails);
  return {
    deleteBvnDetails: mutateAsync,
    result
  };
};

export const useFecthAccountFromBvn = () => {
  const { mutateAsync, ...fetchAccountFromBvnResult } =
    useMutation(fetchAccountFromBvn);
  return {
    fetchAccountFromBvn: mutateAsync,
    fetchAccountFromBvnResult
  };
};
