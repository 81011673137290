import { FC, useEffect, useState } from "react";
import { Button, Card, Empty, Form, Row, Table, DatePicker, Col } from "antd";
import { useMemo } from "react";
import { getTag, sanitizeValues } from "../../../utilities/sanitizer";
import { paginationData } from "../../../types/pagination.inteface";
import { useMutation } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllUsers, exportVendorUserDateRange } from "../../../api/user";
import { formatDate } from "../../../utilities/date-formatter";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { IApplicationSearch } from "@app-interfaces";
import moment from 'moment';
import { RANGE_PRESETS } from "../../../utilities/date-formatter";

const VendorUsers: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [vendor, setVendor] = useState(params.id)
  const [form] = Form.useForm();
  const [paginations, setPaginations] = useState<paginationData>({
    numberPages: 1,
    totalDocs: 10,
    query: {
      keyword: "",
      page: 1,
      size: 10
    }
  });

  const [searchValue, setSearchValue] = useState<IApplicationSearch | null>(
    null
  );

  const onChangePagination = (page?: any, size?: any) => {
    setPaginations({
      ...paginations,
      query: { ...paginations.query, page, size }
    });
  };
  
  const UsersResponse = useMutation({
    mutationFn: async (input: paginationData) => {
      const response = await getAllUsers({
        ...input,
        vendor: params.id
      } as any);
      setPaginations({ ...paginations, ...response.data.data.pagination });
      setUsers(response.data.data.content);

      return response.data.content;
    }
  });


  const handleOnRangeChange = (dates: any, dateStrings: [string, string]) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    setSearchValue(oldState => ({
      ...oldState,
      startDate,
      endDate
    }));
  };

  const handleExport = async () => {
    try {
      const response = await exportVendorUserDateRange(
        params.id || '',
        searchValue?.startDate || "",
        searchValue?.endDate || ""
      );
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `applications-${searchValue?.startDate || ""} - ${
          searchValue?.endDate
        }.csv`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.log("ERROR DOWNLOADING USERS> ", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "Full name",
        dataIndex: "user",
        key: "user",
        render: (record: any, data: any) => (
          <p className="table-name">
            {data?.firstName} {data?.lastName}
          </p>
        )
      },

      {
        title: "Email address",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Phone number",
        dataIndex: "phone_number",
        key: "phone_number"
      },
      {
        title: "Profile status",
        dataIndex: "isApproved",
        key: "isApproved",
        render: (record: any) => <>{getTag(record ? "success" : "pending")}</>
      },
      {
        title: "Date onboarded",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record: any) => <>{formatDate(record)}</>
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: `${new Date().getTime()}`,
        render: record => (
          <Link to={`/dashboard/users/${record}`}>
            <Button>View</Button>
          </Link>
        )
      }
    ],
    []
  );

  useEffect(() => {
    const formValues = sanitizeValues(form.getFieldsValue());

    UsersResponse.mutate({
      ...formValues,
      page: paginations.query.page,
      size: paginations.query.size
    });
  }, [paginations.query]);

  return (
    <div className="container profile-cont">
      <Card
        title={
          <Row>
            <Col sm={4} md={4} lg={4} xl={6}>
              <span className="table-title">
                Showing {users.length || 0} of {paginations.totalDocs || 0}{" "}
                results for{" users"}
              </span>
            </Col>
            <Col sm={20} xs={24} md={20} lg={20} xl={18}>
              <DatePicker.RangePicker
                onChange={handleOnRangeChange}
                ranges={RANGE_PRESETS}
                value={[
                  moment(searchValue?.startDate || new Date()),
                  moment(searchValue?.endDate || new Date())
                ]}
              />
              <Button onClick={handleExport}>
                <CloudDownloadOutlined />
                Export
              </Button>
            </Col>
          </Row>
        }
        
        // extra={
        //   <div
        //     style={{
        //       position: "relative",
        //       display: "flex",
        //       alignItems: "center",
        //       gap: "10px"
        //     }}
        //   >
        //     <Popover
        //       placement="bottomRight"
        //       title={"Filter "}
        //       content={UserFilterComponent}
        //       trigger="click"
        //     >
        //       <Button className="flex filter-btn">
        //         Filter
        //         <img
        //           src={filter}
        //           style={{ width: "16px", marginRight: "5px" }}
        //           alt="filter"
        //           className="pl-3"
        //         />
        //       </Button>
        //     </Popover>
        //     <Popover
        //       placement="bottomRight"
        //       title={"Filter "}
        //       content={UsersExportComponent}
        //       trigger="click"
        //     >
        //       <Button className="flex filter-btn">Export as CSV</Button>
        //     </Popover>
        //   </div>
        // }
      >
        <Table
          locale={{ emptyText: <Empty /> }}
          pagination={{
            showSizeChanger: true,
            total: paginations.totalDocs,
            pageSize: paginations.query.size,
            onChange: onChangePagination,
            onShowSizeChange(page, size) {
              setPaginations({
                ...paginations,
                query: { ...paginations.query, page, size }
              });
            },
            pageSizeOptions: ["5", "10", "20", "30", "40"]
          }}
          columns={columns}
          dataSource={users || []}
          scroll={{ x: 1000 }}
          loading={UsersResponse.isLoading}
        />
      </Card>
    </div>
  );
};

export default VendorUsers;
