import { useSendReciept } from "../../../api/mutations/appications";
import { Box, Input, useDisclosure } from "@chakra-ui/react";
import { Button } from "antd";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { useState } from "react";
import { showError } from "../../../helpers";

export default function SendReceipt({ applicationId }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sendRecieptmutation = useSendReciept({
    onError: (err:any) => {      
      onClose();
      showError(err);
    }
  });
  const [input, setInput] = useState("");

  const handleInputChange = (e: any) => setInput(e.target.value);
  return (
    <Box>
      <Button onClick={onOpen}>Send reciept</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send receipt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Interest</FormLabel>
              <Input type="number" onChange={handleInputChange} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              loading={sendRecieptmutation.isLoading}
              onClick={() =>
                sendRecieptmutation.mutate({
                  applicationId: applicationId,
                  interest: Number(input)
                })
              }
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
