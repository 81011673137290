import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Radio, Row, Select, Space, Typography } from 'antd'
import { FC } from "react";

    const { Option } = Select;

    const OptionalForm: FC <{type:any}>= ({type}) => {
    return (
            <div>
                <Row gutter={8}>
                    <Col span={24}>
                      <Typography.Title level={5} className="form-title">
                        {type}
                      </Typography.Title>


                      <Form.List name={type.toLowerCase()}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name,...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                    
                                        <Row gutter={8}>

                                            <Col span={8}>
                                                <Form.Item 
                                                {...restField} 
                                                label="Key" name={[name, "questionKey" ]}
                                                rules={[{ required: true, message: 'Please input your questionkey!' }]}
                                                >
                                                    <Input />
                                                    
                                                </Form.Item>
                                            </Col>

                                            <Col span={10}>
                                                <Form.Item 
                                                {...restField} 
                                                label="Question" name={[name, "question" ]} 
                                                rules={[{ required: true, message: 'Please input your question!' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col span={6}>
                                                <Form.Item 
                                                {...restField} 
                                                label="Input Type" name={[name, "inputType" ]} 
                                                rules={[{ required: true, message: 'Please input your !' }]}
                                                >
                                                    <Select>
                                                        <Option value="text">Text</Option>
                                                        <Option value="date">Date</Option>
                                                        <Option value="file">File</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={10}>
                                                <Form.Item 
                                                {...restField} 
                                                label="Optional" name= {[name, "optional" ]}
                                                rules={[{ required: true, message: 'Please input your registration number!' }]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value={true}>True</Radio>
                                                        <Radio value={false}>False</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>

                                            <Col span={10}>
                                                <Form.Item 
                                                {...restField} 
                                                label="Is Modal" name= {[name, "isModal" ]}
                                                rules={[{ required: true, message: 'Please input your endorser modal!' }]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value={true}>True</Radio>
                                                        <Radio value={false}>False</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>

                                            <Col span={4}>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Col>
                                        </Row>
                                    </Space>                                       
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add field
                                    </Button>
                                </Form.Item>
                            </>
                         )}
                      </Form.List>
                                        
                    </Col>
                </Row>

            </div>
    )
    }

export default OptionalForm