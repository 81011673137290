import {
  Button,
  Col,
  Select,
  Input,
  Row,
  Table,
  Empty,
  Typography
} from "antd";
import { useState, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";
import {
  getApplicationBillingProccess,
  makeCharge,
  sendPaymentInstruction,
  updateDownpaymentAmount,
  updateRepaymentAmount,
  getPaymentStatus,
  getMandateStatus,
  sendDebitInstruction,
  getDebitStatus,
  stopMandate
} from "../../../api/application";
// import {
//   initiateDebit,
//   sendDebitInstruction,
//   getDebitStatus,
//   getMandateStatus,
//   stopMandate,
// } from "../../../api/application";
import { showError, showMessage } from "../../../helpers";
import { formatAsCurrency } from "../../../utilities/number-formatter";
import { getTag } from "../../../utilities/sanitizer";
import { DatePicker } from "antd";
import moment from "moment";
import { Box } from "@chakra-ui/react";
//import dayjs from 'dayjs'

const PaymentTrack = () => {
  const dateFormat = "DD/MM/YY";
  const params = useParams();
  const queryClient = useQueryClient();
  const response = useQuery({
    queryKey: ["payment-tracking", params.id],
    queryFn: async () => {
      const response = await getApplicationBillingProccess(params.id as string);
      return response.data.data;
    }
  });

  const mandateStatusResponse = useQuery({
    queryKey: ["mndate-status", params.id],
    queryFn: async () => {
      const response = await getMandateStatus(params.id as string);
      return response.data.data;
    }
  });

  const mutation = useMutation({
    mutationFn: sendDebitInstruction,
    onSuccess: response => {
      console.log("response> ", response);
      // Invalidate and refetch
      showMessage(
        "Operation successful",
        "Debit instruction successfully sent",
        "success"
      );
      queryClient.invalidateQueries({
        queryKey: ["payment-tracking", params.id]
      });
    },
    onError: error => {
      showError(error);
    }
  });
  const columns = useMemo(
    () => [
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (record: any, data: any) => (
          <>
            {updates[data._id] ? (
              <Input
                value={updates[data._id].amount}
                onChange={e => {
                  setUpdates((old: any) => ({
                    ...old,
                    [data._id]: {
                      ...old[data._id],
                      amount: e.target.value
                    }
                  }));
                }}
              />
            ) : (
              formatAsCurrency(record)
            )}
          </>
        )
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (record: any) => <>{record}</>
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (record: any, data: any) => (
          <>
            {updates[data._id] ? (
              <Select
                defaultValue="inactive"
                value={updates[data._id].status}
                style={{ width: 100 }}
                onChange={value => {
                  setUpdates((old: any) => ({
                    ...old,
                    [data._id]: {
                      ...old[data._id],
                      status: value
                    }
                  }));
                }}
                options={[
                  { value: "PENDING", label: "PENDING" },
                  { value: "PAID", label: "PAID" },
                  { value: "INACTIVE", label: "INACTIVE" }
                ]}
              />
            ) : (
              getTag(record)
            )}
          </>
        )
      },
      {
        title: "Payment Provider",
        dataIndex: "paymentChannel",
        key: "paymentChannel",
        render: (record: any) => <>{record}</>
      },
      {
        title: "Due date",
        dataIndex: "dueDate",
        key: "dueDate",
        render: (record: any, data: any) => (
          <>
            {updates[data._id] ? (
              <DatePicker
                value={updates[data._id].dueDate}
                format={dateFormat}
                onChange={value => {
                  setUpdates((old: any) => ({
                    ...old,
                    [data._id]: {
                      ...old[data._id],
                      dueDate: value
                    }
                  }));
                }}
              />
            ) : (
              record
            )}
          </>
        )
      },
      {
        title: "Actions",
        dataIndex: "_id",
        key: `${new Date().getTime()}`,
        render: (data: any, record: any) => {
          return (
            <Row gutter={[8, 0]}>
              {!updates[record._id] ? (
                <>
                  {data.type === "DOWNPAYMENT" && (
                    <Col>
                      <Button onClick={() => mutation2.mutate(record._id)}>
                        Send Payment Instruction
                      </Button>
                    </Col>
                  )}
                  {data.type !== "DOWNPAYMENT" && (
                    <Col>
                      <Button onClick={() => mutation.mutate(record._id)}>
                        Send Debit
                      </Button>
                    </Col>
                  )}
                  <Col>
                    <Button
                      onClick={() =>
                        setUpdates((old: any) => ({
                          ...old,
                          [record._id]: {
                            type: data.type,
                            amount: data.amount,
                            status: data.status
                          }
                        }))
                      }
                    >
                      Edit
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      loading={isGetDebitStatusLoading}
                      disabled={isGetDebitStatusLoading}
                      onClick={() => mutation3.mutate(record._id)}
                    >
                      Get Payment Status
                    </Button>
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <Button
                      loading={isUpdating}
                      disabled={isUpdating}
                      onClick={() => handleUpdate(record)}
                    >
                      Update
                    </Button>
                  </Col>
                  <Col>
                    <Button danger onClick={() => resetState(record._id)}>
                      Cancel
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          );
        }
      }
    ],
    []
  );

  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"]
    }),
    []
  );

  const mutation2 = useMutation({
    mutationFn: sendPaymentInstruction,
    onSuccess: response => {
      console.log("response> ", response);
      // Invalidate and refetch
      showMessage(
        "Operation successful",
        "Payment instruction successfully sent",
        "success"
      );
      queryClient.invalidateQueries({
        queryKey: ["payment-tracking", params.id]
      });
    },
    onError: error => {
      showError(error);
    }
  });

  const mutation4 = useMutation({
    mutationFn: makeCharge,
    onSuccess: response => {
      console.log("response> ", response);
      // Invalidate and refetch
      showMessage(
        "Operation successful",
        "Payment instruction successfully sent",
        "success"
      );
      queryClient.invalidateQueries({
        queryKey: ["payment-tracking", params.id]
      });
    },
    onError: error => {
      showError(error);
    }
  });

  const mutation3 = useMutation({
    mutationFn: getPaymentStatus,
    onSuccess: response => {
      // Invalidate and refetch

      showMessage(
        "Operation successful",
        `Payment is ${response.data.data.status?.toUpperCase() || "pending"}`,
        "success"
      );
      queryClient.invalidateQueries({
        queryKey: ["payment-tracking", params.id]
      });
    },
    onError: error => {
      showError(error);
    }
  });

  const [updates, setUpdates] = useState({}) as any;

  const resetState = (key: any) => {
    setUpdates((old: any) => ({ ...old, [key]: null }));
  };

  const getUpdatedStatus = () => {
    queryClient.invalidateQueries({ queryKey: ["mndate-status", params.id] });
  };

  const [isUpdating, setUpdating] = useState(false);

  const handleUpdate = async (billingId: string) => {
    try {
      if (!updates[billingId]) return;

      if (isUpdating) return;

      setUpdating(true);

      const dateFormatted = moment(updates[billingId].dueDate).format(
        "DD/MM/YYYY"
      );

      if (updates[billingId].type === "DOWNPAYMENT") {
        // UPDATE DOWN PAYMENT
        await updateDownpaymentAmount(
          params.id as string,
          updates[billingId].amount,
          dateFormatted,
          updates[billingId].status,
          billingId
        );
      } else {
        //UPDATE RECURRING

        console.log(moment(updates[billingId].dueDate).format("DD/MM/YYYY"));

        await updateRepaymentAmount(
          params.id as string,
          updates[billingId].amount,
          dateFormatted,
          updates[billingId].status,
          billingId as string
        );
      }

      setUpdating(false);

      showMessage(
        "Operation successful",
        "Expected payment successfully updated",
        "success"
      );

      updates[billingId] = null;

      queryClient.invalidateQueries({
        queryKey: ["payment-tracking", params.id]
      });
    } catch (error) {
      setUpdating(false);
    }
  };

  const [isGetDebitStatusLoading, setDebitStatusLoading] = useState(false);

  const [isStoppingMandate, setStoppingMandate] = useState(false);

  const handleGetDebitStatus = async (billingId: string) => {
    try {
      setDebitStatusLoading(true);
      const response = await getDebitStatus(billingId);
      setDebitStatusLoading(false);
      showMessage(
        "Operation successful",
        `Debit Status: ${response.data.data.status}`,
        "success"
      );
      queryClient.invalidateQueries({
        queryKey: ["mndate-status", params.id]
      });
    } catch (error) {
      setDebitStatusLoading(false);
    }
  };

  const handleCancelMandate = async () => {
    try {
      setStoppingMandate(true);
      const response = await stopMandate(params.id as string);
      setStoppingMandate(false);
      showMessage(
        "Operation successful",
        `Mandate cancelled successfully`,
        "success"
      );
      queryClient.invalidateQueries({
        queryKey: ["payment-tracking", params.id]
      });
    } catch (error) {
      setStoppingMandate(false);
    }
  };

  return (
    <Box >
      <br />
      <div className="mb-3"></div>
      <Row gutter={[16, 20]} align="middle">
        <Col>
          <Typography.Text>Mandate Status</Typography.Text>
        </Col>
        <Col>
          {getTag(mandateStatusResponse.data?.isActive ? "Active" : "pending")}
        </Col>
        <Col>
          <Button
            onClick={getUpdatedStatus}
            loading={mandateStatusResponse.isLoading}
          >
            Refresh
          </Button>
        </Col>
        <Col>
          <Link to="mandate/payment-history">
            <Button onClick={getUpdatedStatus}>View Payment History</Button>
          </Link>
        </Col>
        <Col>
          <Button
            disabled={isStoppingMandate}
            loading={isStoppingMandate}
            onClick={handleCancelMandate}
            danger
          >
            Stop Mandate
          </Button>
        </Col>
      </Row>
      <div className="mb-5"></div>
      <table className="w-100 table table-striped">
        <thead>
          <tr>
            <th>Amount</th>
            <th>Type</th>
            <th>Status</th>
            <th>Payment Provider</th>
            <th>Due Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(response.data || []).map((item: any, i: any) => (
            <tr key={i}>
              <td>
                {updates[item._id] ? (
                  <Input
                    value={updates[item._id].amount}
                    onChange={e => {
                      setUpdates((old: any) => ({
                        ...old,
                        [item._id]: {
                          ...old[item._id],
                          amount: e.target.value
                        }
                      }));
                    }}
                  />
                ) : (
                  formatAsCurrency(item.amount)
                )}
              </td>
              <td>{getTag(item.type)}</td>
              <td>
                {updates[item._id] ? (
                  <Select
                    defaultValue="inactive"
                    value={updates[item._id].status}
                    style={{ width: 100 }}
                    onChange={value => {
                      setUpdates((old: any) => ({
                        ...old,
                        [item._id]: {
                          ...old[item._id],
                          status: value
                        }
                      }));
                    }}
                    options={[
                      { value: "PENDING", label: "PENDING" },
                      { value: "PAID", label: "PAID" },
                      { value: "INACTIVE", label: "INACTIVE" }
                    ]}
                  />
                ) : (
                  getTag(item.status)
                )}
              </td>
              <td>{getTag(item.paymentChannel)}</td>
              <td>
                {(
                  item.dueDate
                )}
              </td>

              <td>
                <Row gutter={[8, 0]}>
                  {!updates[item._id] ? (
                    <>
                      {item.type === "DOWNPAYMENT" && (
                        <Col>
                          <Button onClick={() => mutation2.mutate(item._id)}>
                            Send Payment Instruction
                          </Button>
                        </Col>
                      )}
                      {item.type !== "DOWNPAYMENT" && (
                        <Col>
                          <Button onClick={() => mutation.mutate(item._id)}>
                            Send Debit
                          </Button>
                        </Col>
                      )}
                      <Col>
                        <Button
                          onClick={() =>
                            setUpdates((old: any) => ({
                              ...old,
                              [item._id]: {
                                type: item.type,
                                amount: item.amount,
                                status: item.status
                              }
                            }))
                          }
                        >
                          Edit
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          loading={isGetDebitStatusLoading}
                          disabled={isGetDebitStatusLoading}
                          onClick={() => mutation3.mutate(item._id)}
                        >
                          Get Payment Status
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col>
                        <Button
                          loading={isUpdating}
                          disabled={isUpdating}
                          onClick={() => handleUpdate(item._id)}
                        >
                          Update
                        </Button>
                      </Col>
                      <Col>
                        <Button danger onClick={() => resetState(item._id)}>
                          Cancel
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default PaymentTrack;
