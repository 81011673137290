import axios from "axios";
import { showError } from "../helpers";
import { getUserToken } from "../utilities/storage";
import LogOut from "../utilities/LogOut";
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3050/api/v1"
});

api.interceptors.response.use(
  value => Promise.resolve(value),
  error => {
    showError(error);
    if (error.response.status === 403) {
      LogOut();
    }
    if (error.response.status === 401) {
      LogOut();
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((req: any) => {
  const token = getUserToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

export const createBusiness = (payload: any) => {
  return api.post(`/businesses/admin/create`, payload);
};

export const getBusiness = () => {
  return api.get(`/businesses/admin/all`);
};

export const getBusinessById = (id: any) => {
  return api.get(`/businesses/admin/profile/${id}`);
};

export const getAllProduct = (payload: any) => {
  return api.get(
    `/businesses/${payload.id}/devices?page=${payload.page}&size=${
      payload.size
    }${payload?.search ? "&search="+payload.search :''}`
  );
};
export const getProduct = (payload: any) => {
  return api.get(
    `/businesses/${payload.id}/list-devices?page=${payload.page}&size=${payload.size}`
  );
};
export const createProductById = (payload: any) => {
  return api.post(`/businesses/add-devices`, payload);
};
export const deleteProductById = (payload: any) => {
  return api.post(`businesses/admin/remove-devices-id`, { id: payload });
};

export const updateBusinessByID = (id: string, payload: any) => {
  return api.put(`/businesses/admin/update/${id}`, payload);
};

export const addPhoneToBusiness = (payload: any) => {
  return api.post(`/businesses/admin/add-devices`, payload);
};

export const updatePhoneToBusiness = (payload: any) => {
  return api.post(`/businesses/admin/add-devices`, payload);
};
