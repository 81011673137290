import { SearchOutlined } from "@ant-design/icons";
import { Select } from 'antd';
import { Button, Card, Input, InputRef, Table } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getWallets } from "../../api/wallet";
import { formatDate } from "../../utilities/date-formatter";
import { getTag } from "../../utilities/sanitizer";

const Wallet = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["wallets"],
    queryFn: async () => {
      const response = await getWallets();
      
      return response.data.data;
      
    },
  });
  const [wallets, setWallets] = useState([]);
  const inputRef: React.Ref<InputRef> = useRef() as React.Ref<InputRef>;
  const columns = useMemo(
    () => [
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Account Number",
        dataIndex: "accountNumber",
        key: "accountNumber",
      },
      {
        title: "Account Provider",
        dataIndex: "accountProvider",
        key: "accountProvider",
        render: (record: any) => <>{getTag(record)}</>,
      },
      {
        title: "Phone Number",
        dataIndex: "phone_number",
        key: "phone_number",
      },
      {
        title: "Approval Status",
        dataIndex: "isApproved",
        key: "isApproved",
        render: (record: any) => <>{getTag(record ? "success" : "pending")}</>,
      },
      {
        title: "Date Onboarded",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record: any) => <>{formatDate(record)}</>,
      },
      {
        title: "Actions",
        dataIndex: "_id",
        render: (record: any, data: any) => (
          <Link to={`${data.accountNumber}/withdraw`}>
            <Button>Withdraw</Button>
          </Link>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    setWallets(data || []);
  }, [data]);

  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.trim().toLowerCase();

    setSearchValue(searchValue);
  };
  const handleFilter = useCallback(() => {
    if (searchValue.trim() === "") return setWallets(data || []);

    const filtered = (data || []).filter((item: any) => {
      return (
        item.firstName.trim().toLowerCase().includes(searchValue) ||
        item.lastName.trim().toLowerCase().includes(searchValue) ||
        item.accountNumber?.trim()?.toLowerCase()?.includes(searchValue) ||
        item.phone_number.trim().toLowerCase().includes(searchValue) ||
        item.accountProvider.trim().toLowerCase().includes(searchValue)
      );
    });

    setWallets(filtered);
  }, [searchValue, data]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  const [sortValue, setSortValue] = useState("");

  const handleSelect = (value: string) => {
    const sortValue = value;

    setSortValue(sortValue);
  };
  const handleSort = useCallback(() => {
    if (sortValue === ''){
    return setWallets(data);}

    const sorted = data.filter((item: any) => {
      return (
        item.isApproved === sortValue
      )
    });
    

    setWallets(sorted);
  }, [sortValue, data])
  useEffect(() => {
    handleSort();
  }, [handleSort]);
  
  const pagination = useMemo(
    () => ({
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "30", "40"],
    }),
    []
  );
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Card
            title="Wallets"
            extra={
              <div className='flex'>
              <Select
                defaultValue="Sort"
                style={{ width: 120 }}
                onChange={handleSelect}
                options={[
                  { value:'', label: 'ALL' },
                  { value: false, label: 'PENDING' },
                  { value: true, label: 'APPROVED' },
                ]}
              />
              <Input
                prefix={<SearchOutlined />}
                placeholder="search"
                onChange={handleChange}
                ref={inputRef}
              />
              </div>
            }
          >
            <Table
              loading={isLoading}
              dataSource={wallets || []}
              columns={columns}
              pagination={pagination}
              scroll={{ x: 1000 }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
