import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col,Row,Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import OrderProfile from "../orderDetails/OrderProfile/OrderProfile";
import PaymentHistory from "../UserDetail/PaymentHistory/PaymentHistory";
import UserProfile from "../UserDetail/UserProfile/UserProfile"; 

const PaymentDetails = () => {
    const navigate = useNavigate();
    const params = useParams() as any;
  return (
    <div className="container">
    <div className="row">
      <div className="col-md-12">
        
        <Row>
          <Col span={24}>
            <Row gutter={8} justify={"space-between"} align={"middle"}>
                  
                 <Col>
                    <span className="cursor-pointer" onClick={() => navigate(-1)}>
                      <ArrowLeftOutlined /> Back to Payments
                    </span>
                 </Col>
            </Row>
              <br/>
              <br/>
              
            <Card>
              <Tabs>
                <Tabs.TabPane tab="Payment History" key="item-1">
                  <PaymentHistory />
                </Tabs.TabPane>
                <Tabs.TabPane tab="User Profile" key="item-2">
                  <UserProfile user={""}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Order Details"  key="item-3">
                  <OrderProfile />
                </Tabs.TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </div>
  )
}

export default PaymentDetails