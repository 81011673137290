import {
  Box,
  Center,
  Checkbox,
  Flex,
  GridItem,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text
} from "@chakra-ui/react";
import React, { useState } from "react";
import { TextInput } from "../../../components/Input/Input";
import SelectedItem from "./SelectedItem";
import { Product } from "../../../types/product";

export function SectionLabel({ name }: { name: string }) {
  return (
    <Center
      bg={"#FFF2DF"}
      alignItems={"center"}
      justifyContent={"center"}
      h={"2.3rem"}
      minW={"8rem"}
      maxWidth={"10rem"}
      mb={"4"}
    >
      <Text fontWeight={"semibold"}>{name}</Text>
    </Center>
  );
}

export default function LeftDetails({
  product,
  setProduct
}: {
  product: Product;
  setProduct: (data: Product) => void;
}) {
  const [color, setColor] = useState<string>("");
  const [camera, setCamera] = useState<string>("");

  const handleKeyPress = (event: any, itemKey: string) => {
    if (event.key === "Enter") {
      if (itemKey === "colors") {
        setProduct({
          ...product,
          colors: [...product.colors, color]
        });
        setColor("");
      } else if (itemKey === "camera") {
        setProduct({
          ...product,
          component: {
            ...product.component,
            camera: [...product.component.camera, camera]
          }
        });
        setCamera("");
      }
    }
  };

  const handleDirectChange = (key: string, value: string) => {
    const [nestedKey, propertyKey] = key.split(".");
    if (!propertyKey) {
      setProduct({
        ...product,
        [key]: value
      });
    } else {
      setProduct({
        ...product,
        [nestedKey]: {
          ...product[nestedKey],
          [propertyKey]: value
        }
      });
    }
  };

  return (
    <Box>
      {/* ==========product detaild ========= */}
      <Box>
        <SectionLabel name="Device details" />
        <Stack spacing={"4"}>
          <Box maxW={"60%"}>
            <TextInput
              label="Product name"
              name="device "
              value={product.name}
              onChange={(e: any) =>
                setProduct({
                  ...product,
                  name: e.target.value
                })
              }
            />
          </Box>
          <Box maxW={"60%"}>
            <TextInput
              label="Colors"
              name="device"
              onChange={(e: any) => setColor(e.target.value)}
              value={color}
              onKeyDown={(e: any) => handleKeyPress(e, "colors")}
            />
            {product?.colors?.length > 0 ? (
              <Flex mt={"4"} flexWrap={"wrap"} gap={"4"}>
                {product.colors.map((item, index) => (
                  <Box key={index}>
                    <SelectedItem
                      name={item}
                      onClick={() =>
                        setProduct({
                          ...product,
                          colors: product?.colors?.filter(
                            color => color !== item
                          )
                        })
                      }
                    />
                  </Box>
                ))}
              </Flex>
            ) : undefined}
          </Box>
        </Stack>
      </Box>

      <Box mt={"8"}>
        <SectionLabel name="Device components" />
        <SimpleGrid columnGap={"8"} rowGap={"4"} columns={2}>
          <GridItem>
            <TextInput
              label="Camera"
              name="camera"
              onChange={(e: any) => setCamera(e.target.value)}
              value={camera}
              onKeyDown={(e: any) => handleKeyPress(e, "camera")}
            />
            {product?.component.camera?.length > 0 ? (
              <Flex mt={"4"} flexWrap={"wrap"} gap={"4"}>
                {product.component.camera.map((item, index) => (
                  <Box key={index}>
                    <SelectedItem
                      name={item}
                      onClick={() =>
                        setProduct({
                          ...product,
                          component: {
                            ...product.component,
                            camera: product?.component.camera?.filter(
                              cam => cam !== item
                            )
                          }
                        })
                      }
                    />
                  </Box>
                ))}
              </Flex>
            ) : undefined}
          </GridItem>
          <GridItem>
            <TextInput
              label="Phone display"
              onChange={(e: any) =>
                handleDirectChange("component.display", `${e.target.value}`)
              }
              value={product.component.display}
            />
          </GridItem>
          <GridItem>
            <TextInput
              onChange={(e: any) =>
                handleDirectChange("component.chip", `${e.target.value}`)
              }
              label="Phone microchip"
              value={product.component.chip}
            />
          </GridItem>
          {/* <GridItem>
            <TextInput  onChange={(e: any) =>
                handleDirectChange("component.camera", `${e.target.value}`)
              } label="Cellular data" />
          </GridItem> */}
          <GridItem>
            <TextInput
              onChange={(e: any) =>
                handleDirectChange("component.batteryName", `${e.target.value}`)
              }
              label="Battery"
              value={product.component.batteryName}
            />
          </GridItem>
        </SimpleGrid>
      </Box>
      <Box mt={"8"}>
        <SectionLabel name="Device options" />

        <Stack spacing={"4"} my={"4"}>
          <Checkbox
            defaultChecked={product.component.isBatteryRemovable}
            onChange={() =>
              setProduct({
                ...product,
                component: {
                  ...product.component,
                  isBatteryRemovable: !product.component.isBatteryRemovable
                }
              })
            }
          >
            Is Battery Removable
          </Checkbox>
          <Checkbox
            defaultChecked={product.isActive}
            onChange={() =>
              setProduct({
                ...product,
                isActive: !product.isActive
              })
            }
          >
            Is Active
          </Checkbox>

          <RadioGroup
            onChange={(data: any) => setProduct({ ...product, type: data })}
            value={product.type}
          >
            <Stack direction="row">
              <Radio value="PHONE">Phone</Radio>
              <Radio value="LAPTOP">Laptop</Radio>
            </Stack>
          </RadioGroup>
          <RadioGroup
            onChange={(data: any) =>
              setProduct({ ...product, condition: data })
            }
            value={product.condition}
          >
            <Stack direction="row">
              <Radio value="NEW">New</Radio>
              <Radio value="PRE_OWNED">Pre-owned</Radio>
            </Stack>
          </RadioGroup>
        </Stack>
      </Box>
    </Box>
  );
}
